import { Box } from '@mui/material'
import React from 'react'
import { Outlet, Link } from 'react-router-dom'
import { SingleCompanyGrid } from './components'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';





import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';

import Images from './../constants/Images'

import Slide from '@mui/material/Slide';
import HeadMenu from './components/HeadMenu/Index';
import axios from 'axios';
import Cookies from 'js-cookie'
import MinMaxDateRangePicker from './components/MinMaxDateRangePicker';
import { checkUser } from './../constants/helpers'

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

// function HideOnScroll(props) {
//   const { children, window } = props;
//   // Note that you normally won't need to set the window ref as useScrollTrigger
//   // will default to window.
//   // This is only being set here because the demo is in an iframe.
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//   });

//   return (
//     <Slide appear={true} direction="down" in={!trigger}>
//       {children}
//     </Slide>
//   );
// }

// HideOnScroll.propTypes = {
//   children: PropTypes.element.isRequired,
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };


ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


const Layout = (props) => {


  return (
    <>
      <Box>

      <CssBaseline />
      {/* <HideOnScroll {...props}> */}
      <AppBar  sx={{ 
        backgroundColor:'#fff',
        
       }}>
        <Toolbar
          
         sx={{ 
          //  display:'flex',
           justifyContent:'space-between'
        // backgroundColor:'#f37b22',
        // padding:'0 !important'
       }}>
          <Box className='mainLogo'>
            {
              window.location.href.includes('free-blog') ?
              <>
                <a href="https://omkaracapital.in" target="_self" >
                  <img src={Images.logo192} alt="" />
                </a>
              </>
              :
                <Link to="/">
                  <img src={Images.logo192} alt="" />
                </Link>

            }
            {/* <Link to="/">
              <img src={Images.logo192} alt="" />
            </Link> */}
          </Box>
          <Box>
            {
              (!window.location.href.includes('free-blog')) ?
                <><HeadMenu /></>
              :
              <></>
            }
            
            
          </Box>
        </Toolbar>
      </AppBar>
      {/* </HideOnScroll> */}
      <Toolbar id="back-to-top-anchor" />
      
      <div className="DataLink" >
      <ScrollTop {...props} >
        <Fab sx={{ 
          backgroundColor:'#f37b22',
          color:'#fff'
         }}  size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
      </div>
        <div style={{ minHeight: '60vh', marginTop: '4rem', }}>
        <Outlet />
        </div>
        {/* <MinMaxDateRangePicker /> */}
        <footer style={{ 
          height:390,
          backgroundColor:'#133e5b'
         }}
         >
           <Box>

           </Box>
           <Box>

           </Box>
         </footer>
      </Box>
    </>
  )
}

export default Layout