import {
  AppBar,
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import './style.scss'
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  vdrSingleCompanyAction,
  openPDFAction,
  PeerRatiosAction,
  TLOverviewAction,
} from "../../redux/actions/VdrAction";

import { AiFillCaretDown, AiFillCaretUp, AiOutlineFilePdf } from "react-icons/ai";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  DATA_20_YEARS_QUARTERLY_REQUEST,
  DATA_20_YEARS_REQUEST,
  MEDIA_DATA_REQUEST,
  PEER_TO_PEER_REQUEST,
  QUARTERLY_REVIEW_RESET,
  QUARTERLY_SEGMENT_RESET,
  REPOSITORY_LIST_REQUEST,
  VDR_SINGLE_COMPANY_REQUEST,
} from "../../constants/VdrConstants";

import {
  COMPANY_COMMENTS_REQUEST,
  FORENSIC_TAB_SHOW_HIDE_REQUEST,
  SINGLE_COMPANY_NOTE_REQUEST,
  UPLOAD_DOCUMENT_REQUEST,
} from "../../constants/userConstants";

import {
  Charts,
  DocumentView,
  FinancialCharts,
  LoadingComponent,
  MediaSection,
} from "../../components";

import { Link as SLink } from "react-scroll";
import {
  decodeAtob,
  ideaTypeTitle,
  recommendationType,
  checkUser,
  loggedInUser,
  crtUserId,
} from "../../constants/helpers";
import SingleCompanyValuation from "../../components/SingleCompanyValuation/Index";
import styled from "@emotion/styled";
import MediaModel from "../../components/SingleCompanyContainer/MediaSection_old/MediaModel";
import moment from "moment";
import ForensicComponent from "../components/ForensicComponent";
import {
  AnalystRoomComp,
  ClientRoomComp,
  NotesComp,
  ValuationComp,
} from "../../pages/single_compamy/components";
import { UploadDocumentAction, singleCompanyNoteAction } from "../../redux/actions/userAction";
import BarChartModal from "../../pages/single_compamy/Highcharts/BarChartModal";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import HighchartsStock from "../../pages/single_compamy/Highcharts/HighchartsStock";

// function TabPanel(props) {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       className="tabPanelParent"
//       id={`full-width-tabpanel-${index}`}
//       aria-labelledby={`full-width-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//         <Box sx={{ p: 3 }}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const CompanyDetail = () => {
  const dispatch = useDispatch();
  let { companyName } = useParams();
  let companyId;
  companyId = decodeAtob(companyName);
  // console.log(useParams());
  const { data: companyData, loading } = useSelector(
    (state) => state.vdrSingleCompanyReducer
  );
  const { data: UploadDocumentData, loading: UploadDocumentLoading } =
    useSelector((state) => state.UploadDocumentReducer);

    const { data: CompanyNoteData, loading: CompanyNoteLoading } = useSelector(state => state.singleCompanyNoteReducer);
  const { loading: PeerRatioLoading } = useSelector(
    (state) => state.PeerRatiosReducer
  );
  const {
    Overview:{
      data: OverviewData,
      loading: OverviewLoading
    }
  } = useSelector(state=>state.TrendlyneDataReducer)

  
  const { data: DefaultMasterData, loading: DefaultMasterLoading } =
    useSelector((state) => state.DefaultMasterReducer);

  // if (localStorage.getItem('single-company-data')) {
  //     companyId = decodeAtob(localStorage.getItem('single-company-data'));
  // }
  // console.log(companyId);
  const [researchButton, setResearchButton] = React.useState([]);
  const [CompanyNotesRes, setCompanyNotesRes] = useState({});
  const [StockQs, setStockQs] = useState(null);

  
  useEffect(() => {
    if (CompanyNoteLoading) {
        var prams = { "CompanyID": companyId };
        dispatch(singleCompanyNoteAction(prams));
    }
    if (!CompanyNoteLoading) {
        let cmpData = CompanyNoteData?.Data?.[0];
        let cmpDataFooter = CompanyNoteData?.footer;
        if(cmpDataFooter.length > 0){
            setStockQs(cmpDataFooter[0]?.heading);
        }
        let a00 = {
            ...DataRoomDocDetail,
            comName: cmpData?.CompanyName,
            comId: companyId,
            userId:22
        }
        setDataRoomDocDetail(a00)
        let compSlug = (cmpData?.NSEcode || cmpData?.BSEcode)
        dispatch(TLOverviewAction(compSlug))
        setCompanyNotesRes(cmpData);

    }
}, [dispatch, CompanyNoteLoading])


  useEffect(() => {
    dispatch({ type: 'AnnualReportRequest' });
    dispatch({ type: 'EarningsCallReset' });
    dispatch({ type: 'InvestorPresentationReset' });
    dispatch({ type: UPLOAD_DOCUMENT_REQUEST });
    dispatch({ type: MEDIA_DATA_REQUEST });
    dispatch({ type: SINGLE_COMPANY_NOTE_REQUEST })
    checkUser();
  }, []);
  useEffect(() => {}, [DefaultMasterLoading]);

  useEffect(() => {
    if (UploadDocumentLoading) {
      var prams = [
        {
          CompanyID: companyId,
          UserID: "22",
          SectorID: "4",
          IndustryID: ["62"],
          DocumentType: "",
          FileName: "",
          FileContent: "",
        },
      ];
      dispatch(UploadDocumentAction(prams));
    }
    if (!UploadDocumentLoading) {
      // setUploadDocumentDataNew(UploadDocumentData?.Data)
    }
  }, [dispatch, UploadDocumentLoading]);

  useEffect(() => {
    if (PeerRatioLoading) {
      dispatch(PeerRatiosAction());
    }
  }, [PeerRatioLoading]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      vdrSingleCompanyAction({
        CompanyID: companyId,
        token: "123",
        User_id: crtUserId(),
      })
    );
    dispatch({ type: "ForensicReset" });
    
    dispatch({ type: UPLOAD_DOCUMENT_REQUEST });
    dispatch({ type: QUARTERLY_SEGMENT_RESET });
    dispatch({ type: FORENSIC_TAB_SHOW_HIDE_REQUEST });
    dispatch({ type: QUARTERLY_REVIEW_RESET });
    dispatch({ type: DATA_20_YEARS_QUARTERLY_REQUEST });
    dispatch({ type: DATA_20_YEARS_REQUEST });
    dispatch({ type: PEER_TO_PEER_REQUEST });
    dispatch({ type: COMPANY_COMMENTS_REQUEST });
    dispatch({ type: REPOSITORY_LIST_REQUEST });
    dispatch({ type: 'AnnouncementsRequest' });
  }, [dispatch, companyId]);

  const theme = useTheme();

  const [value, setValue] = React.useState("1");
  const [value1, setValue1] = React.useState(null);

  const [OpenForm, setOpenForm] = React.useState(0);
  const [DataRoomDocDetail, setDataRoomDocDetail] = React.useState(0);
  const [inputType, setinputType] = React.useState({});

  const toggleDrawer = (anchor, open) => (event) => {
    if (event) {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  if (loading) {
    return <LoadingComponent />;
  }

  let ResearchTabArray = [
    "aboutUS",
    "bigPicture",
    "Promoters",
    "product",
    "price",
    "triggers",
    "risk",
    "Peers",
  ];

  const companyData1 = companyData.Company?.[0]?.companies;

  let item_recommendation =
    DefaultMasterData?.Recommendation &&
    DefaultMasterData?.Recommendation[companyData1?.recommendation];
  let item_ideaType =
    DefaultMasterData?.IdeaType &&
    DefaultMasterData?.IdeaType[companyData1?.company_type];

  let rightLabel = item_ideaType?.title;
  let rightRecommendation = item_recommendation?.title;

  const mTabsArr = [
    {
      id: "0",
      name: "Reports",
      component:(
        <>
        {companyData.Data ? (
                      <div className="sc-main_box">
                        <ClientRoomComp
                          isCD={true}
                          setOpenForm={setOpenForm}
                          setInputType={setinputType}
                          OpenForm={OpenForm}
                          DataRoomDocDetail={DataRoomDocDetail}
                          title={"Documents"}
                          setDataRoomDocDetail={setDataRoomDocDetail}
                          toggleDrawer={toggleDrawer}
                          companyId={companyId}
                        />
                      </div>
                    ) : null}
        </>
      )
    },
    {
      id: "6",
      name: "About",
      component:(
        <div className="l-cd">
            <div className='desc sc-main_box' >
                <div style={{
                    textAlign:'justify'
                 }}
                dangerouslySetInnerHTML={{
                    __html: CompanyNotesRes?.companyNotes
                 }}
                />
            </div>
            <div className="sc-main_box">
              
        <Grid container >
            <div className='comp_note_table'>
                <div container spacing={3}>
                  {
                   CompanyNoteData && CompanyNoteData?.footer.length > 0 && CompanyNoteData?.footer.map((f_item,f)=>{
                      // console.log('f_item > ', f_item)
                      let a0 = 0;
                    //   if(!StockQs){
                    //       setStockQs(f_item?.heading);
                    //   }
                      return (
                        <div key={f} item md={2} sm={2} xs={1}>
                          <div className='plItem'>
                            <table>
                              {/* <thead>
                                <tr>
                                  <td colSpan={2} style={{
                                    fontWeight: (f_item?.isBold && "500")
                                  }}><span dangerouslySetInnerHTML={{ __html:(f_item?.heading || " &nbsp; ") }}></span></td>
                                </tr>
                              </thead> */}
                              <tbody>
                                {
                                  Object.keys(f_item).map((child_item,cf)=>{
                                    if(child_item.includes('row')){
                                      a0++;
                                      let row_item = f_item[`row${a0}`];

                                      return (
                                        <tr key={cf} >
                                          {
                                            row_item.map((rItem, rIndex)=>{
                                              let cStyle = {

                                              }
                                              if(rItem?.isBold){
                                                cStyle = {
                                                  ...cStyle,
                                                  fontWeight: (rItem?.isBold && "500"),
                                                  color: '#22222F',
                                                  backgroundColor: rItem?.bg_color,
                                                }
                                              }
                                              if(row_item.length-1 == rIndex){
                                                cStyle = {
                                                  ...cStyle,
                                                  textAlign: 'right'
                                                }
                                              }
                                              return (
                                                <td style={cStyle} >{rItem?.column ? rItem?.column : (<>
                                                <span dangerouslySetInnerHTML={{ __html:"&nbsp;" }}></span>
                                                </>)}</td>
                                              )
                                            }
                                            )
                                          }
                                        </tr>
                                      )
                                    }
                                  })
                                }
                              </tbody>

                            </table>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
            </div>
        </Grid>
            </div>
            
        </div>
      )
    },
    {
      id: "7",
      name: "Chart",
      component: (
        <div className="sc-main_box">
          <HighchartsStock companyId={companyId} />
        </div>
      )
    },
    {
      id: "1",
      // name:"Valuation",
      name: "Brief",
      component:(
        <div className="sc-main_box">
        <ValuationComp
                      isCD={true}
                      title="Brief "
                      companyId={companyId}
                    />
        </div>
      )
    },
    {
      id: "2",
      name: "Financial",
      component:(
        <>
        <div className="sc-main_box" >
            <FinancialCharts
              isCD={true}
              companyId={companyId}
              technicalData={companyData && companyData?.technical}
            />
          </div>
        </>
      )
    },
    // {
    //     id:"3",
    //     name:"Technical Charts",
    // },
    {
      id: "5",
      name: "Forensic",
      component:(
        <div className="sc-main_box"><ForensicComponent dataFor={false} /></div>
      )
    },
    {
      id: "4",
      name: "Media",
      component:(
        <>
       <div className="sc-main_box">
            <MediaSection CompanyID={companyId} />
          </div>
        </>
      )
    },
    // {
    //   id: "10",
    //   name: "Report Bank",
    //   component:(
    //     <>
    //     <div className="sc-main_box">
    //         <AnalystRoomComp isCD={true} companyId={companyId} title={'Report Bank'}/>
    //       </div>
    //     </>
    //   )
    // },
    // {
    //   id: "9",
    //   name: "Notes",
    //   component:(
    //     <>
    //    <div className="sc-main_box">
    //         <NotesComp isCD={true} companyId={companyId} title={'Notes'} />
    //       </div>
    //     </>
    //   )
    // },
  ];

  return (
    <>
      <BarChartModal />
      <Box id="div1" className="compnayDetailPage">
        <Box
          className="detailContainer"
          sx={
            {
              // backgroundImage:'url(https://omkaradata.in/vdr_old/public/images/banner-details.jpg) !important'
            }
          }
        >
          {/* <div className='detail-header' style={{ backgroundImage: 'url(https://omkaradata.in/vdr_old/public/images/banner-details.jpg' }}>

                    </div> */}

          <Box
            sx={{
              zIndex: 1,
              position: "relative",
              // marginTop: '-35vh',
              padding: 2,
            }}
          >
            <Box
              className="TopContainer"
              sx={{
                marginTop: 3,
                // backgroundColor: "#fff",
                padding: 3,
                minHeight: "100vh",
              }}
            >
              {companyData.Company ? (
                <>
                  <Box className="categoryContainer sc-main_box" style={{ 
                    marginTop:0
                   }}>
                    <Box>
                      <Box className="mobLogoContainer">
                        <img
                          alt="Logo"
                          src={
                            companyData.Company[0]?.companies?.logo
                              ? companyData.Company[0]?.companies?.logo
                              : "https://omkaracapital.in/site_assets/images/logo.png"
                          }
                        />
                      </Box>
                      <Box className="titleContainer">
                        <Typography variant="h4">
                          {companyData.Company[0]?.companies.company_name}
                        </Typography>
                        <Box>
                          {/* <div>
                            <span>
                              <Typography
                                variant="body"
                                fontSize={20}
                                fontWeight="500"
                              >
                                ₹{" "}
                                {
                                  companyData.Company[0]?.companies?.price_row
                                    ?.price
                                }
                              </Typography>
                            </span>
                            <span
                              style={{
                                color:
                                  companyData.Company[0]?.companies?.price_row
                                    ?.color,
                              }}
                            >
                              <div style={{ display: "inherit" }}>
                                {companyData.Company[0]?.companies?.price_row
                                  .arrow_type == 1 ? (
                                  <>
                                    <ArrowUpwardIcon
                                      sx={{
                                        fontSize: 20,
                                        marginTop: "0.3rem",
                                        position: "relative",
                                        top: "0.2rem",
                                      }}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <ArrowDownwardIcon
                                      sx={{ fontSize: 20, marginTop: "0.3rem" }}
                                    />
                                  </>
                                )}
                                {
                                  companyData.Company[0]?.companies?.price_row
                                    ?.differencepercentage
                                }
                                %
                              </div>
                            </span>
                          </div> */}
                          <div className='price-detail'  style={{
                        display:'flex',
                        gap:'1rem'
                    }}>
                    <div>
                        <div className='price-section'>
                            <span>₹ {OverviewData && OverviewData?.BSEOHLCData?.ltp}</span>

                            <span style={{
                                color: `${OverviewData && OverviewData?.BSEOHLCData?.day_changeP > 0 ? '' : 'red'}`
                            }}><span className='p-icon' style={{
                                color: `${OverviewData && OverviewData?.BSEOHLCData?.day_changeP > 0 ? '' : 'red'}`
                            }}>
                                {
                                OverviewData && OverviewData?.BSEOHLCData?.day_changeP > 0 ?
                                    <>
                                      <AiFillCaretUp sx={{ fontSize: 20, position: 'relative', top: '0.2rem' }} />
                                    </>
                                    :
                                    <>
                                      <AiFillCaretDown sx={{ fontSize: 20, position: 'relative', top: '0.2rem', }} />
                                    </>
                                }
                                
                                </span>{OverviewData && OverviewData?.BSEOHLCData?.day_changeP && OverviewData?.BSEOHLCData?.day_changeP.toFixed(2)} %</span>
                        </div>
                        <span>{ OverviewData?.BSEOHLCData?.date && moment(OverviewData?.BSEOHLCData?.date).format('DD-MMM-YYYY hh:mm:ss') }</span>
                    </div>

                    {/* <div style={{
                        display:'flex',
                        gap:'.5rem'
                    }}>
                        <Typography sx={{
                            fontSize: '14px'
                        }}>
                            52wk H/L :
                        </Typography>
                        <Typography  sx={{
                            fontSize: '14px',
                            fontWeight:'600'
                        }}>
                            {CompanyNotesRes?.WH52}/{CompanyNotesRes?.WL52}
                        </Typography>

                    </div> */}
                </div>
                          {/* <div>
                            <Typography variant="body">
                              {companyData.Company[0]?.companies?.price_row
                                ?.priceDate
                                ? moment(
                                    companyData.Company[0]?.companies?.price_row
                                      ?.priceDate,
                                    "DD-MM-YYYY HH:mm:ss",
                                    true
                                  ).format("DD-MMM-YYYY HH:mm:ss")
                                : null}
                            </Typography>
                          </div> */}
                        </Box>
                      </Box>
                      <Box display="flex" className="afterTitle">
                        {rightRecommendation ? (
                          <Box sx={{ marginRight: "0.5rem" }}>
                            <Typography variant="contained">
                              Recommendation:{" "}
                              <span
                                style={{
                                  color: item_recommendation?.color,
                                  fontWeight: "bold",
                                }}
                              >
                                {rightRecommendation}
                              </span>
                            </Typography>
                          </Box>
                        ) : null}
                        {rightLabel ? (
                          <>
                            <Typography
                              variant="contained"
                              color="success"
                              className="pipeDivider"
                            >
                              |
                            </Typography>
                            <Box sx={{ marginLeft: "0.5rem" }}>
                              <Typography variant="contained" color="success">
                                Idea Type: <b>{rightLabel}</b>
                              </Typography>
                            </Box>
                          </>
                        ) : null}
                      </Box>
                      <Box display="flex" className="afterTitle" fontSize={13}>
                        {companyData1?.recommendation_price ? (
                          <Box sx={{ marginRight: "0.5rem" }}>
                            <Typography variant="contained" color="success">
                              {" "}
                              {rightRecommendation == "Sell" ? "Exit" : ""}{" "}
                              Reco. Date:{" "}
                              <b>
                                {companyData1?.recommendation_date
                                  ? moment(
                                      companyData1?.recommendation_date
                                    ).format("DD MMM YYYY")
                                  : null}
                              </b>
                            </Typography>
                          </Box>
                        ) : null}
                        {companyData1?.recommendation_date ? (
                          <>
                            <Typography
                              variant="contained"
                              color="success"
                              className="pipeDivider"
                            >
                              |
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                              }}
                            >
                              <Typography variant="contained">
                                {" "}
                                {rightRecommendation == "Sell"
                                  ? "Exit"
                                  : ""}{" "}
                                Reco. Price: ₹{" "}
                                <span style={{ fontWeight: "600" }}>
                                  {companyData1?.recommendation_price}
                                </span>
                              </Typography>
                            </Box>
                          </>
                        ) : null}
                        {companyData1?.recommendation_Difference ? (
                          <>
                            <Typography
                              variant="contained"
                              color="success"
                              className="pipeDivider"
                            >
                              |
                            </Typography>
                            <Box
                              sx={{
                                marginLeft: "0.5rem",
                                marginRight: "0.5rem",
                              }}
                            >
                              <Typography variant="contained">
                                Change from Reco.:
                                <Typography
                                  variant="contained"
                                  sx={{
                                    color:
                                      companyData1?.recommendation_Difference >
                                      0
                                        ? "green"
                                        : "red",
                                    fontWeight: "bold",
                                    display: "inline",
                                    marginLeft: "3px",
                                  }}
                                >
                                  {companyData1?.recommendation_Difference > 0
                                    ? companyData1?.recommendation_Difference +
                                      "%"
                                    : companyData1?.recommendation_Difference +
                                      "%"}
                                </Typography>
                              </Typography>
                            </Box>
                          </>
                        ) : null}
                      </Box>
                    </Box>

                    <Box className="logoContainer">
                      <div>
                        <img
                          alt="Company Logo"
                          src={
                            companyData.Company[0]?.companies?.logo
                              ? companyData.Company[0]?.companies?.logo
                              : "https://omkaracapital.in/site_assets/images/logo.png"
                          }
                        />
                      </div>
                    </Box>
                  </Box>

                  {/* <Box
                    sx={{
                      marginTop: 5,
                    }}
                  >
                    <Typography
                      variant="body1"
                      dangerouslySetInnerHTML={{
                        __html: `${companyData.Company[0]?.companies?.about}`,
                      }}
                    ></Typography>
                  </Box> */}
                </>
              ) : null}

              {/* <Divider sx={{ margin: "0.5rem 0" }} /> */}


              <Box sx={{ width: '100%', typography: 'body1' }} style={{ 
                // backgroundColor:'#fff000'
               }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', 'borderRadius':'1rem',backgroundColor:'#fff', padding: '0 2rem' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      {
                        mTabsArr.map((item, index00) => {
                          return <Tab label={item?.name} value={String(index00+1)} /> //<Tab label={item?.name} {...a11yProps(index00)} />;
                        })
                      }
                    </TabList>
                  </Box>
                  {
                      mTabsArr.map((item, index00) => {
                        return <TabPanel className="sc_box" value={String(index00+1)} sx={{ 
                          paddingY:0,
                          paddingX:.25,
                         }}>
                          {item?.component}
                        </TabPanel> //<Tab label={`Item One ${(index00+1)}`} value={String(index00+1)} /> //<Tab label={item?.name} {...a11yProps(index00)} />;
                      })
                    }
                  {/* <TabPanel value="1" >Item One</TabPanel>
                  <TabPanel value="2" >Item Two</TabPanel>
                  <TabPanel value="3" >Item Three</TabPanel> */}
                </TabContext>
              </Box>



              {/* <Box
                sx={{
                  marginTop: 0,
                }}
                className=""
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="full width tabs example"
                >

                  {mTabsArr.map((item, index00) => {
                    return <Tab label={item?.name} {...a11yProps(index00)} />;
                  })}
                </Tabs>


                <SwipeableViews
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                  className="SwipeableViews"
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    {companyData.Data ? (
                      <>
                        <ClientRoomComp
                          isCD={true}
                          setOpenForm={setOpenForm}
                          setInputType={setinputType}
                          OpenForm={OpenForm}
                          DataRoomDocDetail={DataRoomDocDetail}
                          title={"Documents"}
                          setDataRoomDocDetail={setDataRoomDocDetail}
                          toggleDrawer={toggleDrawer}
                          companyId={companyId}
                        />
                      </>
                    ) : null}
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <ValuationComp
                      isCD={true}
                      title="Brief "
                      companyId={companyId}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    {companyData.valuationTable ? (
                      <>
                        <FinancialCharts
                          isCD={true}
                          companyId={companyId}
                          technicalData={companyData && companyData?.technical}
                        />
                      </>
                    ) : null}
                  </TabPanel>

                  <TabPanel value={value} index={3} dir={theme.direction}>
                    {companyData.valuationTable ? (
                      <>
                        <MediaSection CompanyID={companyId} />
                      </>
                    ) : null}
                  </TabPanel>

                  <TabPanel value={value} index={4} dir={theme.direction}>
                    <ForensicComponent dataFor="vdr" />
                  </TabPanel>
                </SwipeableViews>
              </Box> */}
            </Box>
          </Box>
          <MediaModel />
        </Box>
      </Box>

      {/* <div>{companyName}</div>
        <div>{companyId}</div> */}
    </>
  );
};

export default CompanyDetail;
