import { Typography } from '@mui/material'
import React from 'react'

const ConStdButtonError = ({
    BtnStatusError,
    setBtnStatusError,
}) => {
  return (
    <>
      
      {
            BtnStatusError && (
              <>
                <Typography>Data not found!</Typography>
              </>
            )
          } 
    </>
  )
}

export default ConStdButtonError
