"use client";
import React, { createContext, useEffect, useState } from "react";
export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {

    const [ActiveSecondaryBtn, setActiveSecondaryBtn] = useState(1)
    const [ActiveNewsLetter, setActiveNewsLetter] = useState(null)
    const [ActiveNewsLetterQuarter, setActiveNewsLetterQuarter] = useState(null)

    const [DIR_Model, setDIR_Model] = useState(false);
    const [SelectedDIR, setSelectedDIR] = useState(null);

    const [IsCompanyDetail, setIsCompanyDetail] = useState(false);


  
  return (
    <GlobalContext.Provider value={{ 
        IsCompanyDetail, setIsCompanyDetail,
        ActiveSecondaryBtn, setActiveSecondaryBtn,
        ActiveNewsLetterQuarter, setActiveNewsLetterQuarter,
        DIR_Model, setDIR_Model,
        SelectedDIR, setSelectedDIR,
        ActiveNewsLetter, setActiveNewsLetter
    }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
