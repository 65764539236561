import { Box, Button, Grid, Tabs, Tab, Typography, Divider } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import TradingViewWidget from 'react-tradingview-widget';
import { CustomChart } from '../../../frontend/components';
import { quarterlyReviewAction, quarterlySegmentAction, vdrData20YearsAction, vdrData20YearsQuarterlyAction, vdrPeerToPeerAction } from '../../../redux/actions/VdrAction';
import PropTypes from 'prop-types';
import { historicalTrendChooseField } from '../../../constants/defaultArray';
import HistoricalTrendChooseField from './HistoricalTrendChooseField';
import PeerToPeerTrendChooseField from './PeerToPeerTrendChooseField';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import ForensicComponent from '../../../frontend/components/29-april-ForensicComponent';
import QuarterlyReview from './QuarterlyReview';
import QuarterlySegment from './QuarterlySegment';
import { BalanceSheetComp, CashflowComp, InterimResultsComp, PeersComp, ProfitLossStatement, RatioComp, ShareholdingPatternComp } from '../../../pages/single_compamy/components';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { FORENSIC_TAB_SHOW_HIDE_REQUEST } from '../../../constants/userConstants';
import { DATA_20_YEARS_QUARTERLY_REQUEST, SINGLE_COMP_PROFIT_LOSS_REQUEST, SingleCompanyBalanceSheetREQUEST } from '../../../constants/VdrConstants';
import { GlobalContext } from '../../../context/GlobalContext';


const FinancialCharts = (props) => {


    
    const { technicalData, isCD, companyId, showForensic, showDownload, setOpenForm, toggleDrawer, setParentGroup } = props;
    const myRef = useRef();
    const divRef = useRef();
    const HistoricalRef = useRef();
    const PeerToPeerRef = useRef();

    const dispatch = useDispatch();

    const { data: quarterlyData, loading: quarterlyLoading } = useSelector(state => state.vdrData20YearsQuarterlyReducer)
    const { data, loading } = useSelector(state => state.vdrData20YearsReducer);
    const { data: peerToPeerData, loading: peerToPeerLoading } = useSelector(state => state.vdrPeerToPeerReducer);

    const { data: quarterlyReviewData, loading: quarterlyReviewLoading } = useSelector(state => state.quarterlyReviewReducer);
    const { data: quarterlySegmentData, loading: quarterlySegmentLoading } = useSelector(state => state.quarterlySegmentReducer);


    const [ActiveQuarterlyReview, setActiveQuarterlyReview] = useState(false);

    const [ActiveQuarterlyData, setActiveQuarterlyData] = useState(null);
    const [QuarterlyAllData, setQuarterlyAllData] = useState(null);

    const [QuarterlyAllDataTotal, setQuarterlyAllDataTotal] = useState(0);

    const [HistoricalTrendData, setHistoricalTrendData] = useState(null);
    const [PeerToPeerData, setPeerToPeerData] = useState(null);

    const [PeerToPeerDataChartType, setPeerToPeerDataChartType] = useState(null);
    
    const [QuaterlySegmentType, setQuaterlySegmentType] = useState('Standalone');

    // useEffect(()=>{
    //     if(quarterlySegmentLoading){
    //         dispatch(quarterlySegmentAction({companyID:companyId}, QuaterlySegmentType));
    //     }
    // }, [quarterlySegmentLoading])
    const { data: dataAPIData, loading: dataAPILoading } = useSelector((state) => state.dateAPIReducer);
    const quarterlySelect = (quarter) => {
        setActiveQuarterlyData(quarter)
        if(quarter === "QuarterlyReview" ){
            setActiveQuarterlyReview(true);
            if(quarterlyReviewLoading){
                dispatch(quarterlyReviewAction({companyID:companyId}));
            }
        }else
        if(quarter === "QuarterlySegment" ){
            setActiveQuarterlyReview(false);
            // if(quarterlySegmentLoading){
            //     dispatch(quarterlySegmentAction({companyID:companyId}, QuaterlySegmentType));
            // }
        }else
        {
            setActiveQuarterlyReview(false);
            
            let qData = quarterlyData.Data;
            let demo0 = [];
            let title = null;
            if (qData && qData.length > 0) {
                qData = qData[0];
                Object.keys(qData).map((item, i) => {
                    var demo1 = { cat: [], value: [], title: title, typeFor: quarter, YoYQoQ: {} };
                    if (item !== '$id' && item !== '_MainHeader') {
                        var itemQData = qData[item];
                        var a = 0;
                        var a1 = 0;
                        Object.keys(itemQData).map((subItem, i1) => {
                            if (quarter === 20 || a <= quarter) {
                                if (subItem !== '$id') {
    
                                    if (subItem === '_chartName') {
                                        demo1.title = title = itemQData[subItem]?.Name;
                                    } else {
                                        if(itemQData[subItem]?.Value !== null && itemQData[subItem]?.Value !== undefined){
                                            demo1.cat = [...demo1.cat, itemQData[subItem]?.Name]
                                            demo1.value = [...demo1.value, itemQData[subItem]?.Value]
                                        }
                                    }
                                }
                            }
                            if(itemQData[subItem]?.Value !== null && itemQData[subItem]?.Value !== undefined){
                                a1++
                            }
                            a++;
                            if (subItem === '_chartName') {
                                demo1.title = title = itemQData[subItem]?.Name;
                            }
    
                            if (quarter === 5 && subItem === 'YoYQoQ') {
                                demo1.YoYQoQ = { YoY: (itemQData[subItem]?.YoY || null), QoQ: (itemQData[subItem]?.QoQ || null) }
                            }
    
    
                        });
                        setQuarterlyAllDataTotal(a1);
                        // console.log(demo1);
                        demo0[item] = demo1;
                    }
                })

                // console.log('setQuarterlyAllData >>>> ', demo0);
                setQuarterlyAllData(demo0)
            }

        }

    }


    const applyChart = (chartType) => {
        if (!chartType.length) {
            chartType = ["Market Cap (cr.)", "Net Sales (cr.)", "Cons PAT (cr.)", "Gross Profit (cr.)", "Gross Profit Margin (%)", "EBIDTA (cr.)"]
        }
        dispatch(vdrData20YearsAction([
            {
                "CompanyID": companyId,
                "userid": 1,
                "Param": chartType,
                "ChartType": "Annually"
            }
        ]))
    }





    useEffect(() => {
        if (loading) {
            dispatch(vdrData20YearsAction([
                {
                    "CompanyID": companyId,
                    "userid": 1,
                    "Param": ["Market Cap (cr.)", "Net Sales (cr.)", "Cons PAT (cr.)", "Gross Profit (cr.)", "Gross Profit Margin (%)", "EBIDTA (cr.)"],
                    "ChartType": "Annually"
                }
            ]))
        }
        if (!loading) {
            let qData = data.Data;
            let demo0 = [];
            let title = null;
            if (qData && qData.length > 0) {
                qData = qData[0];
                Object.keys(qData).map((item, i) => {
                    var demo1 = { cat: [], value: [], title: title, typeFor: 20, CAGR: "" };
                    if (item !== '$id' && item !== '_MainHeader') {
                        var itemQData = qData[item];
                        var a = 0;
                        Object.keys(itemQData).map((subItem, i1) => {

                            if (subItem !== '$id') {
                                if (subItem === '_chartName') {
                                    demo1.title = title = itemQData[subItem]?.Name;
                                    if (itemQData[subItem]?.CAGR) {
                                        demo1.CAGR = itemQData[subItem]?.CAGR;
                                    }
                                } else {
                                    if (itemQData[subItem]?.Value !== null && itemQData[subItem]?.Value !== undefined) {
                                        demo1.cat = [...demo1.cat, itemQData[subItem]?.Name]
                                        // var value1 = itemQData[subItem]?.Value ? itemQData[subItem]?.Value.toFixed() : itemQData[subItem]?.Value;
                                        var value1 = parseFloat(itemQData[subItem]?.Value).toFixed(0);
                                        
                                        demo1.value = [...demo1.value, value1]
                                    }
                                }
                            }
                        });
                        demo0[item] = demo1;
                    }
                })
                setHistoricalTrendData(demo0)
            }
        }
    }, [dispatch, loading])



    const exportImageData = async (current_ref) => {
        // console.log('exportImageData')
        await htmlToImage.toJpeg(current_ref)
        .then(function (dataUrl) {
          // console.log(dataUrl);
          download(dataUrl, 'my-node.png');
        });
      }



    const applyAction = (chartType, companies) => {
        let companies1 = companies;
          var companies0 = companies1.unshift(companyId);
        // console.log(companies1);
        dispatch(vdrPeerToPeerAction([{
            "CompanyId": companyId,
            "userid": 1,
            "param": chartType,
            "CompanyParam": companies1
        }]))
        setPeerToPeerDataChartType(chartType);

        // dispatch(vdrData20YearsAction([
        //     {
        //         "CompanyID": companyId,
        //         "userid": 1,
        //         "Param": chartType,
        //         "ChartType": "Annually"
        //     }
        // ]))
    }


    useEffect(() => {
        if (peerToPeerLoading) {
            // dispatch(vdrPeerToPeerAction([{ "CompanyId": 132805, "userid": "22", "param": "PE (x)", "CompanyParam": ["132805", "105412", "106076", "132670", "101425", "124226"] }]))
        }
        if (!peerToPeerLoading) {
            let qData = peerToPeerData.Data;
            let demo0 = [];
            let title = null;
            if (qData && qData.length > 0) {
                qData = qData[0];
                Object.keys(qData).map((item, i) => {
                    var demo1 = { cat: [], value: [], title: title, typeFor: 20 };
                    if (item !== '$id' && item !== '_MainHeader') {
                        var itemQData = qData[item];
                        var a = 0;
                        Object.keys(itemQData).map((subItem, i1) => {

                            if (subItem !== '$id') {
                                if (subItem === '_chartName') {
                                    demo1.title = title = itemQData[subItem]?.Name;
                                } else {
                                    // if (itemQData[subItem]?.Value !== null) {
                                        demo1.cat = [...demo1.cat, itemQData[subItem]?.Name]
                                        var value1 = itemQData[subItem]?.Value?parseFloat(itemQData[subItem]?.Value).toFixed(0):null;
                                        demo1.value = [...demo1.value, value1]
                                    // }
                                }
                            }
                        });
                        demo0[item] = demo1;
                    }
                })
                setPeerToPeerData(demo0)
            }
        }
    }, [dispatch, peerToPeerLoading])

    // useEffect(() => {
    //     if (quarterlyLoading) {
    //         dispatch(vdrData20YearsQuarterlyAction([
    //             {
    //                 "CompanyID": companyId,
    //                 "userid": 1,
    //                 "Param": [],
    //                 "ChartType": "Quarterly",
    //                 "Type": "con"
    //             }
    //         ]))
    //     }
    //     if (!quarterlyLoading) {
    //         quarterlySelect(5);
    //     }
    // }, [dispatch, quarterlyLoading])

    useEffect(()=>{
        dispatch({ type: 'ForensicRequest' });
    },[])



    const [value, setValue] = React.useState("1");
    const handleChange = (event, newValue) => {
        // dispatch({ type: DATA_20_YEARS_QUARTERLY_REQUEST });
        dispatch({ type: SINGLE_COMP_PROFIT_LOSS_REQUEST });
        dispatch({ type: SingleCompanyBalanceSheetREQUEST });
        setValue(newValue);
    };


    const {
        ActiveSecondaryBtn, setActiveSecondaryBtn
      } = useContext(GlobalContext)

    const tabsArr = [
        {
            "id":1,
            "isShow": true,
            // "name":"Interim Results",
            "name":"Quarterly P&L",
            "component":(
                <>
                <Box sx={{ 
                        marginTop: 1
                    }}>
                    <div style={{ textAlign:'end', fontSize:'.75rem', fontWeight:'700', marginBottom:'.25rem' }}>
                       
                        (In Cr.)
                    </div>
                    <InterimResultsComp isCD={isCD} companyId={companyId} />
                </Box>
                </>
            ),
        },
        
        {
            "id":6,
            "isShow": true,
            "name":"Annual P&L",
            "component":(
                <Box  sx={{ 
                    marginTop: 1
                }}>
                    <div style={{ textAlign:'end', fontSize:'.75rem', fontWeight:'700', marginBottom:'.25rem' }}>
                        (In Cr.)
                    </div>
                    <ProfitLossStatement isCD={isCD} companyId={companyId} />
                </Box>
            ),
        },
        {
            "id":5,
            "isShow": true,
            "name":"Balance Sheet",
            "component":(
                <Box  sx={{ 
                    marginTop: 1
                }}>
                    <div style={{ textAlign:'end', fontSize:'.75rem', fontWeight:'700', marginBottom:'.25rem' }}>
                        (In Cr.)
                    </div>
                    <BalanceSheetComp isCD={isCD} companyId={companyId} />
                </Box>
            ),
        },
        {
            "id":8,
            "isShow": true,
            "name":"Cash Flows",
            "component":(
                <Box  sx={{ 
                    marginTop: 1
                }}>
                    <div style={{ textAlign:'end', fontSize:'.75rem', fontWeight:'700', marginBottom:'.25rem' }}>
                        (In Cr.)
                    </div>
                    <CashflowComp isCD={isCD} companyId={companyId} />
                </Box>
            ),
        },
        {
            "id":10,
            "isShow": true,
            "name":"Ratios",
            "component":(
                <Box  sx={{ 
                    marginTop: 1
                }}>
                <div style={{ textAlign:'end', fontSize:'.75rem', fontWeight:'700' }}>
                    (In Cr.)
                </div>
                    <RatioComp isCD={isCD} companyId={companyId} />
                    {/* <PeersComp isCD={isCD} companyId={companyId} /> */}
                </Box>
            ),
        },
        {
            "id":7,
            "isShow": true,
            "name":"Peers",
            "component":(
                <Box  sx={{ 
                    marginTop: 1
                }}>
                    <PeersComp isCD={isCD} companyId={companyId} />
                </Box>
            ),
        },
        {
            "id":9,
            "isShow": true,
            "name":"Shareholding %",
            "component":(
                <Box  sx={{ 
                    marginTop: 1
                }}>
                    <ShareholdingPatternComp isCD={isCD}  companyId={companyId} />
                </Box>
            ),
        },

    ]



    return (
        <>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                
                <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                    {
                        tabsArr.map((item, i)=>{
                            let a0 = String(i+1)
                            console.log(typeof a0, String(i+1))
                            return (
                                <Tab   sx={{
                                    fontSize: '0.7rem'
                                }} label={item?.name} value={String(i+1)} />
                            )
                        })
                    }

                    </TabList>
                </Box>
                {
                    tabsArr.map((item, i)=>{
                        return (
                            <>
                                <TabPanel value={String(i+1)}>
                                    {item?.component}
                                </TabPanel>
                            </>
                        )
                    })
                }
                {/* <TabPanel value="1">Item One</TabPanel>
                <TabPanel value="2">Item Two</TabPanel>
                <TabPanel value="3">Item Three</TabPanel> */}
                </TabContext>
            </Box>


            {/* <Box sx={{ width: '100%' }} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}
                >
                    <Tabs variant='scrollable' allowScrollButtonsMobile
                                    scrollButtons="auto" value={value} onChange={handleChange} aria-label="basic tabs example">

{
    tabsArr.map((item, i)=>{
        return (
            <>
                <Tab sx={{
                    fontSize: '0.7rem'
                }} label={item?.name} {...a11yProps(i)} />
            </>
        )
    })
}

                    </Tabs>
                </Box>
                {
    tabsArr.map((item, i)=>{
        return (
            <>
                <TabPanel value={value} index={i}>
                    {item?.component}
                </TabPanel>
            </>
        )
    })
}
                
            </Box> */}


        </>
    )
}

export default FinancialCharts