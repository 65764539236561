import {
  VDR_GROUPS_REQUEST,
  VDR_GROUPS_SUCCESS,
  VDR_GROUPS_FAIL,
  VDR_COMPANY_REQUEST,
  VDR_COMPANY_SUCCESS,
  VDR_COMPANY_FAIL,
  VDR_LEVEL_REQUEST,
  VDR_LEVEL_SUCCESS,
  VDR_LEVEL_FAIL,
  VDR_HOME_REQUEST,
  VDR_HOME_SUCCESS,
  VDR_HOME_FAIL,
  VDR_UPLOAD_IMAGE_REQUEST,
  VDR_UPLOAD_IMAGE_SUCCESS,
  VDR_UPLOAD_IMAGE_FAIL,
  PUBLIC_IP_REQUEST,
  PUBLIC_IP_SUCCESS,
  PUBLIC_IP_FAIL,
  VDR_SINGLE_COMPANY_REQUEST,
  VDR_SINGLE_COMPANY_SUCCESS,
  VDR_SINGLE_COMPANY_FAIL,
  PDF_LINK_REQUEST,
  PDF_LINK_SUCCESS,
  MEDIA_MODEL_REQUEST,
  MEDIA_MODEL_OPEN,
  MEDIA_MODEL_CLOSE,
  MEDIA_DATA_REQUEST,
  MEDIA_DATA_SUCCESS,
  MEDIA_DATA_FAIL,
  DATA_20_YEARS_REQUEST,
  DATA_20_YEARS_SUCCESS,
  DATA_20_YEARS_FAIL,
  DATA_20_YEARS_QUARTERLY_REQUEST,
  DATA_20_YEARS_QUARTERLY_SUCCESS,
  DATA_20_YEARS_QUARTERLY_FAIL,
  PEER_TO_PEER_REQUEST,
  PEER_TO_PEER_SUCCESS,
  PEER_TO_PEER_FAIL,
  SORT_DATA_REQUEST,
  SORT_DATA_SUCCESS,
  SORT_DATA_FAIL,
  SORT_DATA_UPDATE_REQUEST,
  SORT_DATA_UPDATE_SUCCESS,
  SORT_DATA_UPDATE_FAIL,
  VDR_HOME_SUCCESS_FILTER,
  FAVORITE_REQUEST,
  FAVORITE_SUCCESS,
  FAVORITE_FAIL,
  NOTIFICATION_REQUEST,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_FAIL,
  EMPLOYEE_REQUEST,
  EMPLOYEE_SUCCESS,
  EMPLOYEE_FAIL,
  SEGMENT_REQUEST,
  SEGMENT_SUCCESS,
  SEGMENT_FAIL,
  SEGMENT_ASSIGN_REQUEST,
  SEGMENT_ASSIGN_SUCCESS,
  SEGMENT_ASSIGN_FAIL,
  QUARTERLY_REVIEW_REQUEST,
  QUARTERLY_REVIEW_RESET,
  QUARTERLY_REVIEW_SUCCESS,
  QUARTERLY_REVIEW_FAIL,
  QUARTERLY_SEGMENT_REQUEST,
  QUARTERLY_SEGMENT_SUCCESS,
  QUARTERLY_SEGMENT_RESET,
  QUARTERLY_SEGMENT_FAIL,
  DATAROOM_TICKER_REQUEST,
  DATAROOM_TICKER_SUCCESS,
  DATAROOM_TICKER_FAIL,
  FORUM_LIST_REQUEST,
  FORUM_LIST_SUCCESS,
  FORUM_LIST_FAIL,
  FORUM_DETAIL_REQUEST,
  FORUM_DETAIL_SUCCESS,
  FORUM_DETAIL_FAIL,
  QUARTERLY_RESULTS_REQUEST,
  QUARTERLY_RESULTS_SUCCESS,
  QUARTERLY_RESULTS_FAIL,
  QUARTERLY_SNAPSHOT_REQUEST,
  QUARTERLY_SNAPSHOT_SUCCESS,
  QUARTERLY_SNAPSHOT_FAIL,
  SINGLE_COMP_PROFIT_LOSS_REQUEST,
  SINGLE_COMP_PROFIT_LOSS_SUCCESS,
  SINGLE_COMP_PROFIT_LOSS_FAIL,
  VIDEO_LIKE_REQUEST,
  VIDEO_LIKE_SUCCESS,
  VIDEO_LIKE_FAIL,
  VIDEO_COMMENT_REQUEST,
  VIDEO_COMMENT_SUCCESS,
  VIDEO_COMMENT_FAIL,
  VIDEO_COMMENT_LIKE_REQUEST,
  VIDEO_COMMENT_LIKE_SUCCESS,
  VIDEO_COMMENT_LIKE_FAIL,
  NEWSLETTER_LIKE_REQUEST,
  NEWSLETTER_LIKE_SUCCESS,
  NEWSLETTER_LIKE_FAIL,
  NEWSLETTER_COMMENT_ADD,
  NEWSLETTER_COMMENT_DELETE,
  NEWSLETTER_COMMENT_REQUEST,
  NEWSLETTER_COMMENT_SUCCESS,
  NEWSLETTER_COMMENT_FAIL,
  NEWSLETTER_COMMENT_LIKE_REQUEST,
  NEWSLETTER_COMMENT_LIKE_SUCCESS,
  NEWSLETTER_COMMENT_LIKE_FAIL,
  SINGLE_COMP_CASHFLOW_REQUEST,
  SINGLE_COMP_CASHFLOW_SUCCESS,
  SINGLE_COMP_CASHFLOW_FAIL,
  NEWSLETTER_COMMENT_1_LIKE_SUCCESS,
  SINGLE_NEWSLETTER_LIKE_REQUEST,
  SINGLE_NEWSLETTER_LIKE_SUCCESS,
  SINGLE_NEWSLETTER_LIKE_FAIL,
  VDRMediaMyFavouriteListREQUEST,
  VDRMediaMyFavouriteListSUCCESS,
  VDRMediaMyFavouriteListFAIL,
  MyBlogListREQUEST,
  MyBlogListSUCCESS,
  MyBlogListFAIL,
  SingleCompanyBalanceSheetREQUEST,
  SingleCompanyBalanceSheetSUCCESS,
  SingleCompanyBalanceSheetFAIL,
  SingleCompanypeersREQUEST,
  SingleCompanypeersSUCCESS,
  SingleCompanypeersFAIL,
  SingleCompanyratiosREQUEST,
  SingleCompanyratiosSUCCESS,
  SingleCompanyratiosFAIL,
  PEER_RATIOS_REQUEST,
  PEER_RATIOS_SUCCESS,
  PEER_RATIOS_FAIL,
  PEER_TO_PEER_SC_REQUEST,
  PEER_TO_PEER_SC_SUCCESS,
  PEER_TO_PEER_SC_FAIL,
  REPOSITORY_LIST_RESET,
  REPOSITORY_LIST_REQUEST,
  REPOSITORY_LIST_SUCCESS,
  REPOSITORY_LIST_UPDATE,
  REPOSITORY_LIST_FAIL,
  FORUM_TAGS_LIST_REQUEST,
  FORUM_TAGS_LIST_SUCCESS,
  FORUM_TAGS_LIST_FAIL,

  FORUM_LISTINSIGTHS_REQUEST,
  FORUM_LISTINSIGTHS_SUCCESS,
  FORUM_LISTINSIGTHS_FAIL,


  FORUM3_DETAIL_REQUEST,
  FORUM3_DETAIL_SUCCESS,
  FORUM3_DETAIL_FAIL,

} from "./../../constants/VdrConstants";

import publicIp from "public-ip";

import { createReducer, current } from "@reduxjs/toolkit";

export const FavoriteCompanyReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    FavoriteCompanyReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    FavoriteCompanyRequest: (state) => {
      state.loading = true;
    },
    FavoriteCompanySuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    FavoriteCompanyFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const dateAPIReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    dateAPIReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    dateAPIRequest: (state) => {
      state.loading = true;
    },
    dateAPISuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    dateAPIFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const DefaultMasterReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    DefaultMasterRequest: (state) => {
      state.loading = true;
    },
    DefaultMasterSuccess: (state, action) => {
      // console.log('action.payload >>><<<<?? ', action.payload)
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    DefaultMasterFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const PriceActionFilterReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    PriceActionFilterReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    PriceActionFilterRequest: (state) => {
      state.loading = true;
    },
    PriceActionFilterSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    PriceActionFilterFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const priceIndexMasterReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    PriceIndexReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    PriceIndexRequest: (state) => {
      state.loading = true;
    },
    PriceIndexSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    PriceIndexFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const PriceActionReducer = createReducer(
  {
    firstLoading: true,
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    PriceActionReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    PriceActionRequest: (state) => {
      state.loading = true;
    },
    PriceActionSuccess: (state, action) => {
      state.loading = false;
      state.firstLoading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    PriceActionFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const ForensicReducer = createReducer(
  {
    firstLoading: true,
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    ForensicReset: (state) => {
      state.loading = true;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    ForensicRequest: (state) => {
      state.loading = true;
    },
    ForensicSuccess: (state, action) => {
      state.loading = false;
      state.firstLoading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    ForensicFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const ShareholdingReducer = createReducer(
  {
    firstLoading: true,
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    ShareholdingReset: (state) => {
      state.loading = true;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    ShareholdingRequest: (state) => {
      state.loading = true;
    },
    ShareholdingSuccess: (state, action) => {
      state.loading = false;
      state.firstLoading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    ShareholdingFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const BoardOfDirectorDetailReducer = createReducer(
  {
    firstLoading: true,
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    BoardOfDirectorDetailReset: (state) => {
      state.loading = true;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    BoardOfDirectorDetailRequest: (state) => {
      state.loading = true;
    },
    BoardOfDirectorDetailSuccess: (state, action) => {
      state.loading = false;
      state.firstLoading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    BoardOfDirectorDetailFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const MessageReducer = createReducer(
  {
    message: {},
  },
  {
    MessageRequest: (state, action) => {
      state.message = action.payload;
    },
  }
);

export const GetOmakaraUsersReducer = createReducer(
  {
    loadingUsers: true,
    data: [],
  },
  {
    getOmakaraUsers: (state, action) => {
      state.data = action.payload;
      state.loadingUsers = false;
    },
  }
);

export const filesLogReducer = createReducer(
  {
    loading: true,
    loadingOnAdd: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    FilesLogReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    FilesLogRequest: (state) => {
      state.loading = true;
      state.loadingOnAdd = false;
    },
    FilesLogAdd: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    FilesLogSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    FilesLogFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
  }
);

export const AssignEmployeeReducer = createReducer(
  {
    loading: true,
    loadingOnAdd: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    AssignEmployeeReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    AssignEmployeeRequest: (state) => {
      state.loading = true;
      state.loadingOnAdd = false;
    },
    AssignEmployeeAdd: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    AssignEmployeeSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    AssignEmployeeFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
  }
);

export const vdrMediaGroupReducer = createReducer(
  {
    loading: true,
    loadingOnAdd: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    vdrMediaGroupReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    vdrMediaGroupRequest: (state) => {
      state.loading = true;
      state.loadingOnAdd = false;
    },
    vdrMediaGroupAdd: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    vdrMediaGroupSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    vdrMediaGroupFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
  }
);

export const vdrMediaReducer = createReducer(
  {
    loading: true,
    loadingOnAdd: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    vdrMediaReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    vdrMediaRequest: (state) => {
      state.loading = true;
      state.loadingOnAdd = false;
    },
    vdrMediaAdd: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    vdrMediaSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    vdrMediaFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
  }
);

export const VDRMediaMyFavouriteListReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VDRMediaMyFavouriteListREQUEST:
      return {
        ...state,
        loading: true,
      };
    case VDRMediaMyFavouriteListSUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case VDRMediaMyFavouriteListFAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const MyBlogListReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case MyBlogListREQUEST:
      return {
        ...state,
        loading: true,
      };
    case MyBlogListSUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        // totalRows: action.payload?.data.length,
      };
    case MyBlogListFAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const SingleCompanyBalanceSheetReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SingleCompanyBalanceSheetREQUEST:
      return {
        ...state,
        loading: true,
      };
    case SingleCompanyBalanceSheetSUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case SingleCompanyBalanceSheetFAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const SingleCompanypeersReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SingleCompanypeersREQUEST:
      return {
        ...state,
        loading: true,
      };
    case SingleCompanypeersSUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case SingleCompanypeersFAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const SingleCompanyratiosReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SingleCompanyratiosREQUEST:
      return {
        ...state,
        loading: true,
      };
    case SingleCompanyratiosSUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case SingleCompanyratiosFAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrLevelReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VDR_LEVEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VDR_LEVEL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case VDR_LEVEL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrGroupsReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VDR_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VDR_GROUPS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case VDR_GROUPS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrCompanyReducer = (
  state = {
    loading: true,
    loadingAdd: true,
    data: [],
    totalRows: 0,
    msg: null,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case VDR_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
        loadingAdd: true,
      };
    case VDR_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingAdd: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case VDR_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        loadingAdd: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrEmployeeReducer = (
  state = {
    loading: true,
    loadingAdd: true,
    data: [],
    totalRows: 0,
    msg: null,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case EMPLOYEE_REQUEST:
      return {
        ...state,
        loading: true,
        loadingAdd: true,
      };
    case EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingAdd: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.data.length,
      };
    case EMPLOYEE_FAIL:
      return {
        ...state,
        loading: false,
        loadingAdd: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const dataRoomTickerReducer = (
  state = {
    loading: true,
    loadingAdd: true,
    data: [],
    totalRows: 0,
    msg: null,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case DATAROOM_TICKER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DATAROOM_TICKER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.data.length,
      };
    case DATAROOM_TICKER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const ForumReducer = ( state = { loading: true, data: [], totalRows: 0, msg: null, error: null },action) => {
  switch (action.type) {
    case FORUM_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORUM_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        // totalRows: action.payload?.data.length,
      };
    case FORUM_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};





export const ForumTagsReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case FORUM_TAGS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORUM_TAGS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        // totalRows: action.payload?.data.length,
      };
    case FORUM_TAGS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const ForumDetailReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case FORUM_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORUM_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case FORUM_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrHomeReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VDR_HOME_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VDR_HOME_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case VDR_HOME_SUCCESS_FILTER:
      return {
        ...state,
        loading: false,
        filterData: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case VDR_HOME_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrSingleCompanyReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VDR_SINGLE_COMPANY_REQUEST:
      return {
        ...state,
        data: [],
        loading: true,
      };
    case VDR_SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        // totalRows: action.payload?.Data.length,
      };
    case VDR_SINGLE_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrUploadImageReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VDR_UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VDR_UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        totalRows: action.payload?.Data.length,
      };
    case VDR_UPLOAD_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrPDFLinkReducer = (
  state = { loading: true, data: null, totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case PDF_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PDF_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    default:
      return state;
  }
};

export const ipAddressReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case PUBLIC_IP_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PUBLIC_IP_SUCCESS:
      localStorage.setItem("ipData", JSON.stringify(action.payload));
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case PUBLIC_IP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrMediaDataReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case MEDIA_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MEDIA_DATA_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case MEDIA_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrData20YearsReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case DATA_20_YEARS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DATA_20_YEARS_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case DATA_20_YEARS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrPeerToPeerReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case PEER_TO_PEER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PEER_TO_PEER_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case PEER_TO_PEER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrPeerToPeerScReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case PEER_TO_PEER_SC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case PEER_TO_PEER_SC_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case PEER_TO_PEER_SC_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrFavoriteReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case FAVORITE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FAVORITE_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case FAVORITE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrNotificationReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrSortDataReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SORT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SORT_DATA_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case SORT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const PeerRatiosReducer = (
  state = {
    loading: true,
    data: [],
    other_companies: [],
    selected_companies: [],
    isSelected: [],
    msg: null,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case PEER_RATIOS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case "PEER_RATIOS_SELECTED":
      // let crtData = state.data;
      // crtData = crtData
      // console.log('crtData <<<>>>>', state, crtData)
      return {
        ...state,
        isSelected: action.payload,
      };
    case "PEER_RATIOS_SELECTED_COMPANY":
      // let crtData = state.data;
      // crtData = crtData
      // console.log('crtData <<<>>>>', state, crtData)
      let other_companies = action.payload.map((item) => item?.value);
      console.log("other_companies >>>> ", other_companies);
      return {
        ...state,
        selected_companies: action.payload,
        other_companies: other_companies,
      };
    case PEER_RATIOS_SUCCESS:
      let a0 = action.payload;
      // let isSelected = a0.filter(item=>item.is_selected === true);
      let isSelected = a0.filter((item) => item.is_selected === true);
      let isSelectedIds = isSelected.map((item) => item.ID);
      return {
        ...state,
        loading: false,
        data: action.payload,
        isSelected: isSelectedIds,
        msg: action.payload.msg,
      };
    case PEER_RATIOS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrSortUpdateDataReducer = (
  state = { loading: true, data: [], msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SORT_DATA_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SORT_DATA_UPDATE_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case SORT_DATA_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const vdrData20YearsQuarterlyReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case DATA_20_YEARS_QUARTERLY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DATA_20_YEARS_QUARTERLY_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case DATA_20_YEARS_QUARTERLY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const SegmentReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEGMENT_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case SEGMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const SegmentAssignReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SEGMENT_ASSIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEGMENT_ASSIGN_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case SEGMENT_ASSIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const QuarterlyResultsReducer = (
  state = {
    loading: true,
    l_type: {},
    data: [],
    totalRows: 0,
    msg: null,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case QUARTERLY_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        l_type: action.payload,
        data: {},
      };
    case QUARTERLY_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case QUARTERLY_RESULTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const QuarterlySnapShotReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case QUARTERLY_SNAPSHOT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case QUARTERLY_SNAPSHOT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case QUARTERLY_SNAPSHOT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const SingleCompProfitAndLossReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SINGLE_COMP_PROFIT_LOSS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SINGLE_COMP_PROFIT_LOSS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case SINGLE_COMP_PROFIT_LOSS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const SingleCompCashFlowReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SINGLE_COMP_CASHFLOW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SINGLE_COMP_CASHFLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case SINGLE_COMP_CASHFLOW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const VideoLikeReducer = (
  state = { loading: true, data: [], msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VIDEO_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VIDEO_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case VIDEO_LIKE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const VideoCommentReducer = (
  state = { loading: true, data: [], msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VIDEO_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VIDEO_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case VIDEO_COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const VideoCommentLikeReducer = (
  state = { loading: true, data: [], msg: null, error: null },
  action
) => {
  switch (action.type) {
    case VIDEO_COMMENT_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VIDEO_COMMENT_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case VIDEO_COMMENT_LIKE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const NewsletterLikeReducer = (
  state = { loading: true, data: [], msg: null, error: null },
  action
) => {
  switch (action.type) {
    case NEWSLETTER_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case NEWSLETTER_LIKE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const NewsletterCommentReducer = (
  state = { loading: true, data: [], msg: null, error: null },
  action
) => {
  switch (action.type) {
    case NEWSLETTER_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER_COMMENT_1_LIKE_SUCCESS:
      let aaa0 = state?.data;
      let aaa01 = state?.data.data;
      let resData = action.payload?.data[0];
      let findIndex = aaa01.findIndex(
        (item) => item.commentId === resData.commentId
      );
      aaa01[findIndex] = resData;

      // resData.blog_id
      // aaa1.push(action.payload?.data[0])
      aaa0 = {
        ...aaa0,
        data: aaa01,
      };
      return {
        ...state,
        loading: false,
        data: aaa0,
        msg: action.payload.msg,
      };
    case NEWSLETTER_COMMENT_ADD:
      let aaa = state?.data;
      let aaa1 = state?.data.data;
      aaa1.push(action.payload?.data);
      aaa = {
        ...aaa,
        data: aaa1,
      };
      return {
        ...state,
        loading: false,
        data: aaa,
        msg: action.payload.msg,
      };
    case NEWSLETTER_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case NEWSLETTER_COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const NewsletterCommentLikeReducer = (
  state = { loading: true, data: [], msg: null, error: null },
  action
) => {
  switch (action.type) {
    case NEWSLETTER_COMMENT_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEWSLETTER_COMMENT_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case NEWSLETTER_COMMENT_LIKE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const ComparePeerReducer = createReducer(
  {
    loading: true,
    loadingOnAdd: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    ComparePeerReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    ComparePeerRequest: (state) => {
      state.loading = true;
      state.loadingOnAdd = false;
    },
    ComparePeerSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
    ComparePeerFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
      state.loadingOnAdd = false;
    },
  }
);

export const SingleNewsletterLikeReducer = (
  state = { loading: true, data: [], msg: null, error: null },
  action
) => {
  switch (action.type) {
    case SINGLE_NEWSLETTER_LIKE_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
      };
    case SINGLE_NEWSLETTER_LIKE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case SINGLE_NEWSLETTER_LIKE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const quarterlyReviewReducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case QUARTERLY_REVIEW_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case QUARTERLY_REVIEW_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
      };
    case QUARTERLY_REVIEW_RESET:
      // alert('action')
      return {
        ...state,
        loading: true,
        data: [],
        totalRows: 0,
        msg: null,
      };
    case QUARTERLY_REVIEW_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const quarterlySegmentReducer = (
  state = {
    loading: true,
    data: [],
    totalRows: 0,
    msg: null,
    error: null,
    currentType: null,
  },
  action
) => {
  // console.log('currentType >> ', action)
  switch (action.type) {
    case QUARTERLY_SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case QUARTERLY_SEGMENT_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
        currentType: action?.currentType,
      };
    case QUARTERLY_SEGMENT_RESET:
      // alert('action')
      return {
        ...state,
        loading: true,
        data: [],
        totalRows: 0,
        msg: null,
        currentType: null,
      };
    case QUARTERLY_SEGMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const mediaModelReducer = (
  state = { loading: true, data: { isOpen: false } },
  action
) => {
  switch (action.type) {
    case MEDIA_MODEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MEDIA_MODEL_OPEN:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case MEDIA_MODEL_CLOSE:
      return {
        ...state,
        loading: false,
        data: { isOpen: false },
      };
    default:
      return state;
  }
};

// export const ipAddressReducer = (state = { loading: true, data: [], totalRows: 0, msg: null, error: null }, action) => {
//   return publicIp.v4()
// };

export const RepositoryListReducer = (
  state = { data: [], loading: true },
  action
) => {
  switch (action.type) {
    case REPOSITORY_LIST_RESET:
      return {
        ...state,
        loading: true,
        updateStar: true,
        data: [],
      };
    case REPOSITORY_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        updateStar: true,
      };

    case REPOSITORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        updateStar: true,
        data: action.payload,
      };

    case REPOSITORY_LIST_UPDATE:
      return {
        ...state,
        loading: false,
        updateStar: false,
        // data: action.payload,
      };

    case REPOSITORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        updateStar: true,
        error: action.payload,
      };

    default:
      return state;
  }
};

/**************************************START TRENDLYNE**************************************/

export const ThemeColorReducer = createReducer(
  {
    themeColor: "#0521f5",
    chartColor: "#a367dc",
  },
  {
    themeColor: (state, action) => {
      state.themeColor = action.payload;
    },
    chartColor: (state, action) => {
      state.chartColor = action.payload;
    },
  }
);

export const BarChartDataReducer = createReducer(
  {
    Columns_Rows: {
      Columns: [],
      Rows: {},
    },
    Open: false,
    isPercentage: false,
  },
  {
    Columns_Rows: (state, action) => {
      state.Columns_Rows.Columns = action.payload.columns;
      state.Columns_Rows.Rows = action.payload.rows;
      state.Open = true;
      state.isPercentage = action.payload.isPercentage ? true : false;
    },
    Columns_Rows_close: (state, action) => {
      state.Columns_Rows.Columns = [];
      state.Columns_Rows.Rows = {};
      state.Open = false;
      state.isPercentage = false;
    },
    Open: (state, action) => {
      state.Open = action.payload;
    },
  }
);

export const TrendlyneDataReducer = createReducer(
  {
    AnnualReport: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    EarningsCall: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    InvestorPresentation: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    Announcements: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    EOD_OHLC: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    PB: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    PE: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    EPS: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    Overview: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
    QuarterlyResults: {
      loading: true,
      data: [],
      msg: null,
      error: null,
    },
  },
  {
    // Start AnnualReport
    AnnualReportReset: (state) => {
      state.AnnualReport.loading = true;
      state.AnnualReport.data = null;
      state.AnnualReport.msg = null;
      state.AnnualReport.error = null;
    },
    AnnualReportRequest: (state) => {
      state.AnnualReport.loading = true;
    },
    AnnualReportSuccess: (state, action) => {
      state.AnnualReport.loading = false;
      state.AnnualReport.data = action.payload;
      state.AnnualReport.msg = null;
      state.AnnualReport.error = null;
    },
    AnnualReportFailure: (state, action) => {
      state.AnnualReport.loading = true;
      state.AnnualReport.msg = null;
      state.AnnualReport.error = action.payload;
    },

    // End AnnualReport
    // Start EarningsCall
    EarningsCallReset: (state) => {
      state.EarningsCall.loading = true;
      state.EarningsCall.data = null;
      state.EarningsCall.msg = null;
      state.EarningsCall.error = null;
    },
    EarningsCallRequest: (state) => {
      state.EarningsCall.loading = true;
    },
    EarningsCallSuccess: (state, action) => {
      state.EarningsCall.loading = false;
      state.EarningsCall.data = action.payload;
      state.EarningsCall.msg = null;
      state.EarningsCall.error = null;
    },
    EarningsCallFailure: (state, action) => {
      state.EarningsCall.loading = true;
      state.EarningsCall.msg = null;
      state.EarningsCall.error = action.payload;
    },

    // End EarningsCall

    // Start InvestorPresentation

    InvestorPresentationReset: (state) => {
      state.InvestorPresentation.loading = true;
      state.InvestorPresentation.data = null;
      state.InvestorPresentation.msg = null;
      state.InvestorPresentation.error = null;
    },
    InvestorPresentationRequest: (state) => {
      state.InvestorPresentation.loading = true;
    },
    InvestorPresentationSuccess: (state, action) => {
      state.InvestorPresentation.loading = false;
      state.InvestorPresentation.data = action.payload;
      state.InvestorPresentation.msg = null;
      state.InvestorPresentation.error = null;
    },
    InvestorPresentationFailure: (state, action) => {
      state.InvestorPresentation.loading = true;
      state.InvestorPresentation.msg = null;
      state.InvestorPresentation.error = action.payload;
    },
    // End InvestorPresentation

    // Start Announcements

    AnnouncementsReset: (state) => {
      state.Announcements.loading = true;
      state.Announcements.data = null;
      state.Announcements.msg = null;
      state.Announcements.error = null;
    },
    AnnouncementsRequest: (state) => {
      state.Announcements.loading = true;
    },
    AnnouncementsSuccess: (state, action) => {
      state.Announcements.loading = false;
      state.Announcements.data = action.payload;
      state.Announcements.msg = null;
      state.Announcements.error = null;
    },
    AnnouncementsFailure: (state, action) => {
      state.Announcements.loading = true;
      state.Announcements.msg = null;
      state.Announcements.error = action.payload;
    },
    // End Announcements

    // Start EOD-OHLC
    EOD_OHLCReset: (state) => {
      state.EOD_OHLC.loading = true;
      state.EOD_OHLC.data = null;
      state.EOD_OHLC.msg = null;
      state.EOD_OHLC.error = null;
    },
    EOD_OHLCRRequest: (state) => {
      state.EOD_OHLC.loading = true;
    },
    EOD_OHLCRSuccess: (state, action) => {
      state.EOD_OHLC.loading = false;
      state.EOD_OHLC.data = action.payload;
      state.EOD_OHLC.msg = null;
      state.EOD_OHLC.error = null;
    },
    EOD_OHLCRFailure: (state, action) => {
      state.EOD_OHLC.loading = true;
      state.EOD_OHLC.msg = null;
      state.EOD_OHLC.error = action.payload;
    },
    // End EOD-OHLCR

    // Start EPS
    EPSReset: (state) => {
      state.EPS.loading = true;
      state.EPS.data = null;
      state.EPS.msg = null;
      state.EPS.error = null;
    },
    EPSRequest: (state) => {
      state.EPS.loading = true;
    },
    EPSSuccess: (state, action) => {
      state.EPS.loading = false;
      state.EPS.data = action.payload;
      state.EPS.msg = null;
      state.EPS.error = null;
    },
    EPSFailure: (state, action) => {
      state.EPS.loading = true;
      state.EPS.msg = null;
      state.EPS.error = action.payload;
    },
    // End EPS

    // Start PB
    PBReset: (state) => {
      state.PB.loading = true;
      state.PB.data = null;
      state.PB.msg = null;
      state.PB.error = null;
    },
    PBRequest: (state) => {
      state.PB.loading = true;
    },
    PBSuccess: (state, action) => {
      state.PB.loading = false;
      state.PB.data = action.payload;
      state.PB.msg = null;
      state.PB.error = null;
    },
    PBFailure: (state, action) => {
      state.PB.loading = true;
      state.PB.msg = null;
      state.PB.error = action.payload;
    },
    // End PB

    // Start PE
    PEReset: (state) => {
      state.PE.loading = true;
      state.PE.data = null;
      state.PE.msg = null;
      state.PE.error = null;
    },
    PERequest: (state) => {
      state.PE.loading = true;
    },
    PESuccess: (state, action) => {
      state.PE.loading = false;
      state.PE.data = action.payload;
      state.PE.msg = null;
      state.PE.error = null;
    },
    PEFailure: (state, action) => {
      state.PE.loading = true;
      state.PE.msg = null;
      state.PE.error = action.payload;
    },
    // End PB

    // Start Overview
    OverviewReset: (state) => {
      state.Overview.loading = true;
      state.Overview.data = null;
      state.Overview.msg = null;
      state.Overview.error = null;
    },
    OverviewRequest: (state) => {
      state.Overview.loading = true;
    },
    OverviewSuccess: (state, action) => {
      state.Overview.loading = false;
      state.Overview.data = action.payload;
      state.Overview.msg = null;
      state.Overview.error = null;
    },
    OverviewFailure: (state, action) => {
      state.Overview.loading = true;
      state.Overview.msg = null;
      state.Overview.error = action.payload;
    },

    // End Overview

    // Start QuarterlyResults
    QuarterlyResultsReset: (state) => {
      state.QuarterlyResults.loading = true;
      state.QuarterlyResults.data = null;
      state.QuarterlyResults.msg = null;
      state.QuarterlyResults.error = null;
    },
    QuarterlyResultsRequest: (state) => {
      state.QuarterlyResults.loading = true;
    },
    QuarterlyResultsSuccess: (state, action) => {
      state.QuarterlyResults.loading = false;
      state.QuarterlyResults.data = action.payload;
      state.QuarterlyResults.msg = null;
      state.QuarterlyResults.error = null;
    },
    QuarterlyResultsFailure: (state, action) => {
      state.QuarterlyResults.loading = true;
      state.QuarterlyResults.msg = null;
      state.QuarterlyResults.error = action.payload;
    },

    // End QuarterlyResults
  }
);

/*

export const TrendlyneDataReducer00 = (state = { 
  StockAnnualReports:{
    loading:true,
    data:[],
    msg:null,
    error:null
  },
  StockEarningsCall:{
    loading:true,
    data:[],
    msg:null,
    error:null
  },
  StockInvestorPresentation:{
    loading:true,
    data:[],
    msg:null,
    error:null
  },
 }, action) => {
  // console.log('currentType >> ', action)
  switch (action.type) {
    case QUARTERLY_SEGMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case QUARTERLY_SEGMENT_SUCCESS:
      // alert('action')
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload?.Data.length,
        msg: action.payload.msg,
        currentType: action?.currentType,
      };
      case QUARTERLY_SEGMENT_RESET:
      // alert('action')
      return {
        ...state,
        loading: true,
        data: [],
        totalRows: 0,
        msg: null,
        currentType: null,
      };
    case QUARTERLY_SEGMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
*/

/**************************************END TRENDLYNE**************************************/

export const ResultDocumentNewReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    ResultDocumentNewReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    ResultDocumentNewRequest: (state) => {
      state.loading = true;
    },
    ResultDocumentNewSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    ResultDocumentNewFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const ProfitAndLossChartReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    ProfitAndLossChartReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    ProfitAndLossChartRequest: (state) => {
      state.loading = true;
    },
    ProfitAndLossChartSuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    ProfitAndLossChartFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

export const UserLoginReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
    isAuth: false,
    isRedirect: {},
  },
  {
    UserLoginReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    UserLoginRequest: (state) => {
      state.loading = true;
    },
    UserLoginRedirect: (state, action) => {
      state.isRedirect = action.payload;
    },
    UserLoginSuccess: (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    UserLoginFailure: (state, action) => {
      state.loading = true;
      state.isAuth = false;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);

// START WATCH LIST

export const WatchListReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
    bulk_upload_loading: true,
    bulk_upload_data_error: [],
    bulk_upload_msg: null,
    bulk_upload_error: null,
  },
  {
    watchListReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    watchListRequest: (state) => {
      state.loading = true;
    },
    watchListSuccess: (state, action) => {
      let a0 = current(state);
      let AllArr = {
        $id: "",
        ID: "0",
        UserID: "0",
        WatchListNAme: "Default",
      };
      let data = action.payload;

      if (
        !window.location.href.includes("/corporate-announcement") &&
        !window.location.href.includes("/wishlist/create") &&
        !window.location.href.includes("/manage-company")
      ) {
        let a00 = [AllArr].concat(data.Data);
        data["Data"] = a00;
      }

      state.loading = false;
      state.data = data;
      state.msg = null;
      state.error = null;
    },
    watchListFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },

    watchListBulkUploadRequest: (state) => {
      state.bulk_upload_loading = true;
    },
    watchListBulkUploadSuccess: (state, action) => {
      state.bulk_upload_loading = false;
      state.bulk_upload_data = action.payload;
    },
    watchListBulkUploadFailure: (state, action) => {
      state.bulk_upload_loading = false;
      state.bulk_upload_data_error = action.payload;
      state.bulk_upload_msg = null;
      state.bulk_upload_error = null;
    },
  }
);

export const watchListCompanyReducer = createReducer(
  {
    loading: true,
    data: [],
    msg: null,
    error: null,
  },
  {
    watchListCompanyReset: (state) => {
      state.loading = false;
      state.data = null;
      state.msg = null;
      state.error = null;
    },
    watchListCompanyRequest: (state) => {
      state.loading = true;
    },
    watchListCompanySuccess: (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.msg = null;
      state.error = null;
    },
    watchListCompanyFailure: (state, action) => {
      state.loading = true;
      state.data_error = action.payload;
      state.msg = null;
      state.error = null;
    },
  }
);



// ================ Aakash ======================
export const ForumInsiderReducer = ( state = { loading: true, data: [], totalRows: 0, msg: null, error: null },action) => {
  switch (action.type) {
    case FORUM_LISTINSIGTHS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORUM_LISTINSIGTHS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
        // totalRows: action.payload?.data.length,
      };
    case FORUM_LISTINSIGTHS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};





export const ForumDetail3Reducer = (
  state = { loading: true, data: [], totalRows: 0, msg: null, error: null },
  action
) => {
  switch (action.type) {
    case FORUM3_DETAIL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORUM3_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload.msg,
      };
    case FORUM3_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};







// ================ Aakash ======================


// END WATCH LIST
