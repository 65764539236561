
import { Box, Grid, Button, Typography, Menu, MenuItem, Switch, TextField, Checkbox } from '@mui/material';
import React, { useState, useEffect, useRef, createRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CalendarTableComponent, LoadingComponent, ValuationTableComponent } from '../components';
import { defaultValuationArr } from '../constants/defaultArray';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';



// import { DownloadTableExcel } from 'react-export-table-to-excel';
import {
  companyMasterAction,
  industryMasterAction,
  sectorMasterAction,
  valuationDataAction,
  calenderAction,
  turnAroundMasterAction,
  valuationFilters,
  valuationFilterInputAction
} from '../redux/actions/userAction';
import { InputComponent, SelectCompanyIndustrySector } from './../components/Common/index';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Moment from "moment";
import { checkUser } from '../constants/helpers';
import { dateAPIAction, vdrAssignEmployeeAction } from '../redux/actions/VdrAction';



const CalendarPage = (props) => {
  const tableRef = React.createRef();
  const { dataFor } = props;


  const dispatch = useDispatch();
  const { data, loading } = useSelector((state) => state.calenderData);

  const { data: companyMaster, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
  const { data: sectorMaster, loading: sectorMasterLoading } = useSelector((state) => state.sectorMasterData);
  const { data: industryMaster, loading: industryMasterLoading } = useSelector((state) => state.industryMasterData);
  
  const { data: AssignEmployee, loading: AssignEmployeeLoading, loadingOnAdd } = useSelector((state) => state.AssignEmployeeReducer);
  

  const [tableColumns, setTableColumns] = useState(null);
  const [tableBodyData, setTableBodyData] = useState(null);


  const [FilterData, setFilterData] = React.useState(null)
  const [sectors, setSectors] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [sectorMasterArr, setSectorMasterArr] = useState([]);
  const [filterArr, setFilterArr] = useState([]);
  const [industryMasterArr, setIndustryMasterArr] = useState([]);
  const [sectorsKey, setSectorsKey] = useState(false);
  const [industryKey, setIndustryKey] = useState(false);
  const [resetButton, setResetButton] = useState(false);
  const [sectorsIds, setSectorsIds] = useState([]);
  const [assignedData, setAssignedData] = useState([]);


  // const navigation = useNavigate();
  useEffect(() => {
        if(window.location.pathname.split('/')[1] == "calendar" || window.location.pathname.split('/')[1] == "price"){
          // if (!localStorage.getItem('user')) {
          //     window.location.href = 'https://omkaradata.com/data2/navigation';
          // }
        }
    }, [])



  const { data: dataAPIData, loading: dataAPILoading } = useSelector((state) => state.dateAPIReducer);
  const [DateData, setDateData] = React.useState(null)
  React.useEffect(() => {

    if (dataAPILoading) {
      dispatch(dateAPIAction());
    }
    if (!dataAPILoading) {
      let a1 = dataAPIData?.Data?.[0]?.result_calendar;
      setDateData(a1)
    }
  }, [dispatch, dataAPILoading])



  const { control, handleSubmit, register, reset } = useForm({
    defaultValues: {
      From_Date: Moment().format('MM/DD/YYYY'),
      To_Date: Moment().add(1, 'week').format('MM/DD/YYYY'),
      From_MCap: "",
      To_MCap: "",
      Sectors: [],
      Industry: [],
    }
  });

  React.useEffect(() => {
    if (AssignEmployeeLoading) {
      dispatch(vdrAssignEmployeeAction({ optionType: "2" }, 'list'));
    }
    if (!AssignEmployeeLoading) {
      var arrData = AssignEmployee.data.map((item) => item.company_id)
      // console.log('arrData >>>', arrData)
      setAssignedData(arrData);
    }
  }, [dispatch, AssignEmployeeLoading])

  useEffect(() => {
    if (sectorMasterLoading) {
      dispatch(sectorMasterAction());
    }
    if (industryMasterLoading) {
      dispatch(industryMasterAction());
    }
    if (companyMasterLoading) {
      dispatch(companyMasterAction());
    }
    if (loading) {
      const defaultValuationArr1 = [{ "UserId": 1, "FromDate": Moment().format('MM/DD/YYYY'), "ToDate": Moment().add(1, 'week').format('MM/DD/YYYY'), "Sector": [], "Industry": [], "Market_Cap": ["", ""] }];
      dispatch(calenderAction(defaultValuationArr1));
    }
  }, []) 

  // const assignedData = [];
  // const assignedData = AssignEmployee?.data.map((resAssign) => resAssign.company_id);
// console.table(assignedData);




const [checked, setChecked] = React.useState(false);
const handleChange = (event) => {
  setChecked(event.target.checked);
};


const [ChangeChecked, setChangeChecked] = React.useState(false);
const handleChangeChecked = (event) => {
  
  setChangeChecked(event.target.checked);
};



  useEffect(() => {

    if (!loading) {
      let a1 = 0;
      const tableHead = data.Headers;
      var mColArr = [];
      tableHead.map((resHeads) => {

        var hideCheck = false;
        var sticky = null;
        if (a1 !== 1) {
          var hideCheck = true;
        }

        Object.keys(resHeads).forEach(key => {

          var label = resHeads[key];
          var width = 100;
          if (key == 'CompanyName' || key == 'Sector' || key == 'Industry') {
            var width = 200;
          }

          if (key != '$id') {
            var mCol = {
              id: 'CompanyName' + a1,
              label: `${label} (${Moment(label).format('dddd')})`,
              dates: label,
              minWidth: 190,
              maxWidth: 190,
              align: 'canter',
              hideCheck: hideCheck,
              sticky: sticky,
            }
            mColArr.push(mCol);
            a1++;
          }
        })
      });

      setTableColumns(mColArr);

      const tableBody = data.Data;
      var allRowsData = [];
      var allRowsData1 = [];
      // console.warn(mColArr);

      tableBody && tableBody.length > 0 && tableBody.map((resBody) => {

        var company = {
          companyLength: resBody.Company.length,
          companies: resBody.Company,
        };
        allRowsData1.push(company);
      });


      var firstData0000 = allRowsData1.sort((a, b) => b.companyLength - a.companyLength);
      // console.warn(firstData0000);

      var firstData = allRowsData1 && allRowsData1.length > 0 && allRowsData1[0].companyLength;

      var rowsData = [];
      for (let i0 = 0; i0 < firstData; i0++) {

        var obj1 = {};

        let resDataArr11 = tableBody.map((resBody, i) => {
          var columnName = 'CompanyName' + i;
          var CompanyId = 'CompanyData' + i;
          var columnValue = resBody.Company[i0];
          if (typeof columnValue != 'undefined') {
            columnValue = columnValue.split('__');
            // console.log('columnValue >> '+columnValue[0] + '<< i >> '+i)
            obj1[columnName] = (columnValue[0] || "");
            obj1[CompanyId] = { 'Company_Name': columnValue[0], 'CompanyID': columnValue[1] };
            var assignedVar = false;
            var uData = [];
            if(assignedData.includes(columnValue[1])){
              assignedVar = true;
              uData = AssignEmployee.data.filter((resAssign) => resAssign.company_id == columnValue[1]);
            }
            obj1[CompanyId]['assigned'] = assignedVar;
            obj1[CompanyId]['usersData'] = uData;

          }

          if ((i + 1) == tableBody.length) {
            rowsData.push(obj1);
          }
        });

      }
      var newRowData = [];
      
      var ddd = rowsData.map((item) => {
        if(tableColumns && tableColumns.length > 0){
          for (let a0 = 0; a0 < tableColumns.length; a0++) {
            var tableColumn = tableColumns[a0];
              var a00 = 'CompanyData' + a0;

               const sItem = item[a00];
              if(sItem){
                if(sItem?.assigned === true){
                  newRowData.push(item);
                  console.log('newRowData >> ', item)
                  return ;
                  // return item;
                }
              }
          }
        }
        // tableColumns && tableColumns.map((column, a0) => {
        //   var a00 = 'CompanyData' + a0;
        //   var a1 = false;
        //   const sItem = item[a00];
        //   if(sItem){
        //     if(sItem?.assigned === true){
        //       newRowData.push(item);
        //       console.log('newRowData >> ', item)
        //       return ;
        //       // return item;
        //     }
        //   }
        //   return
        // })

      });


      console.warn(newRowData); 
      if(checked){
        setTableBodyData(newRowData);
      }else{
        setTableBodyData(rowsData);
      }

    }
  }, [dispatch, loading, checked])


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ExportData = () => {

    // const wb = XLSX.utils.book_new();
    // let sh = XLSX.utils.table_to_sheet(document.getElementById('table-to-xls'));
    // XLSX.utils.book_append_sheet(wb, sh, "Calender");
    // //      const sheets = wb.Sheets['Calender'];
    // //     // const sheet1 = sh.SheetNames[0];

    // //     console.log('sheet1 >>> '+JSON.stringify(sheets));
    // //     console.log('sheet1 >>> '+Object.keys(sheets).length);

    // //     var i;
    // // for (i = 1; i <= (obj = Object.keys(sheets).length); i++) {
    // //     console.log('wb.Sheets["Calender"]["A"+i].s ?>>>'+wb.Sheets["Calender"]["A"+i].s)
    // //     wb.Sheets["Calender"]["A"+i].s = {
    // //         fill: {
    // //             patternType: "solid",
    // //             fgColor: { rgb: "111111" }
    // //         }
    // //     };
    // // }


    // XLSX.writeFile(wb, "Calender_Data.xlsx");

  }


  React.useEffect(() => {
    checkUser();

  }, [])


  const [inputs, setInputs] = React.useState({});


  const export2Option = () => {
    return (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', 'marginLeft': 1 }}>
          <Typography sx={{ fontSize: '0.8rem', alignItems: 'center', display: 'inline-flex', cursor: 'pointer', }}
            onClick={handleClick} >Export As <KeyboardArrowDownIcon fontSize="small" /> </Typography>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                margin: '0 auto',
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

          <MenuItem style={{ fontSize: '0.8rem' }} onClick={() => exportComponentAsJPEG(tableRef)}>
            Export JPG
          </MenuItem>

          <MenuItem style={{ fontSize: '0.8rem' }} >

            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="table-to-xls"
              filename="Export_Calendar"
              sheet="Calendar"
              buttonText="Export Excel"
            />


            {/* <DownloadTableExcel
              filename="users table"
              sheet="users"
              currentTableRef={tableRef.current}
            >

            Excel
            </DownloadTableExcel> */}
          </MenuItem>
        </Menu>
      </>
    )
  };

  const requestSearch = (searchedVal) => {
    const filteredRows = tableBodyData.filter((row) => {
      return Object.keys(row).some((key) => {
        return String(row[key]).toLowerCase().includes(searchedVal.toLowerCase());
      });
    });
    if (searchedVal.length < 1) {
      setFilterData(tableBodyData)
    }
    else {
      setFilterData(filteredRows)
    }
  };


  const applyFilter = (data) => {
    // console.log('data >>> ', sectors.map((item)=>item.value));
    var sectorValue = sectors.map((item) => item.value);
    var industryValue = industry.map((item) => item.value);

    const newFilterArray = [{ "UserId": 22, "FromDate": Moment(data?.From_Date).format('MM/DD/YYYY'), "ToDate": Moment(data?.To_Date).format('MM/DD/YYYY'), "Sector": (sectorValue || []), "Industry": (industryValue || []), "Market_Cap": [(data?.From_MCap || ''), (data?.To_MCap || '')], "Portfolio":ChangeChecked }];

    const topLabels =
    {
      "portfolio": {
        "label": "Portfolio",
        "value1": (ChangeChecked || ""),
        "value2": ""
      },
      "sectors": {
        "label": "Sector",
        "value1": (data?.Sectors || ""),
        "value2": ""
      },
      "industry": {
        "label": "Industry",
        "value1": (data?.Industry || ""),
        "value2": ""
      },
      "date_range": {
        "label": "Date Range",
        "value1": (data?.FromDate ? Moment(data?.From_Date).format('MM/DD/YYYY') : null),
        "value2": (data?.ToDate ? Moment(data?.To_Date).format('MM/DD/YYYY') : null)
      },
      "Market_Cap": {
        "label": "Market Cap",
        "value1": (data?.From_MCap || ''),
        "value2": (data?.To_MCap || '')
      },
    };

    setFilterArr(topLabels);
    dispatch(calenderAction(newFilterArray));
    setResetButton(true);

  }



  const refreshPage = () => {
    window.location.reload(false);
  }


  return (
    <>

      <Box  sx={{
        marginTop: dataFor === 'frontend' ? '6rem' : '1rem',
        padding: '0 2rem',
      }}>
        <Grid container spacing={2} alignItems="center" className='calendarFilters assignPageheader'>
          <Grid xs={12} sm={12} my={1}>
          <Box>
            {
              DateData
                ?
                <Typography sx={{ paddingBottom:'0', margin:0, textAlign:'end' }} variant='subtitle2' fontSize={12} textAlign="center">Data Updated <b>{DateData}</b></Typography>
                :
                null
            }
          </Box>
          </Grid>
              {/* <Grid xs={12} sm={6} my={1}>
              <Typography  className='fontSize-07rem'>Search</Typography>  
                <TextField placeholder='Search' className='search-input calendar-search' sx={{ padding: '0.3rem 0.7rem' }} onChange={(e) => requestSearch(e.target.value)} />
              </Grid> */}
          <Grid xs={12} sm={12} md={10} my={1} className='newData'>
            <Grid  container spacing={2}>
              <Grid xs={12} sm={2} my={1}>
                <div style={{ width:'100%' }}>
                  <Typography  className='fontSize-07rem'>Company Search</Typography>  
                  <TextField placeholder='Search' className='search-input calendar-search' sx={{ padding: '0.3rem 0.7rem', width:'100%' }} inputProps={{style:{
                    height:'21px'
                  }}} onChange={(e) => requestSearch(e.target.value)} />  
                </div>
              </Grid>
              <Grid xs={12} sm={4} my={1}>
                <SelectCompanyIndustrySector
                  size="small"
                  sectors={sectors} setSectors={setSectors}
                  industry={industry} setIndustry={setIndustry}
                  sectorMasterArr={sectorMasterArr} setSectorMasterArr={setSectorMasterArr}
                  filterArr={filterArr} setFilterArr={setFilterArr}
                  industryMasterArr={industryMasterArr} setIndustryMasterArr={setIndustryMasterArr}
                  sectorsKey={sectorsKey} setSectorsKey={setSectorsKey}
                  industryKey={industryKey} setIndustryKey={setIndustryKey}
                  resetButton={resetButton} setResetButton={setResetButton}
                  sectorsIds={sectorsIds} setSectorsIds={setSectorsIds}
                  companyMasterLoading={companyMasterLoading}
                  sectorMasterLoading={sectorMasterLoading}
                  industryMasterLoading={industryMasterLoading}
                  companyMaster={companyMaster}
                  sectorMaster={sectorMaster}
                  industryMaster={industryMaster}

                />
              </Grid>

              <Grid  >

                <Box sx={{
                  display: 'flex',
                 gap: "4px",
                 width: "fit-content",
                }}>

                  <div style={{ margin: '0 0.2rem' }}>
                    <Typography className='fontSize-07rem'>From</Typography>
                    <InputComponent
                      name={'From_Date'}
                      control={control}
                      size={'small'}
                      placeholder={'From Date'}
                      type="date"
                    />
                  </div>
                  <div style={{ margin: '0 0.2rem' }}>
                    <Typography className='fontSize-07rem'>To</Typography>
                    <InputComponent
                      name={'To_Date'}
                      control={control}
                      size={'small'}
                      placeholder={'To Date'}
                      type="date"
                    />
                  </div>
                </Box>
 
                
              </Grid>


              <Grid xs={12} sm={2} my={1}>
              <Box sx={{
                  marginX: 1
                }}>
                  <div style={{ margin: '0 0.2rem' }}>
                    <Typography className='fontSize-07rem'>Market Cap</Typography>
                    <div style={{ display: 'flex' }}>
                      <InputComponent
                        name={'From_MCap'}
                        control={control}
                        size={'small'}
                        placeholder={'>1'}
                        style={{ marginRight: '0.5rem', width: '50%' }}
                      />
                      <InputComponent
                        name={'To_MCap'}
                        control={control}
                        size={'small'}
                        placeholder={'<100'}
                        style={{ width: '50%' }}
                      />
                    </div>
                  </div>
                </Box>
              </Grid>
              
              <Grid xs={12} sm={2} my={1}>
                <div style={{ width:'100%' }}>
                      <Box sx={{ marginTop: '24px',marginLeft: '-10px' }}>
                        <Checkbox
                          className={'checkBoxContainer'}
                          checked={ChangeChecked}
                          onChange={handleChangeChecked}
                          name="Portfolio"
                          inputProps={{ 'aria-label': 'Portfolio' }}
                          size="small"
                          sx={{ marginRight: '0',paddingRight:'2px' }}
                        />
                        <label>Portfolio</label>
                      </Box>
                </div>
              </Grid>

            </Grid>
          </Grid>
          
          <Grid xs={12} sm={12} md={2} my={1}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              flexDirection: 'row'
            }}
          >
          {
            dataFor !== 'frontend' ?
            <>
              <Switch
              title={checked ? 'Show All' : 'Show Highlighted'}
                checked={checked}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </>
            :
            null
          }
            {
              resetButton ?
                <Button className='btn-cst-primary' sx={{ height: 40 }} size='small' onClick={() => refreshPage()} variant="contained">Reset</Button>
                :
                <>
                  <Button className='btn-cst-primary' sx={{ height: 40 }} size='small' onClick={handleSubmit(applyFilter)} variant="contained">Submit</Button>
                </>

            }
            {
              export2Option()
            }
          </Grid>
        </Grid>
      </Box>

      <Box sx={{
        padding: '0 1rem',
        marginBottom: 3
      }}
      
      ref={tableRef}
      >
        <CalendarTableComponent
          FilterData={FilterData}
          setFilterData={setFilterData}
          dataFor={dataFor}
          loading={loading}
          tableColumns={tableColumns}
          resultData={tableBodyData}
          assignedMembers={checked}

        />
      </Box>
    </>
  )
}

export default CalendarPage