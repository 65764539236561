import React, { Fragment, useContext, useEffect, useState } from 'react'
import { GlobalContext } from '../../context/GlobalContext';
import { useDispatch, useSelector } from 'react-redux';
import { BoardOfDirectorDetailAction } from '../../redux/actions/VdrAction';
import { Box, Divider, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';






const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  
export  function ForensicSectionModal({
    handleOpen,
    companyId,
    typeData,
    open, setOpen,
    handleClose
  }) {
    
  
  
    const {
      SelectedDIR,
      setSelectedDIR,
    } = useContext(GlobalContext)
  
  
    const dispatch = useDispatch()
    const { data, loading } = useSelector(state=>state.BoardOfDirectorDetailReducer)
    const [ResData, setResData] =  useState(null)
  
    useEffect(() => {
        if(loading){
          let params = {
                  "Type": typeData, 
                  "DirName": (SelectedDIR?.column_2),
                  "companyId": companyId
              }
          dispatch(BoardOfDirectorDetailAction(params))
        }
        if(!loading){
          console.log('data >>>> ', data)
        }
    }, [loading, SelectedDIR])
    
    return (
      <div>
        
        <Modal
          keepMounted
          open={open}
          onClose={()=>{ 
            setSelectedDIR(null)
            handleClose();
         }}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box sx={style}>
            
            <Typography id="keep-mounted-modal-title" variant="h6" component="h2" sx={{ fontSize: '18px' }}>
              {SelectedDIR?.column_2}
            </Typography>
            <Divider sx={{ my: 1 }}/>
            <Box style={{ maxHeight: '70vh', overflow: 'auto' }}>
              {
                data?.Data && data?.Data.length == 0 && (
                  <Typography variant='h6' sx={{ margin: '10px 0', fontSize: '15px' }}  >
                    N/A
                  </Typography>
                )
              }
                <TableContainer className='forensic-table'>
                  <Table>
                    <TableHead>
                        
                      <TableRow>
                        {
                              data?.header && data?.header.length > 0 && Object.keys(data?.header[0]).map((item, i_0)=>{
                                  if(i_0 > 0){
                                      console.log(item)
                                      return (
                                          <TableCell style={{ fontSize:'12px', backgroundColor:'#ffffff !important' }} key={i_0} >{data?.header[0]?.[item]}</TableCell>
                                      ) 
                                  }
                              })
                        }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data?.Data && data?.Data.length > 0 && (data.Data).map((item_data, d_0)=>{
                                return (
                                    <TableRow key={d_0}>
                                        {
                                            data?.header && data?.header.length > 0 && Object.keys(data?.header[0]).map((item, i_0)=>{
                                                if(i_0 > 0){
                                                    // console.log(item_data?.[item])
                                                    return (
                                                        <TableCell  style={{ fontSize:'12px', backgroundColor:'#ffffff !important' }} key={i_0}>
                                                            {item_data?.[item]}
                                                        </TableCell>
                                                    ) 
                                                }
                                            })
                                        }
                                        
                                        {/* <TableCell key={i_0} style={{ backgroundColor:'#ffffff !important' }}>
                                        </TableCell> */}
                                    </TableRow>
                                )
                            })
                        }
                      <TableRow>
                      

                        {/* <TableCell style={{ backgroundColor:'#ffffff !important' }}>Cell 1</TableCell>
                        <TableCell style={{ backgroundColor:'#ffffff !important' }}>Cell 2</TableCell> */}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
            </Box>
            {/* <Typography id="keep-mounted-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography> */}
          </Box>
        </Modal>
      </div>
    );
  }
  
  


// const ForensicSectionModal = () => {
//   return (
//     <>
      
//     </>
//   )
// }

// export default ForensicSectionModal
