import axios from "axios";
import moment from "moment";
import publicIp from 'public-ip';
import { useSelector } from "react-redux";

import $ from 'jquery';
import Cookies from 'js-cookie'




export const shortAsc = (data1) => {
    data1.sort((a, b) => {
        var a1 = a.title.toLowerCase();
        var b1 = b.title.toLowerCase();

        if (a1 > b1) {
            return 1
        } else
        if (a1 < b1) {
            return -1
        } else {
            return 0
        }
    })
    return data1;
}


export const stripTags = (original) => {
    var text = original?.replace(/(<([^>]+)>)/gi, "");
    return text?.replace("&amp;", "&");
}


export const wordLimit = (content = '', limit = '') => {
    // var str = "splits a string into an array of substrings, and returns the array.";
    let contentArray = content.split(' ');
    contentArray = contentArray.slice(0, limit);
    if (contentArray != '') {
        contentArray = contentArray.join(' ').concat('...');
    }
    return contentArray;

}




export const mediaInput = {
    "userid": 1,
    "videoCode": "",
    "videoId": "",
    "videoType": "",
    "videoTitle": "",
    "videoDescription": "",
    "DocumentType": "Common",
    "isPinned" : "",
    "groupId":"",
    "group_name": "",
    "isFeatured": true,
    "item_order":"1",
    "fromDate": moment().subtract(1, 'year').format('YYYY-MM-DD'),
    "toDate": moment().format('YYYY-MM-DD'),
    "inputType": 2
  }


export const dateFormat = (str, format) => {
    return moment(str, 'DD-MM-YYYY HH:mm:ss', true).format('DD-MMM-YYYY HH:mm:ss');
}


export const loggedInAdmin = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};

export const loggedInUser = localStorage.getItem('users_leggin') ? JSON.parse(localStorage.getItem('users_leggin')) : {};

export const showCalendarActionBtn = () => {
    var usersArr = ['1', "3", "17", "4"];
    if(usersArr.includes(loggedInAdmin?.UserID)){
        return true
    } else {
        return false
    };
}


// export const watermarkText = `Vineet Kumar ${localStorage.getItem('ipData') ? JSON.parse(localStorage.getItem('ipData'))?.IPv4 : null}`;
const uName = `${localStorage.getItem('users_leggin') ? JSON.parse(localStorage.getItem('users_leggin'))?.email : 'vineet@omkaracapital.in'}`;
export const watermarkText = uName;
// export const watermarkText = `${uName} ${localStorage.getItem('ipData') ? JSON.parse(localStorage.getItem('ipData'))?.IPv4 : null}`;
// export const watermarkText = `UserName ${JSON.parse(localStorage.getItem('ipData'))?.IPv4}`;


export const convertToSlug = (Text) => {
    return Text.toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
}

export const encodeBtoa = (str) => {
    return window.btoa(str)
}

export const decodeAtob = (str) => {
    
    if(str){
        return window.atob(str)
    }
    return str
}

export const rangeArray = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
}


export const DataRoomDocumentType = [
    { title: 'None', value: '0' },
    { title: 'Annual Reports', value: 'Annual Reports' },
    { title: 'Concall Transcripts', value: 'Concall Transcripts' },
    // { title: 'Forensic', value: 'Forensic' },
    { title: 'Initial Coverage', value: 'Initial Coverage' },
    { title: 'Investor Presentation', value: 'Investor Presentation' },
    // { title: 'Brief Notes', value: 'Brief Notes' },
    { title: 'Others', value: 'Others' },
    { title: 'Quarterly Update', value: 'Quarterly Update' },
];

// export const DocumentNotes = [
//     { title: 'None', value: '0' },
//     { title: 'Quarter Notes', value: 'Initial Coverage' },
//     { title: 'Mgt Meet', value: 'Other Updates' },
//     { title: 'Others', value: 'Others' },
//     // { title: 'None', value: '0' },
//     // { title: 'Initial Coverage', value: 'Initial Coverage' },
//     // { title: 'Other Updates', value: 'Other Updates' },
//     // { title: 'Others', value: 'Others' },
// ];

export const DocumentNotes = [
    { title: 'None', value: '0' },
    { title: 'Concall Summary', value: 'Concall Summary' },
    { title: 'Important Source', value: 'Important Source' },
    { title: 'Management Meeting', value: 'Management Meeting' },
    { title: 'One Pager', value: 'One Pager' },
    { title: 'Quarter Update', value: 'Quarter Update' },
    { title: 'Rough', value: 'Rough' },
];

export const DocumentType = [
    { title: 'None', value: '0' },
    { title: 'Research Report', value: 'Research Report' },
    { title: 'Sector Report', value: 'Sector Report' },
    { title: 'Quarterly Updates', value: 'Quarterly Updates' },
    { title: 'Running Notes (WIP)', value: 'Running Notes (WIP)' },
    { title: 'Stock Exchange Announcements', value: 'Stock Exchange Announcements' },
];

export const ideaType = [
    { title: 'None', value: '0' },
    { title: 'Tactical', value: '1' },
    { title: 'Long Term', value: '2' },
    { title: 'Profit Booking', value: '3' },
    // { title: 'Under Study', value: '3' },
];


export const recommendationArr = [
    { title: 'None', value: '0', color: '' },
    { title: 'Buy', value: '1', color: 'Green' },
    { title: 'Sell', value: '2', color: 'Red' },
    { title: 'Hold', value: '3', color: 'orange' },
    { title: 'Under Study', value: '4', color: '#004e8f' },
    { title: 'Under Review', value: '5', color: '#f37b21' },
    { title: 'Not Rated', value: '6', color: '#004e8f' },
];




export const recommendationType = (values) => {
    return recommendationArr[values];
};



export const ideaTypeTitle = (values) => {
    return ideaType[values].title;
};


const getSingleGroupData = (data, group_id) => {
    data.find(obj => {
        return obj.group_id === group_id
    })
}




let LIVE_SITE_URL = 'https://omkaracapital.in';
    // LIVE_SITE_URL = 'https://omkaracapital.net';
// export const checkUser = async () => {
export const checkUser = () => async (dispatch) => {
    console.log('login wait')
    await axios.get(`${LIVE_SITE_URL}/sanctum/csrf-cookie`).then(response => {
        var token = Cookies.get('auth_token');
        // var token = Cookies.get('auth_token') || "22885|QroHb80P1N1utzc0TKlceqHZiu8ip2Eu4orNA4X9";
        // var token = "22415|TvKr5QEurRBXXc06XPrKaQWUgg21ml4lsHHIGpQB";
        // console.log(token);
        axios.get(`${LIVE_SITE_URL}/api/profile`, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then(res => {
            
            if (res.data.type === 'Subscription_User') {
                localStorage.setItem('users_leggin', JSON.stringify(res.data));
                Cookies.set('user_leggin', JSON.stringify(res.data), { expires: 1 });
                console.log('a__a00 >> 1')
                dispatch({
                    type:'UserLoginSuccess',
                    payload: res.data
                })
                dispatch({
                    type:'UserLoginRedirect',
                    payload: {isLogin: false, isFreeBLog: true}
                })
            } else 
            if( window.location.host === 'vdr.omkaracapital.net'){
                dispatch({
                    type:'UserLoginRedirect',
                    payload: {isLogin: true, isFreeBLog: false}
                })
                console.log('a__a00 >> 4')
                // window.location.href = `${LIVE_SITE_URL}/user/login`
            }else
            // if (res.data.type === 'Free_User') {
            //     console.log('a__a00 >> 2')
            //     localStorage.setItem('users_leggin', JSON.stringify(res.data));
            //     Cookies.set('user_leggin', JSON.stringify(res.data), { expires: 1 });
            //     console.log(JSON.stringify(res.data));
            //     dispatch({
            //         type:'UserLoginSuccess',
            //         payload: res.data
            //     })
            //     if( window.location.host === 'omkaracapital.in'){
            //         console.log('a__a00 >> 3')
            //         // let navigate = useNavigate();
            //         // navigate('/managment0')

            //         dispatch({
            //             type:'UserLoginRedirect',
            //             payload: {isLogin: false, isFreeBLog: true}
            //         })

            //     }
                
            // } else
            {
                if( window.location.host === 'vdr.omkaracapital.net'){
                    dispatch({
                        type:'UserLoginRedirect',
                        payload: {isLogin: true, isFreeBLog: false}
                    })
                    console.log('a__a00 >> 4')
                    // window.location.href = `${LIVE_SITE_URL}/user/login`
                }
            }
        }).catch(err => {
            console.log('a__a00 >> 12')
            console.log('a__a00 >> 12 >>> err', err)
            console.log('a__a00 >> 12 >>> err.message', err.message)
            if( window.location.host === 'vdr.omkaracapital.net'){
                console.log('a__a00 >> 5')
                dispatch({
                    type:'UserLoginRedirect',
                    payload: {isLogin: true, isFreeBLog: false}
                })
                // window.location.href = `${LIVE_SITE_URL}/user/login?next=${window.location.href}`;
            }else
            if( window.location.host === 'omkaracapital.in'){
                console.log('a__a00 >> 6')
                dispatch({
                    type:'UserLoginRedirect',
                    payload: {isLogin: true, isFreeBLog: false}
                })
                // window.location.href = `${LIVE_SITE_URL}/user/login?next=${window.location.href}`;
            }else
            if( window.location.host === 'omkaracapital.net' ){
                console.log('a__a00 >> 7')
                dispatch({
                    type:'UserLoginRedirect',
                    payload: {isLogin: true, isFreeBLog: false}
                })
                // window.location.href = `${LIVE_SITE_URL}/user/login?next=${window.location.href}`;
            }
            
            dispatch({
                type:'UserLoginFailure',
                payload: 'localhost'
            })
            
        });
    }).catch(err => {
        console.log('a__a00 >> 8')
        if( window.location.host === 'vdr.omkaracapital.net'){
            console.log('a__a00 >> 9')
            // window.location.href = `${LIVE_SITE_URL}/user/login?next=${window.location.href}`;
            dispatch({
                type:'UserLoginRedirect',
                payload: {isLogin: true, isFreeBLog: false}
            })
        }else
        if( window.location.host === 'omkaracapital.in'){
            console.log('a__a00 >> 10')
            // window.location.href = `${LIVE_SITE_URL}/user/login?next=${window.location.href}`;
            dispatch({
                type:'UserLoginRedirect',
                payload: {isLogin: true, isFreeBLog: false}
            })
        }else
        if( window.location.host === 'omkaracapital.net' ){
            console.log('a__a00 >> 11')
            dispatch({
                type:'UserLoginRedirect',
                payload: {isLogin: true, isFreeBLog: false}
            })
        }
        dispatch({
            type:'UserLoginFailure',
            payload: 'localhost'
        })
        console.log('err 0000000000000000000000000>>>> ',err)
        // window.location.href = `${LIVE_SITE_URL}/user/login?next=${window.location.href}`;
    });
}


export const getFirstChar = (str) => {
    // return "str";
    console.log("str", str);
    if(str){
        return str.charAt(0).toLocaleUpperCase();
    }
}
// vimeo_parser
// youtube_parser

export const vimeo_parser = (url) => {
    // var url = "http://www.vimeo.com/7058755"; //Or any other Vimeo url format
    var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    let unlisted_code = "";
    var match = url.match(regExp);
    var ddArr = url.split('/');
    if(ddArr.length == 5){
        unlisted_code = '/'+ddArr[ddArr.length-1]

    }
    // console.table(match);
    if (match) {
        return match[5]+unlisted_code;
    } else {
        // alert("not a vimeo url");
        return null;
    }
}

export const youtube_parser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match && match[7].length == 11) ? match[7] : null;
}




export const getForensicUpdateTitle = (title="", type="") => {

    let CTitle = '';
    let n_title = '';
    let c_type = '';

    if(type == 'DH'){
        CTitle = 'Dividend History';
    }
    if(type == 'AH'){
        CTitle = 'Auditor History';
    }
    if(type == 'SH'){
        CTitle = 'Shareholding Pattern';
    }
    if(type == 'ESOP'){
        CTitle = 'ESOP';
    }
    if(type == 'CH'){
        CTitle = 'Capital History';
    }

    if(type == 'ratios'){
        CTitle = 'Ratios';
    }

    if(type == 'CF'){
        CTitle = 'Capital Allocation';
    }

    if(title && type != 'SH'){
        n_title = title;
        c_type = n_title;
    }else{
        n_title = CTitle;
        c_type = type;
    }


    // if(type == 'CF'){
    //     c_type = 'Capital Allocation';
    // }
    let res = {
        title:n_title,
        type:c_type,
    }
    console.log('res <><><<>>>>   res > ', res)
    return res

}



// export const openSingleCompany = async (rowData, tableInstance1, CompanyName, type = '_blank') => {
//     localStorage.setItem('companyId', rowData.CompanyID);
//     localStorage.setItem('companyName', CompanyName);
//     localStorage.setItem('openedCompany', rowData.CompanyID);
//     // tableInstance1.current.click();
//     return openInNewTab(`${domainUrl}/${linkBaseUrlData}/single-company.html`, type);
//   }

  export const getBaseLink = () => {
    let baseLink = "/vdr";  
    // baseLink = "/free-blog";  // data2
    // baseLink = "/company-detail"; // data2
    // baseLink = "/company-detail-aceapi"; // data2
    baseLink = "/"; // data2
    // baseLink = "/single-company";
    // baseLink = "/price";
    // baseLink = "/calendar";
    // if(document.location.hostname === 'omkaracapital.in'){
    //     baseLink = "/vdr";
    // }
    // if(document.location.hostname === 'omkaracapital.com'){
    //     baseLink = "/vdr";
    // }
    return baseLink;
  }

export const get_vimeo_thumbnail = (url, imgId, size = null, callback = null) =>{

    axios.get(`https://vasudeep.com:8084/https://player.vimeo.com/video/${url[0]}/config?h=${url[1]}`)
        .then(function (response) {
            var data = response.data;
            $(`#${imgId}`).attr('src', data.video.thumbs.base)
            $(`#title${url[0]}`).text(data.video?.title)
        })
        .catch(function (error) {
            // console.log(error);
        });
  }
  

  export const crtCapitalUser = localStorage.getItem('users_leggin') ? JSON.parse(localStorage.getItem('users_leggin')) : (localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null);
  
  export const crtUserId = () => {
        // console.log('crtCapitalUser >>>>> ', crtCapitalUser)
        // let userId = crtCapitalUser?.UserID || crtCapitalUser?.user_id || 1037;
        let userId = crtCapitalUser?.UserID || crtCapitalUser?.user_id || crtCapitalUser?.userInfo?.[0].UserID || 22;
        return userId;
  };


  const crtUserName = crtCapitalUser?.name || crtCapitalUser?.UserName || crtCapitalUser?.UserNAme || "Wrong";

  
export const commentParams = {
    "parentId":0,
    "videoId":"",
    "comment":"",
    "commentId":"",
    "webUserName": crtUserName,
    "webUserImage":"https://omkaracapital.in/image/default_user.png",
    "webuserId":crtUserId(),
    "inputType":"3" //0:insert 1:edit 2:Delete
}



export const IsDateShow = true;