import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import MinMaxDateRangePicker from './MinMaxDateRangePicker';
import { useNavigate } from 'react-router-dom';

import Moment from 'moment'
import { Box } from '@mui/material';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CalendarDialog() {
  const [open, setOpen] = React.useState(false);

  const [value, setValue] = React.useState([Moment().toDate(), Moment().toDate()]);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  const onsubmit = () => {
    localStorage.setItem('dateRange', JSON.stringify([Moment(value[0]).format('MM/DD/YYYY'),Moment(value[1]).format('MM/DD/YYYY')]));
    navigate('/company-listing');
  };

  
  React.useEffect(() => {

}, [open])


  return (
    <div>
      <Button variant="contained" sx={{ backgroundColor:'#f37b21', color:'#fff', height:56, display:'inline-flex', alignItems:'center', padding:'0.5rem 1rem' }} onClick={handleClickOpen}>
        Choose Date
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        // fullWidth
        maxWidth={600}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ textAlign:'center' }}>{"Reports Uploaded in Between"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
                <MinMaxDateRangePicker value={value} setValue={setValue}/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' color='success' onClick={()=>onsubmit()}>Submit</Button>
          <Button variant='outlined' color='error' onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
