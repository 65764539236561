import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PieChart from "./CustomChart/PieChart";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import ForensicItemTable from "./ForensicItemTable";
import { useDispatch, useSelector } from "react-redux";
import { ForensicTabShowHideAction } from "../../redux/actions/userAction";
import { vdrForensicAction } from "../../redux/actions/VdrAction";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ForensicComponent = (props) => {
  const { companyId, setOpenForm, toggleDrawer, setParentGroup, dataFor } =
    props;
  const [value, setValue] = React.useState(0);

  const { data: companyData, loading: companyLoading } = useSelector(
    (state) => state.vdrSingleCompanyReducer
  );


  let secondaryBtnArr = [
    {
      id: 1,
      label: "Consolidated",
      value: "Consolidated",
      short_name: "con",
      show_in: [1, 2, 3, 4],
    },
    {
      id: 2,
      label: "Standalone",
      short_name: "std",
      value: "Standalone",
      show_in: [1, 2, 3, 4],
    },
  ];

  const [BtnStatus, setBtnStatus] = React.useState(null);
  const [ActivePrimaryBtn, setActivePrimaryBtn] = React.useState(secondaryBtnArr[0]);


  const handleChange = (event, newValue) => {
    console.log('newValue ??? ', newValue)
    setValue(newValue);
  };
  const { data: ForensicTabShowHide, loading: ForensicTabShowHideLoading } =
    useSelector((state) => state.ForensicTabShowHideReducer);
  const dispatch = useDispatch();
  const r_companyId = companyId || companyData.CompanyID;

  useEffect(() => {
    dispatch(ForensicTabShowHideAction({ companyID: r_companyId }));
  }, [dispatch]);




  const changeType = (item) => {
    // {
    //     // console.log('item >>> ', item)
    //     setActivePrimaryBtn(item);
    // }
    setActivePrimaryBtn(item);
    const params = {
        "companyID": companyId || companyData.CompanyID,
        "Type": 'CAP',
        "dataFor": item?.short_name,
        "childType": (item?.short_name == "CAP" ? "Fund_Flow" : "")
      }
      dispatch(vdrForensicAction(params));
      
  }



  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {ForensicTabShowHide?.Data &&
              ForensicTabShowHide?.Data.filter((item) => item.is_display).map(
                (item, index) => {
                  return (
                    <Tab
                      sx={{ fontSize: "0.75rem" }}
                      key={index}
                      label={item.title}
                      {...a11yProps(index)}
                    />
                  );
                }
              )}
          </Tabs>
        </Box>
        {ForensicTabShowHide?.Data &&
          ForensicTabShowHide?.Data.filter((item) => item.is_display).map(
            (item, index) => {
              return (
                <TabPanel key={index} value={value} index={index}>
                  {/* <>
                    {item.type === "CAP" ? (
                      <div style={{
                        display:"flex",
                        gap: '.5rem'
                       }}
                       >

                        {
                        secondaryBtnArr && secondaryBtnArr.map((item, i) => {
                          return (
                            <>
                              <button
                                onClick={() => changeType(item)}
                                key={i}
                                disabled={
                                  BtnStatus &&
                                  BtnStatus[item?.short_name] === false
                                }
                                className={`btn ${
                                  ActivePrimaryBtn.id === item.id
                                    ? "primary-btn"
                                    : "secondary-btn-outline"
                                } ${
                                  BtnStatus &&
                                  BtnStatus[item?.short_name] === false
                                    ? "cst_disabled"
                                    : ""
                                }`}
                              >
                                {item?.label}{" "}
                              </button>
                            </>
                          );
                        })}
                      </div>
                    ) : null}
                  </> */}
                  <ForensicItemTable
                    dataFor={dataFor}
                    setParentGroup={setParentGroup}
                    setOpenForm={setOpenForm}
                    toggleDrawer={toggleDrawer}
                    companyId={r_companyId}
                    title={item.title}
                    typeData={item.type}
                    ActivePrimaryBtn={ActivePrimaryBtn}
                    setActivePrimaryBtn={setActivePrimaryBtn}
                    secondaryBtnArr={secondaryBtnArr}
                    changeType={changeType}
                    BtnStatus={BtnStatus}
                  />
                </TabPanel>
              );
            }
          )}
      </Box>
    </>
  );
};

export default ForensicComponent;
