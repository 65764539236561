import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SendNotificationAction } from '../../../redux/actions/userAction';
import UsersListTable from '../UsersListTable'
import CustomText from './CustomText';

const UsersSendNotification = (props) => {
    const {
        DataRoomDocDetail,
        setDataRoomDocDetail,
        toggleDrawer
    } = props;
    
    useEffect(() => {
      console.log('props >>>>>>>>>> ', props)
    }, [])
    

    const ref1 = React.useRef()
    const CHARACTER_LIMIT = 500;
    const dispatch = useDispatch();
    const { message } = useSelector(state => state.MessageReducer)

    const [selected, setSelected] = React.useState([]);
    const [RadioValue, setRadioValue] = React.useState('Both');
    const [MessageValue, setMessageValue] = React.useState(0);

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
    };
    const handleMessageChange = (e) => {
        //    console.log(e.target.value);
        console.log(message);
        // let dd = {}
        dispatch({
            type: 'MessageRequest',
            payload: { ...message, value: e.target.value },
        });

        //    dispatch(MessageAction(e.target.value))
        //    setMessageValue({name:event.target.value});
    };
    const sendNotification = (e) => {

        let message_val = ref1.current.value;
        var dataR = {};
        dataR = {
            ...dataR,
            ...DataRoomDocDetail,
            messageFor:RadioValue,
            message:message_val,
            sendUserIds:selected
        }
        
        console.log('dataR >>>> ', dataR);

        // dispatch(SendNotificationAction(dataR));
        
    }
    let dd = 0;

    return (
        <>

            <Box sx={{
                maxWidth: '600px',
                display: 'flex',
                justifyContent: 'center',
                // justifyContent:'center',
                flexDirection: 'column',
                paddingTop: 1,
                paddingBottom: 1,
                position:"relative",
                margin:'0 auto'
            }}>
                <Box>
                    <Typography sx={{ 

                    }} variant="h6" >
                    {DataRoomDocDetail?.comName}: <small>({DataRoomDocDetail?.docName})</small>
                    {/* toggleDrawer={toggleDrawer} 
                    setOpenForm={setOpenForm */}
                    </Typography>
                    <Divider />
                </Box>

                <UsersListTable selected={selected} setSelected={setSelected} />

                <Box>
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Gender</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={RadioValue}
                            onChange={handleRadioChange}
                        >
                            <FormControlLabel value="Whatsapp" control={<Radio />} label="Whatsapp Only" />
                            <FormControlLabel value="E-Mail" control={<Radio />} label="Mail Only" />
                            <FormControlLabel value="Both" control={<Radio />} label="Both" />
                        </RadioGroup>
                    </FormControl>
                </Box>

                <Box>
                    <CustomText ref1={ref1} CHARACTER_LIMIT={CHARACTER_LIMIT} />
                </Box>

                <Box>
                    <Grid item xs={12}>
                        <>
                            <Button sx={{ margin: '0'  }} type="button" variant="contained" onClick={(e) => sendNotification(e)}>Submit</Button>
                            <Button sx={{ margin: '0 6px'  }} type="button" variant="contained" onClick={(e) => toggleDrawer('bottom', false)(e)}>Cancel</Button>
                        </>
                    </Grid>
                </Box>

            </Box>

        </>
    )
}

export default UsersSendNotification