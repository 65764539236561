import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import React, { Fragment, useContext } from 'react'
import { FiSearch } from "react-icons/fi";
import './new_style.scss'
import { useState } from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { crtUserId, mediaInput } from '../../../constants/helpers';
import moment from 'moment';
import { forumAction, forumDetailAction, vdrMediaAction } from '../../../redux/actions/VdrAction';
import { companyMasterAction } from '../../../redux/actions/userAction';
import { useParams } from 'react-router-dom';
import TagSearchInput from './TagSearchInput';
import { GlobalContext } from '../../../context/GlobalContext';
import { FORUM_DETAIL_REQUEST, NEWSLETTER_COMMENT_REQUEST } from '../../../constants/VdrConstants';

const NewsletterPageNew = () => {

    const dispatch = useDispatch();
    // const { data: vdrMediaData, loading: vdrMediaLoading } = useSelector((state) => state.vdrMediaReducer);
    // const { data: companyMasterData, loading: companyMasterLoading } = useSelector((state) => state.companyMasterData);
    // const { data: vdrMediaGroupData, loading: vdrMediaGroupLoading } = useSelector((state) => state.vdrMediaGroupReducer);

    const {
        data:{
            data:ForumData
        },
        loading:ForumLoading,
    } = useSelector(state => state.ForumReducer)

    const {
        data: ForumDetailData,
        loading: ForumDetailLoading
    } = useSelector(state=>state.ForumDetailReducer)

    const { data: UserLoginData, loading: UserLoginLoading, isAuth, isRedirect } = useSelector(state => state.UserLoginReducer);
    const [ActiveVideoType, setActiveVideoType] = useState(1);
    const [ActiveVideoNews, setActiveVideoNews] = useState({});
    const [ActiveTag, setActiveTag] = useState({});
    const [ButtonsArr, setButtonsArr] = useState([]);


    let { newsletterSlug } = useParams();
    const [FilterVideoData, setFilterVideoData] = React.useState([]);
    const [VidSortStatus, setVidSortStatus] = React.useState(false);
    const [ActiveImage, setActiveImage] = React.useState("");
    const [ActiveVideoItem, setActiveVideoItem] = React.useState("");
    const [NewsletterData, setNewsletterData] = React.useState([]);
    
    
    const sortVideos = (sortStatus=false, nextLoad=false, crtTab=ActiveVideoType) => {
       
    }
    const [DataForFreeUser, setDataForFreeUser] = useState(false);
    const [PageTitle, setPageTitle] = useState("");
    
    const {
        ActiveNewsLetter, setActiveNewsLetter,
        ActiveNewsLetterQuarter, setActiveNewsLetterQuarter
    } = useContext(GlobalContext)

    let getDataType = Number(0);
    // if(!window.location.href.includes('vdr')){
    if(window.location.href.includes('free-blog')){
        newsletterSlug = 'management'
        getDataType = Number(1);
    }



    useEffect(() => {
        if(ForumLoading){
            dispatch(forumAction({type: getDataType}))
        }
        if(!ForumLoading){
            let $allNData = [];
            let allNewsletterData = ForumData?.newsletter
            if(allNewsletterData.length > 0){
                $allNData = allNewsletterData[0];
                
                if(newsletterSlug === 'management'){
                    setDataForFreeUser(true)
                }

                dispatch({ type: FORUM_DETAIL_REQUEST });
                let params = {
                    "slug": $allNData.company_slug,
                    "user_id": crtUserId(),
                    "tag_id": ActiveTag?.value,
                    // "date":data?.BlogAddonTot_date_new[0]
                    // "from_date": moment().subtract(11, 'days').format('YYYY-MM-DD'),
                    // "to_date": moment().format('YYYY-MM-DD'),
                }
                dispatch(forumDetailAction(params));
                
                setActiveNewsLetter($allNData);
                setActiveVideoNews($allNData);
                setActiveVideoType($allNData.id);

            }
            
            if(newsletterSlug === 'management'){
                setDataForFreeUser(true)
                // setNewsletterData([$allNData])
                setNewsletterData(allNewsletterData)
            }else{
                setNewsletterData(allNewsletterData)
            }
        }
    }, [dispatch, ForumLoading])


    useEffect(() => {

        // if(companyMasterLoading){
        //     dispatch(companyMasterAction());
        // }
        // if (vdrMediaLoading && vdrMediaData.length === 0) {
        //     let a0 = mediaInput;
        //     a0 = {...a0, fromDate: moment().subtract(20, 'years').format('YYYY-MM-DD')}
        //     dispatch(vdrMediaAction(a0));
        // }
        // if (!vdrMediaLoading) {
        //     sortVideos();
        // }
// console.log('ForumDetailLoading >>> ', ForumDetailLoading)
    }, [dispatch, ForumDetailLoading, ActiveTag])

    const chooseType = (item) =>{
        setActiveNewsLetterQuarter(null);
        setActiveNewsLetter(item);
        setActiveVideoNews(item);
        setActiveVideoType(item.id)
        if(item?.quarter && item?.quarter.length > 0){
            let firstData = item?.quarter[0];
            chooseQuarter(firstData, item);
            setActiveNewsLetterQuarter(firstData);
        }


        dispatch({ type: FORUM_DETAIL_REQUEST });
        let params = {
            "slug": item.company_slug,
            "user_id": crtUserId(),
            // "tag_id": ActiveTag?.value,
            // "date":data?.BlogAddonTot_date_new[0]
            // "from_date": moment().subtract(11, 'days').format('YYYY-MM-DD'),
            // "to_date": moment().format('YYYY-MM-DD'),
        }
        dispatch(forumDetailAction(params));


    }

    const chooseQuarter = (item, mainItem={}) =>{
        
        setActiveNewsLetterQuarter(item);
        let a0 = []
        // let a0 = item.split('__')
        let params = {
          "slug": mainItem?.company_slug || ActiveVideoNews.company_slug,
          "user_id": crtUserId(),
          "date": (a0?.[0] || ""),
          "addon_id": (a0?.[1] || ""),
          "tag_id": ActiveTag?.value,
          "quarter_id": item?.id,
        }
        dispatch({ type: NEWSLETTER_COMMENT_REQUEST });
        dispatch(forumDetailAction(params));

        
    }
    
    useEffect(() => {
        let f = document.querySelector('footer');
        if(f){
            f.remove()
        }
        if(DataForFreeUser){
            setPageTitle("Blogs")
        }
        // page_title = (DataForFreeUser && "Blogs")
        let a0 = window.location.href.split('/')
        
        if(a0[a0.length-1] == 'newsletter'){
            setPageTitle("Newsletter")
        }
        
    },[DataForFreeUser]);


      
  return (
    <>
    
    <Box className="headerContainerFront" sx={{
        height:'auto'
       }}>
        <Typography
          style={{ zIndex: 2 }}
          fontSize={"2.5em"}
          color="#000"
          fontWeight="700"
        >
            { PageTitle }
        </Typography>
        <Box className='vid-head-btn'>
            {
                NewsletterData.map((item, index)=>{
                    return (
                        <Fragment key={item.id}>
                            <Button onClick={(e)=>{
                                chooseType(item);
                            }} size="small" className={`cst-btn ${ActiveVideoType===item.id ? 'contained' : "outlined"}`} variant={ActiveVideoType===item.id ? 'contained' : "outlined"} >{item?.name}</Button>
                        </Fragment>
                    )
                })
            }
        </Box>
        {
            ActiveNewsLetter?.quarter && (
                <Box className='vid-head-btn' sx={{ marginTop: 1 }}>
                    {
                        ActiveNewsLetter?.quarter && ActiveNewsLetter?.quarter.map((item, index)=>{
                            return (
                                <Fragment key={item.id}>
                                    <Button onClick={(e)=>{
                                        chooseQuarter(item)
                                    }} size="small" className={`cst-btn ${ActiveNewsLetterQuarter?.id===item.id ? 'contained' : "outlined"}`} variant={ActiveNewsLetterQuarter?.id===item.id ? 'contained' : "outlined"} >{item?.name}</Button>
                                </Fragment>
                            )
                        })
                    }
                </Box>
            )
        }
        <div className='vid-head-sec'>
            <Box className='vid-head-search' mt={2}>
                <div>
                    {/* <TagSearchInput 
                        ActiveTag={ActiveTag}
                        setActiveTag={setActiveTag}
                        ActiveVideoNews={ActiveVideoNews}
                        setActiveVideoNews={setActiveVideoNews}
                    /> */}
                    {/* <TextField
                        id="search-field"
                        size='small'
                        placeholder="Search"
                        fullWidth
                        // sx={{ m: 1, width: '25ch' }}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">
                                <FiSearch />
                            </InputAdornment>,
                        }}
                        /> */}
                </div>
            </Box>
        </div>
      </Box>
      <Box className="videoContainer" sx={{
        marginTop: 5, marginBottom: 5
      }}>
        
        <div className='vid-body-sec'>
            <Box>
                <Typography sx={{ 
                    display: ForumDetailData?.BlogAddonTot_date && ForumDetailData?.BlogAddonTot_date.length == 0 ? "block" : "none"
                    }}>No Data found</Typography>

                <Grid container spacing={2} sx={{ 
                    display: ForumDetailData?.BlogAddonTot_date && ForumDetailData?.BlogAddonTot_date.length == 0 ? "none" : ""
                    }}>
                    <Grid item xs={12} sm={3}>
                        <LeftSection 
                            VidSortStatus={VidSortStatus}
                            setVidSortStatus={setVidSortStatus}
                            sortVideosTypeFun={sortVideos}
                            FilterVideoData={FilterVideoData}
                            setFilterVideoData={setFilterVideoData}
                            ActiveImage={ActiveImage}
                            setActiveImage={setActiveImage}
                            ActiveVideoType={ActiveVideoType}
                            setActiveVideoType={setActiveVideoType}
                            ActiveVideoItem={ActiveVideoItem}
                            setActiveVideoItem={setActiveVideoItem}
                            ActiveVideoNews={ActiveVideoNews}
                            setActiveVideoNews={setActiveVideoNews}
                            ActiveTag={ActiveTag}
                            setActiveTag={setActiveTag}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <RightSection
                            ActiveImage={ActiveImage}
                            setActiveImage={setActiveImage}
                            ActiveVideoItem={ActiveVideoItem}
                            setActiveVideoItem={setActiveVideoItem}
                            ActiveVideoNews={ActiveVideoNews}
                            setActiveVideoNews={setActiveVideoNews}
                            ActiveTag={ActiveTag}
                            setActiveTag={setActiveTag}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
      </Box>
    </>
  )
}

export default NewsletterPageNew