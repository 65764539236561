import React, { useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { ForensicTabShowHideAction } from "./../../redux/actions/userAction";
import { vdrForensicAction } from "../../redux/actions/VdrAction";
import ForensicItemTableCapital from "./ForensicItemTableCapital";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CapitalStructure = ({
  ActivePrimaryBtn,
  secondaryBtnArr,
  setActivePrimaryBtn,
  companyData,
  ForensicItemTable,
  dataFor,
  setParentGroup,
  setOpenForm,
  toggleDrawer,
  companyId,
  title,
  typeData,
  r_companyId,
  inputType,
  setInputType
}) => {
  console.log("Capital structure");
  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);




  const [BtnStatus, setBtnStatus] = React.useState(null);

  const [ActivePrimaryTab, setActivePrimaryTab] = React.useState();


  let cap_str_submenu = [
    {
      id: 0,
      title: "Fund Flow",
      value: "Fund_Flow",
      short_name: "ff",
      type:"CAP",
      show_in: [1, 2, 3, 4],
    },
    {
      id: 1,
      title: "Working Capital Flow",
      value: "Working_Capital",
      short_name: "cap",
      type:"CAP",
      show_in: [1, 2, 3, 4],
    },
  ];

  const handleChange = (event, newValue, item) => {
    // console.log("newValue ??? ", newValue, item);

    setActivePrimaryBtn(secondaryBtnArr[0])
    setValue(newValue);
    setActivePrimaryTab(item);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const changeType = (item,childfor) => {
    // {
    //     // console.log('item >>> ', item)
        setActivePrimaryBtn(item);
    // }

    const params = {
        "companyID": companyId || companyData.CompanyID,
        "Type": 'CAP',
        "dataFor": item?.short_name,
        "childType":childfor
      }
      dispatch(vdrForensicAction(params));

  }

  useEffect(() => {
    setActivePrimaryTab(cap_str_submenu[0])
  }, [])
  

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          // onChange={handleChange}
          aria-label="basic tabs example"
        >
          {cap_str_submenu.map((item, index) => {
            return (
              <Tab
                sx={{ fontSize: "0.75rem" }}
                key={index}
                label={item.title}
                onClick={(e)=>handleChange(e, index, item)}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>

      {cap_str_submenu.map((item, index) => {

        return (
          <TabPanel
            key={index}
            value={value}
            index={index}

          >
            <div
              style={{
                display: "flex",
                gap: ".5rem",
              }}
            >


            {

                secondaryBtnArr &&
                secondaryBtnArr.map((item2, i) => {
                  return (
                    <>
                      <button
                        onClick={() => changeType(item2,item.value)}
                        key={i}
                        disabled={
                          BtnStatus && BtnStatus[item2?.short_name] === false
                        }
                        className={`btn ${
                          ActivePrimaryBtn.id === item2.id
                            ? "primary-btn"
                            : "secondary-btn-outline"
                        } ${
                          BtnStatus && BtnStatus[item2?.short_name] === false
                            ? "cst_disabled"
                            : ""
                        }`}
                      >
                        {item2?.label}{" "}
                      </button>
                    </>
                  );
                })}
            </div>

            <ForensicItemTableCapital
                ActivePrimaryTab={ActivePrimaryTab}
                setActivePrimaryTab={setActivePrimaryTab}
                inputType={inputType}
                setInputType={setInputType}
                setBtnStatus={setBtnStatus}
                BtnStatus={BtnStatus}
                dataFor={dataFor}
                setParentGroup={setParentGroup}
                setOpenForm={setOpenForm}
                toggleDrawer={toggleDrawer}
                companyId={r_companyId}
                title={item.title}
                typeData={item.type}
                childFor={item.value}
                ActivePrimaryBtn={ActivePrimaryBtn}
                setActivePrimaryBtn={setActivePrimaryBtn}
                secondaryBtnArr={secondaryBtnArr}
            />
          </TabPanel>
        );
      })}
    </>
  );
};

export default CapitalStructure;
