import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import HistoricalTrendChooseField from './HistoricalTrendChooseField'
import { useDispatch, useSelector } from 'react-redux'
import { vdrData20YearsAction } from '../../../../redux/actions/VdrAction'
import { CustomChart } from '../../../../frontend/components'
import { useEffect } from 'react'

const HistoricalTrend = ({companyId, themeColor}) => {
    const dispatch = useDispatch();
    const [HistoricalTrendData, setHistoricalTrendData] = useState(null);
    const { data, loading } = useSelector(state => state.vdrData20YearsReducer);

    const { data: PeerRatioData, loading: PeerRatioLoading, isSelected, selected_companies, other_companies } = useSelector(state => state.PeerRatiosReducer);

    const applyChart = (chartType) => {
        if (!chartType.length) {
            chartType = PeerRatioData.map(item=>item.Name);
        }

        dispatch(vdrData20YearsAction([
            {
                "CompanyID": companyId,
                "userid": 1,
                "Param": chartType,
                "ChartType": "Annually"
            }
        ]))

    }

    useEffect(() => {
        if(!PeerRatioLoading){
            if (loading) {
                dispatch(vdrData20YearsAction([
                    {
                        "CompanyID": companyId,
                        "userid": 1,
                        // "Param": ["Market Cap (cr.)", "Net Sales (cr.)", "Cons PAT (cr.)", "Gross Profit (cr.)", "Gross Profit Margin (%)", "EBIDTA (cr.)"],
                        "Param": PeerRatioData.filter(itm=>itm.is_selected === true).map(item=>item.Name),
                        "ChartType": "Annually"
                    }
                ]))
            }
        }
    }, [dispatch, PeerRatioLoading])

    useEffect(() => {
        if(!PeerRatioLoading){
            if (!loading) {
                let qData = data.Data;
                let demo0 = [];
                let title = null;
                if (qData && qData.length > 0) {
                    qData = qData[0];
                    Object.keys(qData).map((item, i) => {
                        var demo1 = { cat: [], value: [], title: title, typeFor: 20, CAGR: "" };
                        if (item !== '$id' && item !== '_MainHeader') {
                            var itemQData = qData[item];
                            var a = 0;
                            Object.keys(itemQData).map((subItem, i1) => {
    
                                if (subItem !== '$id') {
                                    if (subItem === '_chartName') {
                                        demo1.title = title = itemQData[subItem]?.Name;
                                        if (itemQData[subItem]?.CAGR) {
                                            demo1.CAGR = itemQData[subItem]?.CAGR;
                                        }
                                    } else {
                                        if (itemQData[subItem]?.Value !== null && itemQData[subItem]?.Value !== undefined) {
                                            demo1.cat = [...demo1.cat, itemQData[subItem]?.Name]
                                            var value1 = parseFloat(itemQData[subItem]?.Value);
                                            demo1.value = [...demo1.value, value1]
                                        }
                                    }
                                }
                            });
                            demo0[item] = demo1;
                        }
                    })
                    setHistoricalTrendData(demo0)
                }
            }
        }
    }, [dispatch, loading, PeerRatioLoading])



  return (
    <>
      <Box>
        
        <HistoricalTrendChooseField applyChart={applyChart} />
        <Grid container rowSpacing={0} columnSpacing={{ xs: 1 }} sx={{ backgroundColor:'#fff' }} >
            {
                HistoricalTrendData &&
                Object.keys(HistoricalTrendData).map((item) => (
                    <Grid item xs={12} sm={6} md={2} >
                        <div>
                            <CustomChart themeColor={themeColor} chartData={HistoricalTrendData[item]} chartCategory={[]} />
                        </div>
                    </Grid>
                ))
            }
        </Grid>
      </Box>
    </>
  )
}

export default HistoricalTrend
