import React, { useContext } from 'react'
import { Route, Routes, useParams, useLocation, BrowserRouter, useNavigate } from 'react-router-dom'
import Layout from './Layout'
import './App.css'
import {
  HomePage,
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  NavigationPage,
  CalendarPage,
  ValuationPage,
  DeliveryDataPage,
  PriceSectionPage,
  TextForm
} from './pages/index'

import Frontend from './frontend/Frontend';

import publicIp from 'public-ip';


import { GroupsPage, MyPdfViewer, SortDataPage } from './pages/VDR'
import PDFView from './pages/VDR/PDFView'
import { useDispatch, useSelector } from 'react-redux'
import { DefaultMastersAction, dateAPIAction, publicIpAction } from './redux/actions/VdrAction';
import Tree from './pages/VDR/Tree'
import LayoutVdr from './LayoutVdr'
import { checkUser, decodeAtob, getBaseLink } from './constants/helpers'
import SingleCompany from './pages/SingleCompany'
import INV from './pages/INV'
import ProtectedRoute from './ProtectedRoute'
import SingleCompanyContainer from './pages/single_compamy'
import DailyNewsletter from './frontend/pages/daily-newsletter'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import StockChart from './pages/StockChart'
import CommonCompanyContainer from './pages/common_compamy'
import SingleCompanyChoose from './pages/single_compamy/SingleCompanyChoose'
import { singleCompanyNoteAction } from './redux/actions/userAction'
import { Box, CircularProgress } from '@mui/material'
import { GlobalContext } from './context/GlobalContext'

const PageNotFound = () => {
  return (
    <>
      <h2>page Not Found</h2>
    </>
  )
}



const App = (props) => {
  // publicIp.v4()


  
  const {
    IsCompanyDetail, setIsCompanyDetail
  } = useContext(GlobalContext);


  const { data: CompanyNoteData, loading: CompanyNoteLoading } = useSelector(state => state.singleCompanyNoteReducer);
  const { data: UserLoginData, loading: UserLoginLoading, isAuth, isRedirect } = useSelector(state => state.UserLoginReducer);



console.log("userdata ======================", isRedirect);



  const dispatch = useDispatch();
  React.useEffect(() => {

    if(window.location.pathname.includes('company-detail')){
      setIsCompanyDetail(true);
    }

    
    // if(!window.location.pathname.includes('admin')){
    //   let urlArr = window.location.pathname.split('/');
    //   let companyId0 = urlArr[urlArr.length-1]
    //   if(companyId0){
    //       companyId0 = decodeAtob(companyId0);
    //       var prams = { "CompanyID": companyId0 };
    //       dispatch(singleCompanyNoteAction(prams));
    //   }
    // }
    
  }, [])

  // React.useEffect(() => {
  //   if(!CompanyNoteLoading){
  //     let cmpData = CompanyNoteData.Data[0];
  //     console.log('cmpData >>>>> ', cmpData);
  //     let theme_color = cmpData?.theme_color
  //     let Chart_colors = cmpData?.Chart_colors
  //     dispatch({ type: "themeColor", payload: theme_color });
  //     dispatch({ type: "chartColor", payload: Chart_colors });
  //     document.documentElement.style.setProperty(
  //       "--primary-color",
  //       theme_color
  //     );
  //   }
  // },[CompanyNoteLoading])

  
  const { data: dataAPIData, loading: dataAPILoading } = useSelector((state) => state.dateAPIReducer);


  React.useEffect(() => {
    if (dataAPILoading) {
      dispatch(dateAPIAction());
    }
  },[])

  React.useEffect(() => {
    console.log('First Load')

    dispatch(DefaultMastersAction())
    dispatch(checkUser())
    console.log('after call u_check')
    

  },[])


  let isValidate = [
    'vdr.omkaracapital.net',
    'omkaracapital.in',
    'omkaracapital.net'
  ]


  let navigate = useNavigate();
  React.useEffect(() => {
    if(isRedirect?.isFreeBLog === true){
          // navigate('/managment0')
    }else
    if(isRedirect?.isLogin === true){
      let LIVE_SITE_URL = window.location.host;
      if(window.location.host === "vdr.omkaracapital.net"){
          LIVE_SITE_URL = 'omkaracapital.in';
      }
      
      window.location.href = `https://${LIVE_SITE_URL}/user/login?next=${window.location.href}`;

    }
  },[isRedirect])
  dispatch(publicIpAction())
  

  if(isAuth === false && isValidate.includes(window.location.host)){
      return (
        <Box sx={{ 
          width: '100%',
          height: '100vh',
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
         }}>
          <CircularProgress />
        </Box>
      )
  }
  
  return (
    <>
    
      <ToastContainer />

      <Routes>
        <Route exact path="/login" element={<LoginPage />} />
        
        <Route exact path="/Tree" element={<ProtectedRoute><Tree /></ProtectedRoute>} />
        <Route exact path="PDFView" element={<ProtectedRoute><PDFView /></ProtectedRoute>} />
        <Route exact path="/MyPdfViewer" element={<ProtectedRoute><MyPdfViewer /></ProtectedRoute>} />
        
        {/* <Route exact path='/old-videos' element={<Frontend.VideosPage />} /> */}
        {/* <Route exact path='/old-newsletter' element={<Frontend.TOTPage />} /> */}
        {/* <Route exact path='/old-blogs' element={<Frontend.Blogs />} /> */}
        {/* <Route exact path='/old-resources' element={<Frontend.Resources />} /> */}

        {/* <Route path="/" element={
          <ProtectedRoute>
            <Frontend.Layout />
          </ProtectedRoute>
        } >
          <Route exact path='/:newsletterSlug' element={<Frontend.NewsletterPageNew />} />
        </Route> */}
        
        {/* Below routes for vdr.omkaracapital.net  omkaradata.in/vdr  omkaradata.com/vdr   */}

        <Route path="/" element={
          <ProtectedRoute>
            <Frontend.Layout />
          </ProtectedRoute>
        } >
          <Route index element={<Frontend.HomePage />} />
          <Route exact path="/calendar" element={<CalendarPage dataFor="frontend" />} />
          <Route exact path='/ticker' element={<Frontend.TickerPage />} />
          <Route exact path='/small-case' element={<Frontend.SmallCase />} />
          <Route exact path='/videos' element={<Frontend.VideoPageNew />} />
          <Route exact path='/my-list' element={<Frontend.MyListPage />} />
          <Route exact path='/videos/:vidSlug' element={<Frontend.VideoPageNew />} />
          <Route exact path='/newsletter' element={<Frontend.NewsletterPageNew />} />
          <Route exact path='/newsletter/:newsletterSlug' element={<Frontend.NewsletterPageNew />} />
          <Route exact path='/resources' element={<Frontend.ResourcesComponent />} />
          <Route exact path='/blogs' element={<Frontend.BlogPageNew />} />

          {/* =================== Aakash ========================= */}
          <Route exact path='/insights' element={<Frontend.BlogPageNew  insigths ="insigths" />} />
          {/* =================== Aakash ========================= */}

          <Route exact path='/blogs/:blogSlug' element={<Frontend.BlogPageNew />} />
          <Route exact path='/favorite-listing' element={<Frontend.FavoriteListing />} />
          <Route exact path='/single-company/:companyId' element={<SingleCompany />} />
          <Route exact path='/company-listing' element={<Frontend.CompanyListing />} />
          <Route exact path='/company/:companyName' element={<Frontend.CompanyDetail />} />
          <Route exact path='/:companyName' element={<Frontend.CompanyOldDetail />} />
        </Route>

          {/*  ENDED Below routes for vdr.omkaracapital.net  omkaradata.in/vdr  omkaradata.com/vdr   */}


          {/* Below routes for enable Admin   */}

        {/* <Route path="/admin" element={<LayoutVdr />}>
          <Route index element={<GroupsPage />} />
        </Route> */}

        {/* ENDED Below routes for enable Admin   */}


        {/* <Route exact path='/stock-chart' element={<StockChart />} /> */}


        {/* Below routes for ACE TEST   */}

        {/* <Route exact path="/company-detail-aceapitest/MyPdfViewer" element={<ProtectedRoute><MyPdfViewer /></ProtectedRoute>} />
          <Route path="/" element={<ProtectedRoute>
              <Layout />
            </ProtectedRoute>}> 
            <Route exact path='/company-detail-aceapitest' element={<SingleCompanyChoose />} />
            <Route exact path='/company-detail-aceapitest/:companyName' element={<SingleCompanyContainer />} />
            <Route exact path='/company-common/:companyName' element={<CommonCompanyContainer />} />
        </Route> */}


        {/* <Route exact path="/company-detail/MyPdfViewer" element={<ProtectedRoute><MyPdfViewer /></ProtectedRoute>} />
        <Route path="/" element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>}>
            <Route exact path='/company-detail' element={<SingleCompanyChoose />} />
            <Route exact path='/company-detail/:companyName' element={<SingleCompanyContainer />} />
            <Route exact path='/company-common/:companyName' element={<CommonCompanyContainer />} />
        </Route>  */}

        {/* <Route exact path="/company-detail/MyPdfViewer" element={<ProtectedRoute><MyPdfViewer /></ProtectedRoute>} />
        <Route path="/" element={
          <ProtectedRoute>
            <Layout />
          </ProtectedRoute>
        }> 
          <Route index element={<SingleCompanyChoose />} />
          <Route exact path='/:companyName' element={<SingleCompanyContainer />} />
          <Route exact path='/company-common/:companyName' element={<CommonCompanyContainer />} />
        </Route>  */}

{/* 
        <Route path="/price" element={<Layout />}>
          <Route index element={<PriceSectionPage dataFor="backend" />} />
        </Route> */}

        {/* <Route path="/admin" element={<LayoutVdr />}>
          <Route index element={<GroupsPage />} />
        </Route> */}


        {/* START FOR https://omkaradata.in/new-test/data2/ || https://omkaradata.com/data2/ */}
        
        {/* <Route path="/" element={<Layout />}> 
          <Route index element={<HomePage />} />
          <Route path="navigation" element={<NavigationPage />} />  
          <Route path="calendar" element={<CalendarPage dataFor="backend" />} />
          <Route path="valuation" element={<ValuationPage />} />
          <Route path="delivery-data" element={<DeliveryDataPage />} />
          <Route path="reset-password" element={<ResetPasswordPage />} />
          <Route path="text-form" element={<TextForm />} />
          <Route path="fii" element={<INV />} />
          <Route exact path='/company/:companyName' element={<Frontend.CompanyDetail />} />
          <Route exact path='/company-detail/:companyName' element={<SingleCompanyContainer />} />
          <Route exact path='/single-company/:companyId' element={<SingleCompany />} />
        </Route> */}

       
        {/* END FOR https://omkaradata.in/new-test/data2/ || https://omkaradata.com/data2/ */}
        
     
         {/* <Route path="/assign" element={<Layout />}>
          <Route index element={<CalendarPage dataFor="backend" />} />
        </Route>   */}

        {/* <Route path="/list" element={<Layout />}>
          <Route index element={<PriceSectionPage dataFor="backend" />} />
        </Route> */}

        <Route path="*" element={<PageNotFound />} />
        
      </Routes>
      

    </>
  )
}

export default App