import React, { useEffect } from 'react'


import {
    CardActionArea,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Popover,
    Box,
    IconButton,
    Button,
    inputClasses,
    Tooltip
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { convertToSlug, ideaTypeTitle, stripTags, wordLimit, recommendationType, loggedInUser } from '../../constants/helpers';
import { Link } from 'react-router-dom';
import { motion } from "framer-motion";
//import { BsPlusCircleFill } from "react-icons/bi";
import { BsCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { vdrFavoriteAction } from '../../redux/actions/VdrAction';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosCloseCircle } from "react-icons/io";


const SingleCompanyGrid = ({ companyData, numCount, disabledLink }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(null);

    const handlePopoverOpen = (event, id) => {
        setAnchorEl(event.currentTarget);
        setOpen(id);
    };


    
    const {
        data: DefaultMasterData, loading:DefaultMasterLoading
    } = useSelector(state=>state.DefaultMasterReducer)
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpen(null);
        // console.log('sssss', open)
    };
    var itemImage = 'https://omkaracapital.in/site_assets/images/logo.png';
    if (companyData?.logo && companyData.logo !== '' && companyData.logo !== null) {
        itemImage = companyData?.logo;
    }

    const comId = window.btoa(companyData.company_id);
    let slug = `company/${comId}`;
    if(disabledLink){
        slug = `javascript:void(0)`;
    }
    // const slug = convertToSlug(companyData.company_name);
 

    let tagColor = companyData?.price_row?.color;

    let tagLabel = "";
    let rightLabel = "";

    if (tagColor?.toLowerCase() === 'red') {
        tagColor = "#ff2222";
        tagLabel = "Sell";
    }
    if (tagColor?.toLowerCase() === 'green') {
        tagLabel = "Buy";
    }
    if (tagColor?.toLowerCase() === 'yellow') {
        tagLabel = "Hold";
    }

    useEffect(() => {
        console.log('companyData?.company_type ??? ', companyData?.company_type)
    }, [DefaultMasterLoading])



    let ideaTypeArr = DefaultMasterData?.IdeaType;
        rightLabel = ideaTypeArr && (companyData?.company_type ? ideaTypeArr[companyData?.company_type]?.title : "");

    let RecommendationData = DefaultMasterData?.Recommendation && DefaultMasterData?.Recommendation[companyData?.recommendation];
    let rightRecommendation = companyData?.recommendation ? RecommendationData?.title : "";
    // console.log(companyData);

    const dispatch = useDispatch();
    const { data: FavoriteData, loading: FavoriteLoading, error: FavoriteError } = useSelector(state => state.vdrFavoriteReducer);
    const { data: FavoriteCompanyData, loading: FavoriteCompanyLoading, error: FavoriteCompanyError } = useSelector(state => state.FavoriteCompanyReducer);






    let dd = FavoriteCompanyData;
    const updateFavoriteList = (companyId, isFavorite) => {
        const req = {
            "isFavorite": isFavorite,
            "company_id": companyId,
            "user_id": (loggedInUser?.user_id),
            "Inputtype": 1
        };
        if (isFavorite === true && !FavoriteCompanyData.includes(companyId)) {
            dd = [...FavoriteCompanyData, companyId];
        }
        console.log(FavoriteCompanyData, companyId)
        if (isFavorite === false && FavoriteCompanyData.includes(companyId)) {
            dd = FavoriteCompanyData.filter(item => item !== companyId);
        }

        dispatch({
            type: 'FavoriteCompanySuccess',
            payload: dd
        });

        dispatch(vdrFavoriteAction(req));
    }

    // useEffect(() => {
    //     // console.log(FavoriteCompanyData)
    // }, [dispatch, FavoriteCompanyData]);


    const [UpdateFavorite, setUpdateFavorite] = React.useState(false);


    return (
        <>
            <div className="companyContainer">
                {/* <Typography className='topCountLabel'>1</Typography> */}
                {
                    numCount ?
                        <Typography className='topCountLabel'>{numCount}</Typography>
                        :
                        null
                }
                <Box
                    sx={{
                        width: '100%',
                    }}
                >

                    <Card sx={{ borderBottom: `3px solid ${companyData?.recommendation ? RecommendationData?.color : '#a3a3a3'}`, maxWidth: 250 }}
                        aria-owns={open === 1 ? true : false}
                        aria-haspopup="true"
                        // onMouseEnter={(e) => handlePopoverOpen(e, companyData.company_id)}
                        // onMouseLeave={(e) => handlePopoverClose(e, companyData.company_id)}
                        className="companyThumb"

                    >



                        <>
                            {
                                FavoriteCompanyData.includes(companyData.company_id)  ?

                                    <Tooltip title="Remove Favorite"
                                        sx={{
                                            position: 'absolute',
                                            bottom: '10px',
                                            right: '2px'
                                        }}
                                    >
                                        <IconButton size='small' >
                                            <BsCheckCircleFill
                                                size={20}
                                                color={'Green'}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    null
                            }

                            <motion.div
                                className='demoData'
                                animate={{ scale: 1 }}
                                transition={{ duration: 0.4, ease: "easeOut", x: 50, y: 0 }}
                            >
                                {
                                    FavoriteCompanyData.includes(companyData.company_id) ?
                                        <>
                                            <motion.div
                                                animate={{ y: 0 }}
                                                initial={{ y: -100 }}
                                                transition={{ duration: 0.4, ease: "easeOut", x: 50, y: 0 }}
                                            >
                                                <Tooltip title="Remove From Favorite List">
                                                    <IconButton onClick={() => updateFavoriteList(companyData.company_id, false)} size='small' >
                                                        <IoIosCloseCircle
                                                            size={20}
                                                            color={'red'}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </motion.div>
                                        </>
                                        :
                                        <>
                                            <motion.div
                                                animate={{ y: 0 }}
                                                initial={{ y: 100 }}
                                                transition={{ duration: 0.4, ease: "easeOut", x: 50, y: 0 }}
                                            >
                                                <Tooltip title="Add To Favorite List">
                                                    <IconButton size='small' >
                                                        <BsPlusCircleFill
                                                            size={20}
                                                            color={'#f37b21'}
                                                            onClick={() => updateFavoriteList(companyData.company_id, true)}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </motion.div>
                                        </>

                                }

                            </motion.div>
                        </>

                        <CardActionArea component={!disabledLink ? Link : ""} to={`/${slug}`} onClick={() => localStorage.setItem("single-company-data", comId)} style={{ cursor: !disabledLink?'pointer':'unset' }}>


                            {/* <CardActionArea component={Link} to={`${comId}/${slug}`} > */}
                            <CardMedia
                                component="img"
                                height="100"
                                image={itemImage}
                                alt={companyData?.company_name}
                            />
                            {
                                rightRecommendation
                                    ?
                                    <Box className='top_label_tags'
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            top: 0,
                                            left: 0,
                                            padding: '0rem 0.4rem',
                                            background: `${RecommendationData?.color}`,
                                            fontSize: '0.8rem',
                                            color: '#fff',
                                            borderRadius: '5px 5px 5px 0'
                                        }}>
                                        {rightRecommendation}
                                    </Box>
                                    :
                                    null
                            }

                            {
                                rightLabel ?
                                    <Box className='top_label_tags1'
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            top: 0,
                                            right: 0,
                                            padding: '0rem 0.4rem',
                                            background: '#eee',
                                            fontSize: '0.8rem',
                                            color: '#000',
                                            borderRadius: '5px 0px 0px 5px'
                                        }}>
                                        {rightLabel}
                                    </Box>
                                    :
                                    null
                            }


                        </CardActionArea>
                    </Card>
                </Box>

                <Popover
                    id="mouse-over-popover"
                    sx={{
                        pointerEvents: 'none',
                        left: 0,
                        top: 0,
                    }}

                    open={open === companyData.company_id ? true : false}
                    anchorEl={anchorEl}
                    disableScrollLock={true}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    onClose={handlePopoverClose}
                    // onMouseLeave={(e) => handlePopoverClose(e, 1)}
                    disableRestoreFocus
                >

                    <Card sx={{ maxWidth: 270 }}
                    // onMouseLeave={(e) => console.log('ssssssss')}
                    >
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="140"
                                image={itemImage}
                                alt={companyData?.company_name}
                            />
                            {
                                rightRecommendation
                                    ?
                                    <Box className='top_label_tags'
                                        sx={{
                                            position: 'absolute',
                                            zIndex: 1,
                                            top: '0.5rem',
                                            left: 0,
                                            padding: '0rem 0.4rem',
                                            background: `${RecommendationData?.color}`,
                                            fontSize: '0.8rem',
                                            color: '#fff',
                                            borderRadius: '0 5px 5px 0'
                                        }}>
                                        {rightRecommendation}
                                    </Box>
                                    :
                                    null
                            }

                            <Box className='top_label_tags1'
                                sx={{
                                    position: 'absolute',
                                    zIndex: 1,
                                    top: '0.5rem',
                                    right: 0,
                                    padding: '0rem 0.4rem',
                                    background: '#eee',
                                    fontSize: '0.8rem',
                                    color: '#000',
                                    borderRadius: '5px 0px 0px 5px'
                                }}>
                                {rightLabel}
                            </Box>
                            <CardContent >
                                <Typography gutterBottom variant="body1" fontSize={18} component="div">
                                    {companyData?.company_name}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {wordLimit(stripTags(companyData.about), 10)}
                                </Typography>
                                {
                                    companyData.price_row ?
                                        <Box
                                            className='priceRow'
                                        >
                                            <Typography variant='body2'>
                                                ₹ {companyData.price_row.price}
                                            </Typography>
                                            <Typography fontSize={13} sx={{
                                                alignItems: 'center',
                                                display: 'flex',
                                                color: companyData.price_row.color,
                                                marginLeft: '10px'
                                            }}>
                                                <span>{companyData.price_row.difference}</span> <span>({companyData.price_row.differencepercentage})</span>
                                                <span >
                                                    {
                                                        companyData.price_row.arrow_type == 1 ?
                                                            <>
                                                                <ArrowUpwardIcon sx={{ fontSize: 20, marginTop: '0.3rem' }} />
                                                            </>
                                                            :
                                                            <>
                                                                <ArrowDownwardIcon sx={{ fontSize: 20, marginTop: '0.3rem' }} />
                                                            </>
                                                    }
                                                </span>
                                            </Typography>
                                        </Box>
                                        :
                                        null
                                }
                                {
                                    companyData.recommendation
                                        ?
                                        <>
                                            <Box
                                                className='recommendationRow'
                                            >
                                                <span style={{ fontSize: '12px' }}>Recommendation</span>
                                                <Box className='recommendationChild' variant="link" sx={{ color: RecommendationData?.color, display: 'inline-flex' }}>{rightRecommendation}</Box>
                                            </Box>
                                        </>
                                        :
                                        null
                                }

                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Popover>
            </div>
        </>
    )
}

export default SingleCompanyGrid