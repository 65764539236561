import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "@redux-devtools/extension"

import {
  allCompanyReducer,
  allCompanyReducer2,
  calenderReducer,
  userLoginReducer,
  forgotPasswordReducer,
  userReducer,
  companyMasterReducer,
  sectorMasterReducer,
  industryMasterReducer,
  turnAroundMasterReducer,
  resultFilterInputReducer,
  resetPasswordReducer,
  valuationReducer,
  valuationFilterReducer,
  DeliveryDataReducer,
  DeliveryDataFilterReducer,
  singleCompanyReducer,
  SingleCompanyValuationReducer,
  UploadDocumentReducer,
  SingleCompanyCommentsReducer,
  UploadDocumentAnalystNotesReducer,
  AboutUsUpdateReducer,
  SendNotificationReducer,

  FIIsModelReducer,
  FIIsSectorReducer,
  FIIsTopBottomReducer,
  FIISDateMasterReducer,
  RatioTooltipReducer,
  ForensicTabShowHideReducer,
  singleCompanyNoteReducer,
  ForensicModelCommentsReducer,
  newSubMenuReducer
  
} from './reduces/userReducer'

import {
  SegmentReducer,
  vdrGroupsReducer,
  vdrLevelReducer,
  vdrCompanyReducer,
  vdrHomeReducer,
  vdrUploadImageReducer,
  ipAddressReducer,
  vdrSingleCompanyReducer,
  vdrPDFLinkReducer,
  mediaModelReducer,
  vdrMediaDataReducer,
  vdrData20YearsQuarterlyReducer,
  vdrData20YearsReducer,
  vdrPeerToPeerReducer,
  vdrSortDataReducer,
  vdrSortUpdateDataReducer,
  vdrFavoriteReducer,
  FavoriteCompanyReducer,
  AssignEmployeeReducer,
  vdrMediaReducer,
  filesLogReducer,
  vdrEmployeeReducer,
  PriceActionFilterReducer,
  PriceActionReducer,
  vdrMediaGroupReducer,
  priceIndexMasterReducer,
  dateAPIReducer,
  ForensicReducer,
  MessageReducer,
  GetOmakaraUsersReducer,
  quarterlyReviewReducer,
  quarterlySegmentReducer,
  dataRoomTickerReducer,
  ForumDetailReducer,
  vdrNotificationReducer,
  QuarterlyResultsReducer,
  QuarterlySnapShotReducer,
  SingleCompProfitAndLossReducer,
  ForumReducer,
  VideoLikeReducer,
  VideoCommentReducer,
  VideoCommentLikeReducer,
  NewsletterLikeReducer,
  NewsletterCommentReducer,
  NewsletterCommentLikeReducer,
  SingleCompCashFlowReducer,
  SingleNewsletterLikeReducer,
  MyBlogListReducer,
  VDRMediaMyFavouriteListReducer,
  SingleCompanyBalanceSheetReducer,
  SingleCompanyratiosReducer,
  SingleCompanypeersReducer,
  DefaultMasterReducer,
  TrendlyneDataReducer,
  ComparePeerReducer,
  PeerRatiosReducer,
  vdrPeerToPeerScReducer,
  RepositoryListReducer,
  ThemeColorReducer,
  BarChartDataReducer,
  ResultDocumentNewReducer,
  ProfitAndLossChartReducer,
  ForumTagsReducer,
  UserLoginReducer,
  WatchListReducer,
  ShareholdingReducer,
  BoardOfDirectorDetailReducer,
  watchListCompanyReducer,

  ForumInsiderReducer,
  ForumDetail3Reducer,

} from './reduces/VdrReducer';



const reducer = combineReducers({
  users: userReducer,
  loginData: userLoginReducer,
  allCompanies: allCompanyReducer,
  allCompanies2: allCompanyReducer2,
  calenderData: calenderReducer,
  companyMasterData: companyMasterReducer,
  sectorMasterData: sectorMasterReducer,
  industryMasterData: industryMasterReducer,
  turnAroundMasterData: turnAroundMasterReducer,
  resultFilterInput: resultFilterInputReducer,
  resetPasswordData: resetPasswordReducer,
  valuationReducerData: valuationReducer,
  valuationFilterReducerData: valuationFilterReducer,
  DeliveryDataReducerData: DeliveryDataReducer,
  DeliveryDataFilterReducerData: DeliveryDataFilterReducer,
  ForgotPasswordReducerData: forgotPasswordReducer,
  SingleCompanyCommentsReducer,
  UploadDocumentAnalystNotesReducer,
  singleCompanyReducer,
  UploadDocumentReducer,
  SingleCompanyValuationReducer,
  vdrGroupsReducer,
  vdrLevelReducer,
  vdrCompanyReducer,
  vdrHomeReducer,
  vdrUploadImageReducer,
  ipAddressReducer,
  vdrSingleCompanyReducer,
  vdrPDFLinkReducer,
  vdrMediaDataReducer,
  mediaModelReducer,
  vdrData20YearsQuarterlyReducer,
  vdrData20YearsReducer,
  vdrPeerToPeerReducer,
  vdrSortDataReducer,
  vdrSortUpdateDataReducer,
  vdrFavoriteReducer,
  FavoriteCompanyReducer,
  vdrEmployeeReducer,
  AssignEmployeeReducer,
  vdrMediaReducer,
  filesLogReducer,
  PriceActionFilterReducer,
  PriceActionReducer,
  vdrMediaGroupReducer,
  priceIndexMasterReducer,
  dateAPIReducer,
  ForensicReducer,
  MessageReducer,
  GetOmakaraUsersReducer,
  AboutUsUpdateReducer,
  SendNotificationReducer,
  FIIsModelReducer,
  FIIsSectorReducer,
  FIIsTopBottomReducer,
  FIISDateMasterReducer,
  RatioTooltipReducer,
  ForensicTabShowHideReducer,
  ForensicModelCommentsReducer,
  SegmentReducer,
  quarterlyReviewReducer,
  quarterlySegmentReducer,
  dataRoomTickerReducer,
  ForumDetailReducer,
  vdrNotificationReducer,
  singleCompanyNoteReducer,
  QuarterlyResultsReducer,
  QuarterlySnapShotReducer,
  SingleCompProfitAndLossReducer,
  VideoLikeReducer,
  ForumReducer,
  VideoCommentReducer,
  VideoCommentLikeReducer,
  NewsletterLikeReducer,
  NewsletterCommentReducer,
  NewsletterCommentLikeReducer,
  SingleCompCashFlowReducer,
  SingleNewsletterLikeReducer,
  MyBlogListReducer,
  VDRMediaMyFavouriteListReducer,
  SingleCompanyBalanceSheetReducer,
  SingleCompanyratiosReducer,
  SingleCompanypeersReducer,
  DefaultMasterReducer,
  ComparePeerReducer,
  PeerRatiosReducer,
  vdrPeerToPeerScReducer,
  RepositoryListReducer,
  ThemeColorReducer,
  TrendlyneDataReducer,
  BarChartDataReducer,
  ResultDocumentNewReducer,
  ProfitAndLossChartReducer,
  ForumTagsReducer,
  UserLoginReducer,

  ForumInsiderReducer,
  ForumDetail3Reducer,
  
  WatchListReducer,
  ShareholdingReducer,
  BoardOfDirectorDetailReducer,
  WatchListCompanyReducer: watchListCompanyReducer,
  newSubmenu : newSubMenuReducer
});

let initialState = {};

const middleware = [thunk];

const Store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default Store;
