import {
  ALL_USERS_FAIL,
  ALL_USERS_SUCCESS,
  ALL_USERS_REQUEST,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOGOUT,
  ALL_COMPANY_REQUEST,
  ALL_COMPANY_SUCCESS,
  ALL_COMPANY_FAIL,
  ALL_COMPANY_REQUEST_2,
  ALL_COMPANY_SUCCESS_2,
  ALL_COMPANY_FAIL_2,
  CALENDER_REQUEST,
  CALENDER_SUCCESS,
  CALENDER_FAIL,
  COMPANY_MASTER_REQUEST,
  COMPANY_MASTER_SUCCESS,
  COMPANY_MASTER_FAIL,
  SECTOR_REQUEST,
  SECTOR_SUCCESS,
  SECTOR_FAIL,
  INDUSTRY_REQUEST,
  INDUSTRY_SUCCESS,
  INDUSTRY_FAIL,
  TURN_AROUND_REQUEST,
  TURN_AROUND_SUCCESS,
  TURN_AROUND_FAIL,
  RESULT_DATA_REQUEST,
  RESULT_DATA_SUCCESS,
  RESULT_DATA_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  VALUATION_REQUEST,
  VALUATION_SUCCESS,
  VALUATION_FAIL,
  VALUATION_FILTER_REQUEST,
  VALUATION_FILTER_SUCCESS,
  VALUATION_FILTER_FAIL,
  DELIVERY_DATA_FILTER_REQUEST,
  DELIVERY_DATA_FILTER_SUCCESS,
  DELIVERY_DATA_FILTER_FAIL,
  DELIVERY_DATA_REQUEST,
  DELIVERY_DATA_SUCCESS,
  DELIVERY_DATA_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_SUCCESS,
  SINGLE_COMPANY_REQUEST,
  SINGLE_COMPANY_SUCCESS,
  SINGLE_COMPANY_FAIL,
  SINGLE_COMPANY_NOTE_REQUEST,
  SINGLE_COMPANY_NOTE_SUCCESS,
  SINGLE_COMPANY_NOTE_FAIL,
  SINGLE_COMPANY_VALUATION_REQUEST,
  SINGLE_COMPANY_VALUATION_SUCCESS,
  SINGLE_COMPANY_VALUATION_FAIL,
  UPLOAD_DOCUMENT_REQUEST,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAIL,
  UPLOAD_DOCUMENT_ANALYST_REQUEST,
  UPLOAD_DOCUMENT_ANALYST_SUCCESS,
  UPLOAD_DOCUMENT_ANALYST_FAIL,
  SEND_NOTIFICATION_REQUEST,
  SEND_NOTIFICATION_SUCCESS,
  SEND_NOTIFICATION_FAIL,

  COMPANY_COMMENTS_REQUEST,
  COMPANY_COMMENTS_SUCCESS,
  COMPANY_COMMENTS_FAIL,
  ABOUT_US_UPDATE_REQUEST,
  ABOUT_US_UPDATE_SUCCESS,
  ABOUT_US_UPDATE_FAIL,


  
FIIS_MODEL_REQUEST,
FIIS_MODEL_SUCCESS,
FIIS_MODEL_FAIL,
FIIS_SECTORS_REQUEST,
FIIS_SECTORS_SUCCESS,
FIIS_SECTORS_FAIL,
FIIS_TOP_BOTTOM_REQUEST,
FIIS_TOP_BOTTOM_SUCCESS,
FIIS_TOP_BOTTOM_FAIL,
DATE_MASTER_REQUEST,
DATE_MASTER_SUCCESS,
DATE_MASTER_FAIL,
RATIO_TOOLTIP_REQUEST,
RATIO_TOOLTIP_SUCCESS,
RATIO_TOOLTIP_FAIL,
FORENSIC_TAB_SHOW_HIDE_REQUEST,
FORENSIC_TAB_SHOW_HIDE_SUCCESS,
FORENSIC_TAB_SHOW_HIDE_FAIL,
FIIS_MODEL_COMMENT_REQUEST,
FIIS_MODEL_COMMENT_SUCCESS,
FIIS_MODEL_COMMENT_FAIL,
NEW_SUBMENU_REQUEST,
NEW_SUBMENU_SUCCESS,
NEW_SUBMENU_FAIL

} from "../../constants/userConstants";



export const userReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        isLoggedIn: true
      };

    case ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const userLoginReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        isLoggedIn: true
      };

    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isLoggedIn: false
      };
    case USER_LOGOUT:
      return {
        ...state,
        loading: false,
        error: null,
        isLoggedIn: false
      };

    default:
      return state;
  }
};

export const resetPasswordReducer = (state = {
  data: [],
  isLoggedIn: true
}, action) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        isLoggedIn: true
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload?.msg,
        error: null,
        isLoggedIn: false
      };

    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        msg: null,
        data: [],
        isLoggedIn: true
      };

    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = { data: [], loading: true, r_status: false }, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        msg: action.payload?.msg,
        error: null,
        r_status: true,
      };

    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
        data: [],
        r_status: false,
      };

    default:
      return state;
  }
};


export const valuationReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case VALUATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case VALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload.Data.length,
      };

    case VALUATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const singleCompanyReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case SINGLE_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SINGLE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload.Data.length,
      };

    case SINGLE_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const singleCompanyNoteReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case SINGLE_COMPANY_NOTE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SINGLE_COMPANY_NOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload.Data.length,
      };

    case SINGLE_COMPANY_NOTE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const UploadDocumentReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPLOAD_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload.Data.length,
      };

    case UPLOAD_DOCUMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const UploadDocumentAnalystNotesReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case UPLOAD_DOCUMENT_ANALYST_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPLOAD_DOCUMENT_ANALYST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload.Data.length,
      };

    case UPLOAD_DOCUMENT_ANALYST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const SendNotificationReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case SEND_NOTIFICATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SEND_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload.Data.length,
      };

    case SEND_NOTIFICATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const SingleCompanyCommentsReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case COMPANY_COMMENTS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPANY_COMMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload.Data.length,
      };

    case COMPANY_COMMENTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const SingleCompanyValuationReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case SINGLE_COMPANY_VALUATION_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SINGLE_COMPANY_VALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        // totalRows: action.payload.Data.length,
      };

    case SINGLE_COMPANY_VALUATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const DeliveryDataReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case DELIVERY_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELIVERY_DATA_SUCCESS:
      // console.log('action.payload.Data >>>> '+JSON.stringify(action.payload));
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRows: action.payload.Data.length,
      };

    case DELIVERY_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const DeliveryDataFilterReducer = (state = { loading: true, data: [] }, action) => {
  switch (action.type) {
    case DELIVERY_DATA_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELIVERY_DATA_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case DELIVERY_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const valuationFilterReducer = (state = { loading: true, data: [], totalRows: 0 }, action) => {
  switch (action.type) {
    case VALUATION_FILTER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case VALUATION_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case VALUATION_FILTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


export const allCompanyReducer = (state = { loading: true, data: [] }, action) => {
  switch (action.type) {
    case ALL_COMPANY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ALL_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ALL_COMPANY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const allCompanyReducer2 = (state = { data2: [] }, action) => {
  switch (action.type) {
    case ALL_COMPANY_REQUEST_2:
      return {
        ...state,
        loading2: true,
      };

    case ALL_COMPANY_SUCCESS_2:
      return {
        ...state,
        loading2: false,
        data2: action.payload,
      };

    case ALL_COMPANY_FAIL_2:
      return {
        ...state,
        loading2: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const calenderReducer = (state = { loading: true, data: [] }, action) => {
  switch (action.type) {
    case CALENDER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case CALENDER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case CALENDER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const companyMasterReducer = (state = { loading: true, totalRow: 0, data: [] }, action) => {
  switch (action.type) {
    case COMPANY_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case COMPANY_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        totalRow: action.payload.length,
      };

    case COMPANY_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const sectorMasterReducer = (state = { loading: true, data: [] }, action) => {
  switch (action.type) {
    case SECTOR_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SECTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case SECTOR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const AboutUsUpdateReducer = (state = { loading: true, data: [] }, action) => {
  switch (action.type) {
    case ABOUT_US_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ABOUT_US_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case ABOUT_US_UPDATE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const industryMasterReducer = (state = { loading: true, data: [] }, action) => {
  switch (action.type) {
    case INDUSTRY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case INDUSTRY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case INDUSTRY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const turnAroundMasterReducer = (state = { loading: true, data: [] }, action) => {
  switch (action.type) {
    case TURN_AROUND_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TURN_AROUND_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case TURN_AROUND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};




export const resultFilterInputReducer = (state = { data: [] }, action) => {
  switch (action.type) {
    case RESULT_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RESULT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case RESULT_DATA_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};




export const FIIsSectorReducer = (state = { data: [], loading:true }, action) => {
  switch (action.type) { 
    case FIIS_SECTORS_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FIIS_SECTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case FIIS_SECTORS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const FIIsModelReducer = (state = { data: [], loading:true }, action) => {
  switch (action.type) { 
    case FIIS_MODEL_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FIIS_MODEL_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case FIIS_MODEL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const FIIsTopBottomReducer = (state = { data: [], loading:true }, action) => {
  switch (action.type) { 
    case FIIS_TOP_BOTTOM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FIIS_TOP_BOTTOM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case FIIS_TOP_BOTTOM_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const FIISDateMasterReducer = (state = { data: [], loading:true }, action) => {
  switch (action.type) { 
    case DATE_MASTER_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DATE_MASTER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case DATE_MASTER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const RatioTooltipReducer = (state = { data: [], loading:true }, action) => {
  switch (action.type) { 
    case RATIO_TOOLTIP_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case RATIO_TOOLTIP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case RATIO_TOOLTIP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const ForensicTabShowHideReducer = (state = { data: [], loading:true }, action) => {
  switch (action.type) { 
    case FORENSIC_TAB_SHOW_HIDE_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case FORENSIC_TAB_SHOW_HIDE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case FORENSIC_TAB_SHOW_HIDE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


export const ForensicModelCommentsReducer = (state = { data: [], loading:true }, action) => {
  switch (action.type) { 
    case FIIS_MODEL_COMMENT_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
      };

    case FIIS_MODEL_COMMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case FIIS_MODEL_COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};


// new submenu
export const newSubMenuReducer = (state = { loading: true, data: [], error: null }, action) => {
  switch (action.type) {
    case NEW_SUBMENU_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case NEW_SUBMENU_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,

      };
    case NEW_SUBMENU_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};