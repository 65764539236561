import { loggedInUser } from "../constants/helpers";
import { request as https, requestPost } from "./request";

// console.log('loggedInUser >>> ', loggedInUser);


let baseUrl1 = 'https://omkaradata.in/api';
if((window.location.href).includes('omkaradata.com') || (window.location.href).includes('omkaracapital.in')){
  baseUrl1 = 'https://omkaradata.com/api'; // FOR https://vdr.omkaracapital.net/ OR omkaradata.com
}
if( window.location.host != 'omkaradata.in' &&  window.location.host != 'omkaradata.com'){
  baseUrl1 = "https://vasudeep.com:8084/"+baseUrl1;
}   

// baseUrl1 = "https://vasudeep.com:8084/"+baseUrl1;
// if(loggedInUser && loggedInUser?.user_id == 141){ }
// baseUrl1 = "https://vasudeep.com:8084/"+baseUrl1;

export const baseUrl = baseUrl1;

// export const baseUrl = "http://www.omkaracapital.co:8084/https://omkaradata.in/api"
// export const baseUrl = "https://omkaradata.com/api"
// export const baseUrl = "http://www.omkaracapital.co:8084/https://omkaradata.com/api"

export const GetUsers = ({ limit }) => {
  return https.get(`http://127.0.0.1:8000/api/test`);
};


export const userLogin = ({userName, password}) => {
  const params = {UserId:userName, password:password};
  return requestPost.post(`${baseUrl}/SignIn`, params);
};


export const omkaraUserListRequest = (params) => {
  return requestPost.post(`https://omkaracapital.in/api/get-subscription-user`, params);
};


export const ResultDocumentRequest = (params) => {
  return requestPost.post(`${baseUrl}/ResultDocument`, params);
};


export const forgotPassword = ({userName, password}) => {
  const params = [{userid:userName}];
  return requestPost.post(`${baseUrl}/ForgetPassword`, params);
};


export const resetPasswordRequest = (filterArray) => {
  const params = filterArray;
  // console.warn('params >> ',params)
  return requestPost.post(`${baseUrl}/ChangePassword`, params);
};


export const valuationDataRequest = (filterArray) => {
  const params = filterArray;
  return requestPost.post(`${baseUrl}/ValuationData`, params);
};

export const DeliveryDataRequest = (filterArray) => {
  const params = filterArray;
  return requestPost.post(`${baseUrl}/DeliveryVolume`, params);
};

export const allCompanyDataRequest = (filterArray) => {
  const params = filterArray;
  // console.warn('params >> ',params)
  return requestPost.post(`${baseUrl}/resultdata`, params);
};


export const allCompanyData2Request = (filterArray) => {
  const params = filterArray;
  // console.warn('params >> ',params)
  return requestPost.post(`${baseUrl}/resultdatasheet2`, params);
};


export const singleCompanyRequest = (params) => {
  // const params = filterArray;
  // console.warn('params >> ',params)
  return requestPost.post(`${baseUrl}/resultdatasinglecompany`, params);
};

export const singleCompanyNoteRequest = (params) => {
  // return requestPost.post(`https://vasudeep.com:8084/https://omkaradata.in//api/companynote`, params);
  return requestPost.post(`${baseUrl}/companynote`, params);
};

export const SingleCompanyValuationRequest = (params) => {
  return requestPost.post(`${baseUrl}/SingleCompanyValuationData`, params);
};
export const SingleCompanyValuationNewRequest = (params) => {
  return requestPost.post(`${baseUrl}/ValuationData_New`, params);
};

export const MultipleFileUploaderRequest = (params) => {
  return requestPost.post(`${baseUrl}/MultipleFileUploader`, params);
};

export const SendNotificationRequest = (params) => {
  return requestPost.post(`https://omkaracapital.in/api/sent_notification`, params);
};

export const MultipleAnalystNotesUploaderRequest = (params) => {
  return requestPost.post(`${baseUrl}/MultipleUploadDocumentAnalystNotes`, params);
};

export const UploadDocumentRequest = (params) => {
  return requestPost.post(`${baseUrl}/UploadDocument`, params);
};

export const UploadDocumentAnalystNotesRequest = (params) => {
  return requestPost.post(`${baseUrl}/UploadDocumentAnalystNotes`, params);
};

export const SingleCompanyCommentsRequest = (params) => {
  return requestPost.post(`${baseUrl}/SingleCompanyComments`, params);
};

export const SingleCompanyAboutUsUpdateRequest = (params) => {
  return requestPost.post(`${baseUrl}/SingleCompanyAboutUsUpdation`, params);
};


export const calenderRequest = (params) => {
  return requestPost.post(`${baseUrl}/ResultCalender`, params);
};


export const allCompanyMasterRequest = (params) => {  
  return https.get(`${baseUrl}/SymbolMasterAllSymbols`, params);
};

export const companyMasterRequest = (params) => {  
  return https.get(`${baseUrl}/symbolmaster`, params);
};

export const sectorMasterRequest = (params) => {  
  return https.get(`${baseUrl}/sectormaster`, params);
};

export const industryMasterRequest = (params) => {  
  return https.get(`${baseUrl}/industrymaster`, params);
};

export const turnAroundMasterRequest = (params) => {  
  return https.get(`${baseUrl}/turnaroundfilter`, params);
};

export const FIIsSectorsRequest = (params) => {
  return requestPost.post(`${baseUrl}/FIIsSectors`, params);
};
export const FIIsModelRequest = (params) => {
  return requestPost.post(`${baseUrl}/FIIsModel`, params);
};
export const FIISDateMasterRequest = (params) => {
  return requestPost.post(`${baseUrl}/FIISDateMaster`, params);
};
export const FIIsTopBottomRequest = (params) => {
  return requestPost.post(`${baseUrl}/FIIsTopBottom`, params);
};

export const RatioTooltipRequest = (params) => {
  return requestPost.post(`${baseUrl}/ForensicTooltip`, params);
};

export const ForensicModelCommentsRequest = (params) => {
  return requestPost.post(`${baseUrl}/ForensicModelComments`, params);
};

export const ForensicTabShowHideRequest = (params) => {
  return requestPost.post(`${baseUrl}/ForensicTabShowHide`, params);
};

// request new submenu api
export const New_SubMenu = () => {
  return https.get(`${baseUrl}/Resources`);
};