import { Box, Checkbox, Divider, Tooltip, FormControlLabel, FormGroup, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, Button, Modal } from '@mui/material';
import { borderRadius, margin } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { LoadingComponent } from '../../components';
import { BoardOfDirectorDetailAction, vdrForensicAction } from '../../redux/actions/VdrAction';
import ForensicItemPieChart from './ForensicItemPieChart';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import HelpIcon from '@mui/icons-material/Help';
import { TbArrowBigDown, TbArrowBigTop } from "react-icons/tb";
import PieChart from './CustomChart/PieChart'
import { RatioTooltipAction } from '../../redux/actions/userAction';
import { GlobalContext } from '../../context/GlobalContext';
import { ForensicSectionModal } from './ForensiSectionModal';
import { IsDateShow } from '../../constants/helpers';
import { BiEdit } from 'react-icons/bi';


function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}


const ForensicItemTableCapital = (props) => {
  // console.table(props);
  const { setInputType, ActivePrimaryTab, setActivePrimaryTab, title, companyId, typeData, setParentGroup, setOpenForm, toggleDrawer, dataFor, ActivePrimaryBtn,childFor,BtnStatus,setBtnStatus ,setActivePrimaryBtn,secondaryBtnArr} = props;

  console.log("typeData>>",typeData);
  const dispatch = useDispatch();

  const {
    loading:loading_forensic,
    firstLoading,
    data:data_forensic,
    msg,
    error
  } = useSelector(state => state.ForensicReducer);
  const {
    loading:RatioTooltipLoading,
    data:RatioTooltipData
  } = useSelector(state => state.RatioTooltipReducer);

  const [capitalSegmentType, setCapitalSegmentType] = useState('Consolidated');

  const { data: companyData, loading: companyLoading } = useSelector((state) => state.vdrSingleCompanyReducer);
  const { loading: ForensicModelCommentsLoading } = useSelector((state) => state.ForensicModelCommentsReducer);

  const [tableColumns, setTableColumns] = React.useState(null);
  const [tableBodyData, setTableBodyData] = React.useState(null);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const [checked, setChecked] = React.useState(false);
  const [loadagain,setLoadagain] =React.useState(true);

  const showHide = (event) => {
    setChecked(event.target.checked);
  };

    const {
        data:ForensicTabShowHide,
        loading:ForensicTabShowHideLoading
    } = useSelector(state=>state.ForensicTabShowHideReducer);
  useEffect(() => {
    console.log("Hello.......")

    const params = {
      "companyID": companyId || companyData.CompanyID,
      "Type": typeData,
      "dataFor": ActivePrimaryBtn?.short_name,
      "childType":childFor
    }
    console.log("BM....",params)
    dispatch(vdrForensicAction(params));

    if(typeData == 'ratios'){
      dispatch(RatioTooltipAction({"Type": "ratios"}))
    }

  }, [ForensicTabShowHideLoading, ForensicModelCommentsLoading])


  const filterTooltip = (type="") => {

    let ddd = RatioTooltipData.Data && RatioTooltipData.Data[0].ToolTip.filter((item)=>item.key == type);

    // console.log(ddd);
    return (ddd && ddd[0].toolTip);

  }


  // const callApi = () => {

  //   // let pType = 'con';
  //   //     setP_Type(pType)

  //   // if(QuarterlySegmentType === "Standalone"){
  //   //   pType = 'std';
  //   //   setP_Type('std')
  //   // }
  //   // // pType = 'std';

  //   // let params =  {
  //   //   "CompanyId":companyId,
  //   //   "type":pType
  //   // }
  //   //  console.log('params started >>>> ', params)
  //   // dispatch(QuarterlyResultsAction(params))
  //   console.log("Active primary button>>",ActivePrimaryBtn);


  //   const params = {
  //     "companyID": companyId || companyData.CompanyID,
  //     "Type": typeData,
  //     "dataFor": ActivePrimaryBtn?.short_name,
  //     "childType":childFor
  //   }
  //   console.log("BM load again....",params)
  //   dispatch(vdrForensicAction(params));



  // }

  // useEffect(() => {
  //   // setTableColumns([])
  //   // setTableBody([])
  //   // if(capitalSegmentType=="Consolidated")
  //   //   callApi();

  // }, [capitalSegmentType])


  useEffect(() => {

    console.log("data_forensic>>>",data_forensic)
    if (!loading_forensic && data_forensic)
    {
      console.log("ActivePrimaryBtn?.short_name>>",ActivePrimaryBtn?.short_name)
      // let xx=data_forensic?.button_status[ActivePrimaryBtn?.short_name]
      // console.log("btn status>>",xx)
      //  console.log("data_forensic>>",data_forensic)
      console.log("data_forensic?.button_status>>",data_forensic?.button_status)

       if(data_forensic?.button_status!=null && data_forensic?.button_status['con']==false){
            setActivePrimaryBtn(secondaryBtnArr[1])
            
            if(ActivePrimaryBtn?.short_name != "std"){
              const params = {
                "companyID": companyId || companyData.CompanyID,
                "Type": typeData,
                "dataFor": "std",
                "childType":childFor
              }
              // console.log("BM....",params)
              dispatch(vdrForensicAction(params));
            }

       }
      //    if(loadagain)
      //    {
      //   setLoadagain(false)
      //   setCapitalSegmentType("Standalone")
      //    }
      // }
      // let x={
      //   "con":false,
      //   "std":true
      // }

      // if (x.con==false)
      // {
      //   setActivePrimaryBtn(secondaryBtnArr[1])


      // }
      // else if (x.con==true)
      // {
      //   setActivePrimaryBtn(secondaryBtnArr[0])

      // }
      setBtnStatus(data_forensic?.button_status)
    }
  }, [loading_forensic])


  // useEffect(() => {
    
  // }, [ActivePrimaryBtn])
  

  // console.log(companyData);



  const {
    IsCompanyDetail,
    DIR_Model,
    setDIR_Model,
    SelectedDIR,
    setSelectedDIR
  } = useContext(GlobalContext);



  // const [open, setOpen] = React.useState(false);
  const handleOpen = () => setDIR_Model(true);
  const handleClose = () => setDIR_Model(false);


  const { data: dataAPIData, loading: dataAPILoading } = useSelector((state) => state.dateAPIReducer);

  const divData = () => {


    return (
      <>

        {
          typeData=="CAP" &&  data_forensic && data_forensic.Data && data_forensic.Data.length > 0 && data_forensic.Data.map((res, i) => {

            if (i != 1 && typeData == "CF" || typeData != "CF") {

              const itemDetails = res.details.length > 0 ? res.details[0] : {};
              let highlights = res.details.length > 0 ? res.details[0].highlight : {};

              let a1 = 0;
              let tableHead = res.mheader;
              tableHead = tableHead;
              console.log("TableHead>>>",tableHead);
              var mColArr = [];
              tableHead.map((resHeads) => {
                console.log("resHeads>>>",resHeads?.Column);

                var hideCheck = false;
                if (a1 !== 1) {
                  var hideCheck = true;
                }
                var stickyLeft = 0;

                var stuff = {};
                resHeads= resHeads?.Column
                Object.keys(resHeads).forEach((key, i0) => {

                  console.log("key==>>",key);

                  var label = resHeads[key];
                  console.log("bm:label>>>",label)
                  var show_status = resHeads[key];
                  console.log("bm:show_status>>>",show_status)
                  if (label != null && label != "") {
                    var width = 120;
                    var sticky = false;
                    if (key == 'Company_Name' || key == 'Sector' || key == 'Industry') {
                      width = 150;
                      sticky = true;

                      hideCheck = false;
                    } else {
                      hideCheck = true;
                    }

                    if (key != '$id' && key != 'AccordCode') {


                      // var a0 = { key:key, show_status:true };
                      // a00.push(a0);
                      stuff[key] = true;
                      var mCol = {
                        id: key,
                        label: label.name,
                        bgcolor:label.bgcolor,
                        isBold:label.isBold,
                        isItalic:label.isItalic,
                        isLine:label.isLine,
                        textcolor:label.textcolor,
                        stickyLeft: stickyLeft,
                        minWidth: width,
                        maxWidth: width,
                        align: 'canter',
                        hideCheck: hideCheck,
                        sticky: sticky,
                      }
                      mColArr.push(mCol);
                    }
                  }
                  a1++;
                })
              });
              console.log("mcolarray>>>",mColArr);


              {/* const tableBody = res?.TableData; */}
              const tableBody = res?.row;

              var allRowsData = [];
              tableBody.map((resBody) => {

                allRowsData.push(resBody);

              });

              console.log("allRowsData>>",allRowsData)


              if (i == 0 && typeData == "CF") {
                  const data3 = data_forensic.Data[1]?.TableData;
                if(data3){

                  data3.map((resBody) => {
                  var singleRow ={};
                  Object.keys(resBody).forEach(key => {
                    let col_val = resBody[key];

                    singleRow.push(col_val);
                  })
                  allRowsData.push(singleRow);
                  });
                }
              }


              {/* console.log(res.ApplicationPie);  */}
              {/*
              */ }
              {/* console.log(i); */}
              

              return (
                <React.Fragment key={i} >
                <div style={{ display: !checked && i == 2 && typeData == "CF" ? 'none': 'unset' }} >
                  <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 3, padding: 1 }}>
                    <Box>
                      {/* <Typography variant="subtitle1" fontWeight='bold' >
                        {itemDetails?.title}
                      </Typography> */}
                      
                      <Typography sx={{ marginTop: 1 }} variant="title" dangerouslySetInnerHTML={{ __html: itemDetails?.description }} />
                    </Box>

                    <Grid container>
                      <Grid sm={res.sourcePie.length == 0 && res.ApplicationPie.length == 0 ? (typeData == 'ratios' ? 12 : 12) : 5}>
                        <TableContainer>
                          <Table className='custom-table forensic-table' size={'small'} stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                {mColArr.map((column, clm) => {



                                  let cStyle = {}
                                          // if(column?.isBold){
                                            cStyle = {
                                              ...cStyle,
                                              fontWeight: (column?.isBold && "500"),
                                              fontStyle:column?.isItalic ? "italic" : "",
                                              color: column?.textcolor != "" ? column?.textcolor :  '#22222F',
                                              backgroundColor: column?.bgcolor  && "",
                                            }
                                          // }


                                  if(column.id !== 'column_1' && clm !== 0){
                                    cStyle['textAlign'] = 'center';
                                  }

                                  return (
                                  <TableCell
                                    style={cStyle}
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column?.label}
                                  </TableCell>
                                )})}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {allRowsData.map((row, is) => {

                                // console.log("Javin Row>>",row)
                                      row = row?.Column;

                                let lastR = false;
                                return (
                                  <TableRow role="checkbox" tabIndex={-1} key={is}>
                                    {mColArr.map((column, c1) => {


                                      const value = row[column?.id];
                                      console.log("Javin Value>>",value?.textcolor)

                                  let cStyle2 = {}
                                          // if(value?.isBold){
                                            cStyle2 = {
                                              ...cStyle2,
                                              fontWeight: (value?.isBold && "500"),
                                              fontStyle:value?.isItalic ? "italic" : "",
                                              color: value?.textcolor != "" ? value?.textcolor : '#22222F',
                                              backgroundColor: value?.bgcolor  && "",
                                            }
                                          // }

                                      if(c1 > 0){
                                        cStyle2['textAlign'] = "center"
                                        {/* cStyle2['height'] = 40 */}
                                      }


                                      {/* console.log(lastR); */}
                                      return (
                                        <TableCell style={cStyle2} key={column?.id} align={column?.align}>
                                          {
                                            value?.name === " "
                                              ?
                                              <>
                                                <div className="blankCell"></div>
                                              </>
                                              :
                                              <>
                                                {
                                                  <>
                                                  {column.format && typeof value?.name === 'number'
                                                  ? column.format(value?.name)
                                                  : value?.name}

                                                  </>
                                                }


                                            {
                                              lastR === true
                                              ?
                                              <>
                                                  {
                                                     value < 10 ?
                                                      <TbArrowBigTop fontSize='18' style={{ marginLeft:5, position:'relative', top:2, fill:'#9beb8d' }} />
                                                    :
                                                      <TbArrowBigDown fontSize='18' style={{ marginLeft:5, position:'relative', top:2, fill:'#ffa3a3' }} />
                                                  }
                                              </>
                                              :
                                              null
                                            }


                                              </>
                                          }
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        <Grid container spacing={2} style={{}}>
                          <Grid item xs={12}>
                            <Box
                              sx={{
                                marginTop: 1,
                                display: 'flex',
                                justifyContent: 'center',
                              }}
                            >
                              {
                                highlights && highlights.length > 0 && highlights.map((highlight, i00) => {
                                  {/* console.log('highlight >> ', highlight) */}
                                  return (
                                    <Box sx={{
                                      backgroundColor: '#c7dbb0',
                                      borderRadius: '4px',
                                      border: '1px solid #ddd',
                                      margin: '0.5rem',
                                      textAlign: 'center',
                                    }}>
                                      <div style={{
                                        padding: '0.5rem 1rem',
                                      }}
                                      >
                                        <Typography fontSize={12} fontWeight={'600'} variant="subtitle1"  >
                                          {highlight?.title}
                                        </Typography>
                                        <Typography fontSize={12} fontWeight={'600'} variant="subtitle2"  >
                                          {highlight?.subtitle}
                                        </Typography>
                                      </div>
                                      <div style={{
                                        backgroundColor: (highlight?.arrow == 'Up' && highlight?.value > 50 ? '#9beb8d' : highlight?.arrow == '' ? '#fff' : '#ffa3a3'),
                                        borderTop: '1px solid',
                                        padding: '0.5rem 1rem',
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent:'center',
                                      }}>
                                        <Typography fontSize={13} sx={{ marginTop:.5 }} variant="subtitle2" fontWeight='bold' >
                                          {highlight?.value}
                                        </Typography>
                                        {
                                          highlight?.arrow == 'Up' && highlight?.value > 50 ?
                                            <TbArrowBigTop fontSize='18' style={{ marginLeft:5, fill:'#9beb8d' }} />
                                          :
                                            <>
                                            {
                                              highlight?.arrow == ""
                                              ?
                                              <></>
                                              :
                                              <TbArrowBigDown fontSize='18' style={{ marginLeft:5, fill:'#ffa3a3' }} />

                                            }
                                            </>
                                        }

                                      </div>
                                    </Box>
                                  )
                                })
                              }
                            </Box>
                          </Grid>
                        </Grid>

                        {
                          itemDetails?.title != "Cash Flow" && (
                            <Box sx={{
                              marginTop:1
                             }}>
                              <Box style={{ display: 'flex', justifyContent:'space-between', alignItems: 'center' }}>
                              <Typography variant='h6' sx={{ fontWeight:'800', fontSize:15 }}>Comment</Typography>
                              {
                                IsCompanyDetail && (
                                  <Button onClick={(e)=>{ 
                                    
                                    setParentGroup({...(res.Comment[0]), Type:itemDetails?.title, capType: ActivePrimaryTab?.title, typeData, companyId });
                                    setInputType({...(res.Comment[0]), Type:itemDetails?.title, title, capType: ActivePrimaryTab?.title, typeData, companyId });
                                    toggleDrawer('bottom', true)(e);
                                    setOpenForm('ForensicCommentForm'); }}
                                    size="small" 
                                    sx={{ fontSize:10, color:'#333' }}
                                    variant="text"
                                  ><BiEdit /> &nbsp; Edit</Button>
                                )
                              }
                              </Box>
                              <Divider sx={{
                                marginY:1
                               }}/>
                              <Typography variant='span' style={{ fontSize:'14px' }} dangerouslySetInnerHTML={{
                                __html: res.Comment[0] ? res.Comment[0].description : ""
                              }} />
                            </Box>
                          )
                        }

                        {
                            typeData == "CF" && i == 0
                            ?
                            <>
                              <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox checked={checked} onChange={showHide} name="gilad" />
                                    }
                                    label="Show/Hide"
                                  />
                              </FormGroup>
                            </>
                            :
                            null
                          }
                      </Grid>
                      {
                        res.sourcePie.length > 0 && res.ApplicationPie.length > 0
                        ?
                        <>
                      <Grid md={7} sx={{ paddingLeft: 1 }} >
                        <Box>
                          <Grid container >
                            {
                              res.sourcePie.length > 0
                                ?
                                <>
                                  <Grid sm={6} sx={{ padding: '0.5rem' }}>
                                    <Box style={{ border: '1px solid #ddd', borderRadius: '5px', overflow: 'hidden' }} >
                                    <PieChart  ChartTitle="Sources" chartData={res.sourcePie} />
                                      {/* <ForensicItemPieChart ChartTitle="Sources" chartData={res.sourcePie} /> */}
                                    </Box>
                                  </Grid>
                                </>
                                :
                                null
                            }
                            {
                              res.ApplicationPie.length > 0
                                ?

                                <>
                                  <Grid sm={6} sx={{ padding: '0.5rem' }}>
                                    <Box style={{ border: '1px solid #ddd', borderRadius: '5px', overflow: 'hidden' }} >
                                        <PieChart  ChartTitle="Application" chartData={res.ApplicationPie} />
                                      {/* <ForensicItemPieChart ChartTitle="Application" chartData={res.ApplicationPie} /> */}
                                    </Box>
                                  </Grid>
                                </>
                                :
                                null
                            }


                          </Grid>
                        </Box>
                      </Grid>

                        </>
                        :
                        null
                      }
                    </Grid>

                  </Paper>
                </div>

                {
                          itemDetails?.title == "Cash Flow" && res?.Comment?.length > 0 && (
                            <Box sx={{
                              marginTop:1
                             }}>
                              <Typography variant='h6' sx={{ fontWeight:'800', fontSize:15 }}>Comment</Typography>
                              <Divider sx={{
                                marginY:1
                               }}/>
                              <Typography dangerouslySetInnerHTML={{
                                __html: res.Comment[0].description
                              }} />
                            </Box>
                          )
                        }

                </React.Fragment>
              )
            }
          })
        }

      </>
    )
  }




  return (
    <>

  {
    SelectedDIR && (
      <ForensicSectionModal typeData={typeData} companyId={companyId} handleOpen={handleOpen} handleClose={handleClose} open={DIR_Model} setOpen={setDIR_Model} />
    )
  }

      <Box>
        {/* <h2>{title}123</h2> */}
        {
          loading_forensic ? <LoadingComponent /> : <>
            {divData()}
          </>
        }
      </Box>
    </>
  )
}

export default ForensicItemTableCapital