import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PieChart from "./CustomChart/PieChart";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import ForensicItemTable from "./ForensicItemTable";
import { useDispatch, useSelector } from "react-redux";
import { ForensicTabShowHideAction } from "./../../redux/actions/userAction";
import { vdrForensicAction } from "../../redux/actions/VdrAction";
import CapitalStructure from "./CapitalStructure";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


const ForensicComponent = (props) => {
  const { companyId, setOpenForm, OpenForm, toggleDrawer, setParentGroup, dataFor, inputType, setInputType } =
    props;
  const [value, setValue] = React.useState(0);

  const { data: companyData, loading: companyLoading } = useSelector(
    (state) => state.vdrSingleCompanyReducer
  );

  console.log('props ?>?>?>??>>?>?>?   22', props)



  let secondaryBtnArr = [
    {
      id: 1,
      label: "Consolidated",
      value: "Consolidated",
      short_name: "con",
      show_in: [1, 2, 3, 4],
    },
    {
      id: 2,
      label: "Standalone",
      short_name: "std",
      value: "Standalone",
      show_in: [1, 2, 3, 4],
    },
  ];


  const [ActivePrimaryBtn, setActivePrimaryBtn] = React.useState(secondaryBtnArr[0]);

  const handleChange = (event, newValue) => {
    console.log('newValue ??? ', newValue)
    setValue(newValue);
  };
  const { data: ForensicTabShowHide, loading: ForensicTabShowHideLoading } =
    useSelector((state) => state.ForensicTabShowHideReducer);
  const dispatch = useDispatch();
  const r_companyId = companyId || companyData.CompanyID;

  useEffect(() => {
    dispatch(ForensicTabShowHideAction({ companyID: r_companyId }));
  }, [dispatch]);








  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 4, borderColor: "divider" }}>
          <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {ForensicTabShowHide?.Data &&
              ForensicTabShowHide?.Data.filter((item) => item.is_display).map(
                (item, index) => {
                  // console.log(item)
                  // if((item?.type).toLocaleLowerCase() != "cap".toLocaleLowerCase()){

                    return (
                      <Tab
                        sx={{ fontSize: "0.75rem" }}
                        key={index}
                        label={item.title}
                        {...a11yProps(index)}
                      />
                    );
                  // }
                }
              )}
          </Tabs>
        </Box>

        {ForensicTabShowHide?.Data &&
          ForensicTabShowHide?.Data.filter((item) => item.is_display).map(
            (item, index) => {
              return (
                <TabPanel key={index} value={value} index={index} sx={{ borderBottom: 2, borderColor: "red" }}>
                  <>
                    {item.type === "CAP" ? (
                      <div>
                       <CapitalStructure setActivePrimaryBtn={setActivePrimaryBtn} secondaryBtnArr={secondaryBtnArr} ActivePrimaryBtn={ActivePrimaryBtn} companyId={companyId} companyData={companyData} ForensicItemTable={ForensicItemTable} dataFor={dataFor} setParentGroup={setParentGroup} setOpenForm={setOpenForm} toggleDrawer={toggleDrawer}  title={item.title} typeData={item.typeData} r_companyId={r_companyId} inputType={inputType}
                    setInputType={setInputType}/>

                      </div>
                    ) :
                    <ForensicItemTable
                    inputType={inputType}
                    setInputType={setInputType}
                    dataFor={dataFor}
                    setParentGroup={setParentGroup}
                    setOpenForm={setOpenForm}
                    OpenForm={OpenForm}
                    toggleDrawer={toggleDrawer}
                    companyId={r_companyId}
                    title={item.title}
                    typeData={item.type}
                    ActivePrimaryBtn={ActivePrimaryBtn}
                  />

                    }
                  </>

                </TabPanel>
              );
            }
          )}
      </Box>
    </>
  );
};

export default ForensicComponent;
