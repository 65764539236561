import { Box, CircularProgress, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import InCr from "./InCr";
import { useDispatch, useSelector } from "react-redux";
import { ShareholdingAction, vdrForensicAction } from "../../../redux/actions/VdrAction";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { BsFillBarChartFill } from "react-icons/bs";
import { IsDateShow } from "../../../constants/helpers";

const ShareholdingPatternComp = (props) => {
  const { companyId, isCD } = props;

  const {
    loading,
    firstLoading,
    data,
    msg,
    error
  } = useSelector(state => state.ShareholdingReducer);


  const { data: dataAPIData, loading: dataAPILoading } = useSelector((state) => state.dateAPIReducer);

  const showDiv = (e, index) => {
    let a0 = PlusIcons;
    a0 = {
      ...a0,
      [`childTable_${index}`]: (!PlusIcons[`childTable_${index}`])
    }
    
    // console.log(PlusIcons, 'index >> ', index, PlusIcons[`childTable_${index}`], (!PlusIcons[`childTable_${index}`]));


    setPlusIcons(a0);
  }
  
const showChart = (row, level=1)=>{
  // console.log(row);
  let newRow = {};
if(level == 1){
  // delete row['childTable']
  newRow = row
}else{
  Object.keys(row).map((item)=>{
      let a0 = {
          bg_color: "#ccdbf5",
          isBold: true,
          isItalic: false,
          label: row[item],
          text_color: "#000",
        
      }
      newRow = {...newRow, [item]: a0}
      
  })
  
}
  dispatch({
    type:"Columns_Rows",
    payload:{ columns:TableColumns, rows:newRow, isPercentage: true }
  })
  
}

  
  const [QuarterlySegmentType, setQuarterlySegmentType] = useState([]);
  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);
  const [ActivePrimaryBtn, setActivePrimaryBtn] = useState(1);
  const [FirstLoad, setFirstLoad] = useState(true);
  const [PlusIcons, setPlusIcons] = useState({});
  


  const dispatch = useDispatch();

  let typeData = "SH";
  useEffect(() => {
    if(loading){
      const params = {
        "companyID": companyId,
        "Type": typeData
      }
      dispatch(ShareholdingAction(params));
    }
    
  }, [dispatch, props])
  useEffect(() => {
    if(!loading){
      
      
      
      // if(data.header && data.header.length > 0 && data?.button_status[P_Type] === true){
      if(data.header && data.header.length > 0 ){
        let cols = []
        let firstObj = data.header[0];
        let a0 = firstObj.row;
        Object.keys(a0).map((item, i)=>{
          if(i > 0){
            let dd = {
                "id": item,
                "width": "",
                "align": "",
                "bg_color": firstObj?.bg_color,
                "isItalic": firstObj?.isItalic,
                "isBold": firstObj?.isBold,
                "text_color": firstObj?.text_color,
                "label": a0[item]
              }
            cols.push(dd);
          }
        })
        setTableColumns(cols)
      }else{
        if(FirstLoad){
          setFirstLoad(false);
          setActivePrimaryBtn(2);
          setQuarterlySegmentType('Standalone')
        }
      }
      // if(data.Data && data.Data.length && data?.button_status[P_Type] === true){
      if(data.Data && data.Data.length ){
        let dataA = []
        let plusIcon = {};
        let a00 = data.Data;
        // console.log('a00 >>> ', a00)
        a00.map((item, i)=>{
          let cData = item?.row;
          let childObj = {}
          Object.keys(cData).map((item0, i0)=>{
            if(i0 > 0){
              let data = {
                "label":cData[item0],
                "bg_color":item['bg_color'],
                "isItalic":item['isItalic'],
                "isBold":item['isBold'],
                "text_color":item['text_color'],
              };
              childObj = {...childObj, [`Column${i0}`]: data}
            }
          })
          if(item?.childTable.length > 0){
            plusIcon = {...plusIcon, [`childTable_${i}`]: false}
          }
          childObj = {...childObj, [`childTable`]: (item?.childTable || [])}
          dataA.push(childObj)
        })
        setPlusIcons(plusIcon);
        setTableBody(dataA)
      }


    }
    
  }, [dispatch, loading])
  
if(loading){
  return (
    <> 
      <CircularProgress />
    </>
  )
}
  

  return (
    <>
      {/* <button className='btn primary-btn'>Download Image</button> */}
      <Box className="valuation-box" style={{ 
        position:'relative'
       }}>
        <div className="box-head-sec">
        <div style={{ 
            display:'flex',
            width:'100%',
            justifyContent:'space-between',
            alignItems:'center',
           }}>
            {
          !isCD && (
            <div style={{ 
            display:'flex',
            alignItems:'end',
            gap:'.5rem'
           }}>
          <h3>Shareholding Pattern (%)</h3>
                {/* <InCr /> */}
            </div>
          )}

          </div>
          <div>
            {/* <button className="btn primary-btn-outline">Edit</button>
            <button className="btn primary-btn">Download Image</button> */}
          </div>
        </div>
        <div className="" style={{
          marginBottom:'14px',
          textAlign:'right'
        }}>
          {
            IsDateShow && (
              <span style={{"position":"relative","right":".5rem","top":".5","fontSize":"0.8rem","color":"#000", fontWeight:'800'}}><span style={{ fontWeight:'500' }}>Updated On</span> {dataAPIData?.Data?.[0]?.PnL}</span>
            )
          }
            {/* <div className="btn-group">
              <button className="btn active">Consolidate</button>
              <button className="btn">Standalone</button>
            </div> */}
        </div>

        {/* {
          data && data.Data && data.Data.length > 0 && data.Data.map((res, i) => {
            
              let a1 = 0;
              const tableHead = res.header;
              var mColArr = [];
              tableHead.map((resHeads) => {

                var hideCheck = false;
                if (a1 !== 1) {
                  var hideCheck = true;
                }
                var stickyLeft = 0;

                var stuff = {};
                Object.keys(resHeads).forEach((key, i0) => {

                  var label = resHeads[key];
                  var show_status = resHeads[key];
                  if (label != null && label != "") {
                    var width = 120;
                    var sticky = false;
                    if (key == 'Company_Name' || key == 'Sector' || key == 'Industry') {
                      width = 150;
                      sticky = true;

                      hideCheck = false;
                    } else {
                      hideCheck = true;
                    }

                    if (key != '$id' && key != 'AccordCode') {


                      // var a0 = { key:key, show_status:true };
                      // a00.push(a0);
                      stuff[key] = true;
                      var mCol = {
                        id: key,
                        label: label,
                        stickyLeft: stickyLeft,
                        minWidth: width,
                        maxWidth: width,
                        align: 'canter',
                        hideCheck: hideCheck,
                        sticky: sticky,
                      }
                      mColArr.push(mCol);
                    }
                  }
                  a1++;
                })
              });

              const tableBody = res?.TableData;
              
              var allRowsData = [];
              tableBody.map((resBody) => {

                var singleRow = {};
                Object.keys(resBody).forEach(key => {
                  let col_val = resBody[key] ? resBody[key].trim() : " ";
                  singleRow[key] = col_val;
                })
                allRowsData.push(singleRow);
              });

              return (
                <>
                <div className="box-body-sec">
                    <table>
                      <thead>
                        <tr>
                          {
                            mColArr.map((item,i)=>{
                              return (
                                <td key={i}>{item.label}</td>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                            allRowsData.map((row,i)=>{
                              return (
                                <tr>
                                  {
                                    mColArr.map((item,i)=>{
                                      let value = row[item.id]
                                      return (
                                        <td key={i}>{value}</td>
                                      )
                                    })
                                  }
                                </tr>
                              )
                            })
                          }
                        
                      </tbody>
                    </table>
                  </div>
                </>
              )

          })
        } */}
        

        
        <div className="box-body-sec">


        <table>
                  <thead>
                    <tr>
                      {
                        TableColumns.map((item,i)=>{
                          let cStyle = {
                            
                                backgroundColor:item?.bg_color,
                                fontStyle:item?.isItalic ? "italic" : "",
                                fontWeight:item?.isBold ? "500" : "",
                                color:item?.text_color,
                              }
                          return (
                            <td style={cStyle} key={i}>{item.label}</td>
                          )
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                        TableBody.map((row,r_i)=>{
                          let backgroundColor = "";
                          let fontStyle = "";
                          let fontWeight = "";
                          let color = "";

                          return (
                            <>
                              <tr>
                                {
                                  TableColumns.map((item,i)=>{
                                    let rowData = row[item.id]
                                    let value = rowData?.label
                                    let cStyle = {
                                      backgroundColor:rowData?.bg_color,
                                      fontStyle:rowData?.isItalic ? "italic" : "",
                                      fontWeight:rowData?.isBold ? "500" : "",
                                      color:rowData?.text_color,
                                    }
                                    if(backgroundColor === "" &&
                                      fontStyle === "" &&
                                      fontWeight === "" &&
                                      color === ""){
                                        backgroundColor = rowData?.bg_color;
                                        fontStyle = rowData?.isItalic ? "italic" : "";
                                        fontWeight = rowData?.isBold ? "500" : "";
                                        color = rowData?.text_color;
                                    }
                                    return (
                                      <td style={cStyle} key={i}>
                                        <div style={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent: i !==0 ? 'end' : "space-between",
                                        // columnGap:'.5rem'
                                      }}>
                                        <div style={{ 
display:'flex',
alignItems:'center',
justifyContent:'start',
columnGap:'.5rem'
                                         }}>
                                          <div>{value || ""}</div>
                                          {
                                            i===0 && row?.childTable && row?.childTable.length > 0 && (
                                              PlusIcons[`childTable_${r_i}`] ?  <AiOutlineMinus onClick={(e)=>showDiv(e, r_i)} className="td-icon"/> : <AiOutlinePlus onClick={(e)=>showDiv(e, r_i)} className="td-icon"/> 
                                            )
                                          }
                                        </div>
                                        <span>
                                            {
                                              i === 0 && 
                                              <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                                <BsFillBarChartFill size={16} />
                                              </IconButton>
                                            }
                                          </span>
                                      </div>
                                      </td>
                                    )
                                  })
                                }
                              </tr>
                              { 
                                 PlusIcons[`childTable_${r_i}`] &&
                                row?.childTable && row?.childTable.length > 0 && (
                                  <>
                                    {
                                      row.childTable.map((child, c)=>{
                                        return (
                                          <tr key={c} className={r_i%2 !== 0 ? "tr-even" : "tr-odd"}>
                                            {
                                              TableColumns.map((item0,i0)=>{
                                                let c_value = child[item0.id];
                                                let cStyle = {};
                                                if(i0 === 0){
                                                  cStyle = {
                                                    ...cStyle,
                                                    paddingLeft:'2rem'
                                                  }
                                                }
                                                if(i0 > 0){
                                                  cStyle = {
                                                    ...cStyle,
                                                    width:'100px'
                                                  }
                                                }
                                                cStyle = {
                                                  ...cStyle,
                                                  backgroundColor:backgroundColor,
                                                  fontStyle:fontStyle,
                                                  fontSize:'12px',
                                                  // fontWeight:fontWeight,
                                                  color:color
                                                }
                                                return (
                                                  <>
                                                    <td key={i0} style={cStyle}>
                                                    <div style={{ 
                                                        display:'flex',
                                                        width:'100%',
                                                        justifyContent: (i0 === 0 ? 'space-between' : 'flex-end'),
                                                        alignItems:'center'
                                                      }}>
                                                        {c_value || ""}  
                                                        <span>
                                                          {
                                                            i0 === 0 && 
                                                            <IconButton onClick={(e)=>showChart(child, 2)} sx={{ padding:0 }}>
                                                              <BsFillBarChartFill size={16} />
                                                            </IconButton>
                                                          }
                                                        </span>
                                                      </div>
                                        {/* {c_value} */}
                                        </td>
                                                  </>
                                                )
                                              })
                                            }
                                          </tr>
                                        )
                                      })
                                    }
                                  </>
                                )
                              }
                            </>
                          )
                        })
                      }
                    
                  </tbody>
                </table>

        </div>


      </Box>
    </>
  );
};

export default ShareholdingPatternComp;
