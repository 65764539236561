import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import InCr from "./InCr";
import { useDispatch, useSelector } from "react-redux";
import { SingleCompCashFlowAction } from "../../../redux/actions/VdrAction";
import { BsFillBarChartFill } from "react-icons/bs";
import { IsDateShow } from "../../../constants/helpers";
import ConStdButtonError from "../../../components/SingleCompanyContainer/FinancialCharts/ConStdButtonError";

const CashflowComp = (props) => {
  const {
    companyId,
    isCD
  } = props;


  const {
    data,
    loading
  } = useSelector(state=>state.SingleCompCashFlowReducer);


  const { data: dataAPIData, loading: dataAPILoading } = useSelector((state) => state.dateAPIReducer);
  const [QuarterlySegmentType, setQuarterlySegmentType] = useState([]);
  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);
  const [ActivePrimaryBtn, setActivePrimaryBtn] = useState(1);
  const [FirstLoad, setFirstLoad] = useState(true);
  const [ActiveSecondaryBtn, setActiveSecondaryBtn] = useState(1);

  const [PlusIcons, setPlusIcons] = useState({});
  

  const showDiv = (e, index) => {
    let a0 = PlusIcons;
    a0 = {
      ...a0,
      [`childTable_${index}`]: (!PlusIcons[`childTable_${index}`])
    }
    
    console.log(PlusIcons, 'index >> ', index, PlusIcons[`childTable_${index}`], (!PlusIcons[`childTable_${index}`]));
    // PlusIcons[`childTable_${r_i}`]

    setPlusIcons(a0);
  }

const dispatch = useDispatch();

const [P_Type, setP_Type] = useState('con');
const [BtnStatus, setBtnStatus] = useState(null)
const [BtnStatusError, setBtnStatusError] = useState(null)

const callApi = () => {
    
  let pType = 'con';
  if(QuarterlySegmentType === "Standalone"){
    pType = 'std';
  }
  setP_Type(pType)
  let params =  {
    "CompanyId":companyId,
    "type":pType
  }
  console.log('params >>>> ', params, QuarterlySegmentType)
  dispatch(SingleCompCashFlowAction(params))
}

useEffect(() => {
  setTableColumns([])
  setTableBody([])
  callApi()
  setBtnStatusError(null);
  
  console.log(QuarterlySegmentType);

}, [QuarterlySegmentType])

useEffect(() => {
    if(!loading){
      setBtnStatus(data.button_status)
      
      let NotFound = false;
      let button_status = data?.button_status;
      Object.keys(button_status).map(item=>{
        NotFound = false;
        if(button_status[item] === false ){
            NotFound = true;
        }
      })
      setBtnStatusError(NotFound);

      // console.log('data?.button_status[P_Type] >>> ', data?.button_status[P_Type], P_Type)
      
      if(data.header && data.header.length > 0){
        let cols = []
        let firstObj = data.header[0];
        let a0 = firstObj.row;
        Object.keys(a0).map((item, i)=>{
          if(i > 0){
            let dd = {
                "id": item,
                "width": "",
                "align": "",
                "bg_color": firstObj?.bg_color,
                "isItalic": firstObj?.isItalic,
                "isBold": firstObj?.isBold,
                "text_color": firstObj?.text_color,
                "label": a0[item]
              }
            cols.push(dd);
          }
        })
        setTableColumns(cols)
      }else{
        if(FirstLoad){
          setFirstLoad(false);
          setActivePrimaryBtn(2);
          setQuarterlySegmentType('Standalone')
        }
      }
      console.log('data.Data >>> ', data.Data)
      if(data.Data && data.Data.length){
        let dataA = []
        let plusIcon = {};
        let a00 = data.Data;
        a00.map((item, i)=>{
          let cData = item?.row;
          let childObj = {}
          Object.keys(cData).map((item0, i0)=>{
            if(i0 > 0){
              let data = {
                "label":cData[item0],
                "bg_color":item['bg_color'],
                "isItalic":item['isItalic'],
                "isBold":item['isBold'],
                "text_color":item['text_color'],
              };
              childObj = {...childObj, [`Column${i0}`]: data}
            }
          })
          if(item?.childTable.length > 0){
            plusIcon = {...plusIcon, [`childTable_${i}`]: false}
          }
          childObj = {...childObj, [`childTable`]: (item?.childTable || [])}
          dataA.push(childObj)
        })
        // console.log('dataA >>>>>>>> ', dataA)
        setPlusIcons(plusIcon);
        setTableBody(dataA)
      }
    }
}, [dispatch, loading])
  

  
let secondaryBtnArr = [
  {
    "id":1,
    "label":"Consolidated",
    "value":"Consolidated",
    "short_name":"con",
    "show_in":[1,2,3]
  },
  {
    "id":2,
    "label":"Standalone",
    "value":"Standalone",
    "short_name":"std",
    "show_in":[1,2,3]
  },
]

const showChart = (row, level=1)=>{
  // console.log(row);
  let newRow = {};
if(level == 1){
  // delete row['childTable']
  newRow = row
}else{
  Object.keys(row).map((item)=>{
      let a0 = {
          bg_color: "#ccdbf5",
          isBold: true,
          isItalic: false,
          label: row[item],
          text_color: "#000",
        
      }
      newRow = {...newRow, [item]: a0}
      
  })
  
}
  dispatch({
    type:"Columns_Rows",
    payload:{columns:TableColumns, rows:newRow}
  })
  
}
 


  return (
    <>
      {/* <button className='btn primary-btn'>Download Image</button> */}
      <Box className="valuation-box" style={{ 
        position:'relative',
       }}>
            {
          !isCD && (
        <div className="box-head-sec">
          
        <div style={{ 
            display:'flex',
            width:'100%',
            justifyContent:'space-between',
            alignItems:'center',
           }}>
            <div style={{ 
            display:'flex',
            alignItems:'end',
            gap:'.5rem'
           }}>
            <>
              <h3>Cash Flow</h3>
              <InCr />
            </>
            </div>

          </div>
          </div>
          )}
          {
            IsDateShow && (
<span style={{"position":"absolute","right":"3.5rem","top":"-1.4rem","fontSize":"0.8rem","color":"#000", fontWeight:'800'}}><span style={{ fontWeight:'500' }}>Updated On</span> {dataAPIData?.Data?.[0]?.PnL}</span>
              
            )
          }

          <div style={{
            display:'flex',
            gap:'10px',
            marginBottom:'14px'
           }}>
          {
              secondaryBtnArr.map((item, i)=>{
                if(item.show_in.includes(ActiveSecondaryBtn)){
                }
                return (
                  <>
                <button onClick={()=>{
                  // console.log('item.id >>> ', item.id)
                  if((BtnStatus && BtnStatus[item?.short_name])){
                    setQuarterlySegmentType(item?.value)
                    setActivePrimaryBtn(item.id);
                  }
                }
                } key={i} className={`btn ${ActivePrimaryBtn === item.id ? "primary-btn" : "secondary-btn-outline"} ${(BtnStatus && !BtnStatus[item?.short_name]) ? "cst_disabled" : ""} `}>{item?.label}</button>
                  </>
                )
              })
            }

            {/* <button className="btn primary-btn-outline">Edit</button>
            <button className="btn primary-btn">Download Image</button> */}
          </div>
        
        <div className="box-body-sec">

        <ConStdButtonError  BtnStatusError={BtnStatusError} setBtnStatusError={setBtnStatusError}/>

        <table>
                  <thead>
                    <tr>
                      {
                        TableColumns.map((item,i)=>{
                          let cStyle = {
                            
                                backgroundColor:item?.bg_color,
                                fontStyle:item?.isItalic ? "italic" : "",
                                fontWeight:item?.isBold ? "500" : "",
                                color:item?.text_color,
                              }
                          return (
                            <td style={cStyle} key={i}>{item.label}</td>
                          )
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                   
                    {
                        TableBody.map((row,r_i)=>{
                          let backgroundColor = "";
                          let fontStyle = "";
                          let fontWeight = "";
                          let color = "";

                          return (
                            <>
                              <tr>
                                {
                                  TableColumns.map((item,i)=>{
                                    let rowData = row[item.id]
                                    let value = rowData?.label
                                    let cStyle = {
                                      backgroundColor:rowData?.bg_color,
                                      fontStyle:rowData?.isItalic ? "italic" : "",
                                      fontWeight:rowData?.isBold ? "500" : "",
                                      color:rowData?.text_color,
                                    }
                                    if(backgroundColor === "" &&
                                      fontStyle === "" &&
                                      fontWeight === "" &&
                                      color === ""){
                                        backgroundColor = rowData?.bg_color;
                                        fontStyle = rowData?.isItalic ? "italic" : "";
                                        fontWeight = rowData?.isBold ? "500" : "";
                                        color = rowData?.text_color;
                                    }
                                    return (
                                      <td style={cStyle} key={i}>
                                        <div style={{
                                        display:'flex',
                                        alignItems:'center',
                                        justifyContent: i !==0 ? 'end' : "space-between",
                                        columnGap:'.5rem'
                                      }}>
                                        <div style={{ 
display:'flex',
alignItems:'center',
justifyContent:'start',
columnGap:'.5rem'
                                         }}>
                                          <div>{value || ""}</div>
                                          {
                                            i===0 && row?.childTable && row?.childTable.length > 0 && (
                                              PlusIcons[`childTable_${r_i}`] ?  <AiOutlineMinus onClick={(e)=>showDiv(e, r_i)} className="td-icon"/> : <AiOutlinePlus onClick={(e)=>showDiv(e, r_i)} className="td-icon"/> 
                                            )
                                          }
                                        </div>
                                        <span>
                                            {
                                              i === 0 && 
                                              <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                                <BsFillBarChartFill size={16} />
                                              </IconButton>
                                            }
                                          </span>
                                      </div>
                                      </td>
                                    )
                                  })
                                }
                              </tr>
                              { 
                                 PlusIcons[`childTable_${r_i}`] &&
                                row?.childTable && row?.childTable.length > 0 && (
                                  <>
                                    {
                                      row.childTable.map((child, c)=>{
                                        return (
                                          <tr key={c} className={r_i%2 !== 0 ? "tr-even" : "tr-odd"}>
                                            {
                                              TableColumns.map((item0,i0)=>{
                                                let c_value = child[item0.id];
                                                let cStyle = {};
                                                if(i0 === 0){
                                                  cStyle = {
                                                    ...cStyle,
                                                    paddingLeft:'2rem'
                                                  }
                                                }
                                                cStyle = {
                                                  ...cStyle,
                                                  backgroundColor:backgroundColor,
                                                  fontStyle:fontStyle,
                                                  fontWeight:fontWeight,
                                                  color:color
                                                }
                                                return (
                                                  <>
                                                    <td key={i0} style={cStyle}>
                                                    <div style={{ 
                                                        display:'flex',
                                                        width:'100%',
                                                        justifyContent: (i0 === 0 ? 'space-between' : 'flex-end'),
                                                        alignItems:'center'
                                                      }}>
                                                        {c_value || ""}  
                                                        <span>
                                                          {
                                                            i0 === 0 && 
                                                            <IconButton onClick={(e)=>showChart(child, 2)} sx={{ padding:0 }}>
                                                              <BsFillBarChartFill size={16} />
                                                            </IconButton>
                                                          }
                                                        </span>
                                                      </div>
                                        {/* {c_value} */}
                                        </td>
                                                  </>
                                                )
                                              })
                                            }
                                          </tr>
                                        )
                                      })
                                    }
                                  </>
                                )
                              }
                            </>
                          )
                        })
                      }
                    
                  </tbody>
                </table>

        </div>
      </Box>
    </>
  );
};

export default CashflowComp;
