import React, { useState } from 'react'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { SingleCompProfitAndLossAction } from '../../../redux/actions/VdrAction';
import InCr from './InCr';
import { BsFillBarChartFill } from 'react-icons/bs';
import ProfitLossChartComp from './ProfitLossChartComp';
import { IsDateShow } from '../../../constants/helpers';

const ProfitLossStatement = (props) => {
  const {
    companyId,
    isCD
  } = props

  const [QuarterlySegmentType, setQuarterlySegmentType] = useState([]);
  const [TableColumns, setTableColumns] = useState([]);
  const [TableBody, setTableBody] = useState([]);
  const [ActivePrimaryBtn, setActivePrimaryBtn] = useState(1);


  
  const [FirstLoad, setFirstLoad] = useState(true);
  const [ActiveSecondaryBtn, setActiveSecondaryBtn] = useState(1);
  let secondaryBtnArr = [
    {
      "id":1,
      "label":"Consolidated",
      "value":"Consolidated",
      "short_name":"con",
      "show_in":[1,2,3]
    },
    {
      "id":2,
      "label":"Standalone",
      "value":"Standalone",
      "short_name":"std",
      "show_in":[1,2,3]
    },
  ]
  let primaryBtnArr = [
    {
      "id":1,
      "label":"P&L Statement",
      "component": ""
    },
    {
      "id":2,
      "label":"Chart",
      "component": ""
    }
  ]


  const {
    data,
    loading
  } = useSelector(state=>state.SingleCompProfitAndLossReducer);

  const dispatch = useDispatch();


  const [P_Type, setP_Type] = useState('con');

  const [BtnStatus, setBtnStatus] = useState({})


  const ThemeColorData = useSelector(state=> state.ThemeColorReducer);

  const { data: dataAPIData, loading: dataAPILoading } = useSelector((state) => state.dateAPIReducer);
  
  const callApi = () => {
    
    let pType = 'con';
        setP_Type(pType)
    if(QuarterlySegmentType === "Standalone"){
      pType = 'std';
      setP_Type(pType)
    }
    let params =  {
      "CompanyId":companyId,
      "type":pType
    }
    dispatch(SingleCompProfitAndLossAction(params))
  }

  useEffect(() => {
    setTableColumns([])
    setTableBody([])
    callApi()
  }, [QuarterlySegmentType])

  useEffect(() => {
      if(!loading){
      setBtnStatus(data?.button_status)
        
        if(data?.header && data?.header.length > 0 && data?.button_status[P_Type] === true){
          let cols = []
          let firstObj = data?.header?.[0];
          let a0 = firstObj.row;
          Object.keys(a0).map((item, i)=>{
            if(i > 0){
              let dd = {
                  "id": item,
                  "width": "",
                  "align": "",
                  "bg_color": firstObj?.bg_color,
                  "isItalic": firstObj?.isItalic,
                  "isBold": firstObj?.isBold,
                  "text_color": firstObj?.text_color,
                  "label": a0[item]
                }
              cols.push(dd);
            }
          })
          setTableColumns(cols)
        }else{
          if(FirstLoad){
            setFirstLoad(false);
            setActivePrimaryBtn(2);
            setQuarterlySegmentType('Standalone')
          }
        }
        if(data?.Data && data?.Data.length && data?.button_status[P_Type] === true){
          let dataA = []
          let a00 = data?.Data;
          a00.map((item, i)=>{
            let cData = item?.row;
            let childObj = {}
            Object.keys(cData).map((item0, i0)=>{
              if(i0 > 0){
                let data = {
                  "label":cData[item0],
                  "bg_color":item['bg_color'],
                  "isItalic":item['isItalic'],
                  "isBold":item['isBold'],
                  "text_color":item['text_color'],
                };
                childObj = {...childObj, [`Column${i0}`]: data}
              }
            })
            dataA.push(childObj)
          })
          // console.log(dataA)
          setTableBody(dataA)
        }
      }
  }, [dispatch, loading])

  const showChart = (row)=>{
    // setIsOpen(true);
    // setCrtRow(row);
    dispatch({
      type:"Columns_Rows",
      payload:{columns:TableColumns, rows:row}
    })
  }
   


  return (
    <>
    
    {/* <CrtModal
      isOpen={IsOpen}
      setIsOpen={setIsOpen}
      row={CrtRow}
      columns={TableColumns}
    /> */}
    <Box className="valuation-box" style={{ 
      position:'relative'
     }}>
    {
          !isCD && (
        <div className="box-head-sec">
          <div style={{ 
            display:'flex',
            width:'100%',
            justifyContent:'space-between',
            alignItems:'center',
           }}>
            <div style={{ 
            display:'flex',
            alignItems:'end',
            gap:'.5rem'
           }}>
                <h3>Annual Profit & Loss Statement</h3>
                <div>

                  <InCr />
                </div>
            </div>

          </div>
          <div>
            {/* <button className="btn primary-btn-outline">Edit</button>
            <button className="btn primary-btn">Download Image</button> */}
          </div>
        </div>
          )}

  {
    (dataAPIData?.Data?.[0]?.PnL && IsDateShow) && (
        <span style={{"position":"absolute","right":"3.5rem","top":"-1.4rem","fontSize":"0.8rem","color":"#000", fontWeight:'800'}}><span style={{ fontWeight:'500' }}>Updated On</span> {dataAPIData?.Data?.[0]?.PnL}</span>
    )
  }

        <div className="interim-btn-sec">
           <div style={{
            display:'flex',
            gap:'10px'
           }} className="">


            {
              secondaryBtnArr && secondaryBtnArr.length > 0 && secondaryBtnArr.map((item, i)=>{
                if(item.show_in.includes(ActiveSecondaryBtn)){
                  return (
                    <>
                  <button onClick={()=>{
                    // console.log('item.id >>> ', item.id)
                    
                  if((BtnStatus?.[item?.short_name])){
                    setQuarterlySegmentType(item?.value)
                    setActivePrimaryBtn(item.id);
                  }
                  }
                  } key={i} className={`btn ${ActivePrimaryBtn === item.id ? "primary-btn" : "secondary-btn-outline"} ${ !BtnStatus?.[item?.short_name] ? "cst_disabled" : ""}`}>{item?.label}</button>
                    </>
                  )
                }
              })
            }
            

           </div>
           <div className="aaaaaaa">
            <div className="btn-group">

              
            {
              primaryBtnArr.map((item, i)=>{
                return (
                  <>
                    <button onClick={()=>{
                        // setFirstLoad(true);
                        setActiveSecondaryBtn(item.id);
                      }}  key={i} className={`btn ${ActiveSecondaryBtn === item.id ? "active" : ""}`}>{item?.label}</button>
                    </>
                )
              })
            }
              </div>
           </div>
           
        </div>

        {/* <div style={{
            display:'flex',
            gap:'10px',
            marginBottom:'14px'
           }} className="">

            {
              secondaryBtnArr.map((item, i)=>{
                if(item.show_in.includes(ActiveSecondaryBtn)){
                  return (
                    <>
                  <button onClick={()=>{
                    // console.log('item.id >>> ', item.id)
                    
                  if((BtnStatus?.[item?.short_name])){
                    setQuarterlySegmentType(item?.value)
                    setActivePrimaryBtn(item.id);
                  }
                  }
                  } key={i} className={`btn ${ActivePrimaryBtn === item.id ? "primary-btn" : "secondary-btn-outline"} ${ !BtnStatus?.[item?.short_name] ? "cst_disabled" : ""}`}>{item?.label}</button>
                    </>
                  )
                }
              })
            }

            {
              primaryBtnArr.map((item, i)=>{
                return (
                  <button onClick={()=>{
                    setActivePrimaryBtn(item.id); 
                    setActiveSecondaryBtn(1)
                  }
                  } key={i} className={`btn ${ActivePrimaryBtn === item.id ? "primary-btn" : "secondary-btn-outline"}`}>{item?.label}</button>
                )
              })
            }
            

           </div> */}
           {
            ActiveSecondaryBtn === 1 &&
            <>
            
        <div className="box-body-sec">
        

        {
          loading && (
            <CircularProgress />
          )
        }
        {
          !loading && (
            <>
            {
              TableBody.length > 0 ?
              <>
              
              
              <table>
                <thead>
                  <tr>
                    {
                      TableColumns.map((item,i)=>{
                        let cStyle = {
                          
                              backgroundColor:item?.bg_color,
                              fontStyle:item?.isItalic ? "italic" : "",
                              fontWeight:item?.isBold ? "500" : "",
                              color:item?.text_color,
                            }
                        return (
                          <td style={cStyle} key={i}>{item.label}</td>
                        )
                      })
                    }
                  </tr>
                </thead>
                <tbody>
                  {
                      TableBody.map((row,i)=>{
                        return (
                          <tr>
                            {
                              TableColumns.map((item,i)=>{
                                let rowData = row[item.id]
                                let value = rowData?.label
                                let cStyle = {
                                  backgroundColor:rowData?.bg_color,
                                  fontStyle:rowData?.isItalic ? "italic" : "",
                                  fontWeight:rowData?.isBold ? "500" : "",
                                  color:rowData?.text_color,
                                }
                                return (
                                  <td style={cStyle} key={i}>
                                    {/* {value || ""} */}
                                    <div style={{ 
                                      display:'flex',
                                      width:'100%',
                                      justifyContent: (i === 0 ? 'space-between' : 'flex-end'),
                                      alignItems:'center'
                                     }}>
                                      {value || ""}  
                                      <span>
                                        {
                                          i === 0 && 
                                          <IconButton onClick={(e)=>showChart(row)} sx={{ padding:0 }}>
                                            <BsFillBarChartFill size={16} />
                                          </IconButton>
                                        }
                                      </span>
                                    </div>
                                  </td>
                                )
                              })
                            }
                          </tr>
                        )
                      })
                    }
                  
                </tbody>
              </table>
              </>
              :
              <>
                <div>
                    <Typography>N/A</Typography>
                </div>
              </>

            }
              
            </>
          )
        }
      </div>

      {
        !loading && (
          <>
            <div className='pl_footer'>
              <Grid container spacing={3}>
                {
                  data?.footer && data?.footer.length > 0 && data?.footer.map((f_item,f)=>{
                    // console.log('f_item > ', f_item)
                    let a0 = 0;
                    return (
                      <Grid key={f} item md={3} sm={2} xs={1}>
                        <div className='plItem'>
                          <table>
                            <thead>
                              <tr>
                                <td colSpan={2} style={{
                                  fontWeight: (f_item?.isBold && "500"),
                                  textAlign:'center'
                                }}>{f_item?.heading}</td>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                Object.keys(f_item).map((child_item,cf)=>{
                                  if(child_item.includes('row')){
                                    a0++;
                                    let row_item = f_item[`row${a0}`];
                                    
                                    return (
                                      <tr key={cf} >
                                        {
                                          row_item.map((rItem, rIndex)=>{
                                            let cStyle = {
                                              
                                            }
                                            if(rItem?.isBold){
                                              cStyle = {
                                                ...cStyle,
                                                fontWeight: (rItem?.isBold && "500"),
                                                color: '#22222F'
                                              }
                                            }
                                            if(row_item.length-1 == rIndex){
                                              cStyle = {
                                                ...cStyle,
                                                textAlign: 'right'
                                              }
                                            }
                                            return (
                                              <td style={cStyle} >{rItem?.column}</td>
                                            )
                                          }
                                          )
                                        }
                                      </tr>
                                    )
                                  }
                                })
                              }
                            </tbody>
                            
                          </table>
                        </div>
                      </Grid>
                    )
                  })
                }
              </Grid>
            </div>
          </>
        )
      }
            </>
           }
           {
            ActiveSecondaryBtn === 2 &&
            <>
              <Box>
                <ProfitLossChartComp  companyId={companyId}  P_Type={P_Type} themeColor={ThemeColorData.chartColor} />
              </Box>
            </>
           }
           
        
        {/*  */}
      </Box>
    </>
  )
}

export default ProfitLossStatement